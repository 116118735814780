import axios from "axios";
import { Form, Formik } from "formik";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAppSelector } from "../../app/hooks";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import CustomModal from "../../components/Modal/CustomModal";
import {
  PropertyExtraOptionDtos,
  PropertyGeneralInfoDtos,
  PropertyServicesDtos,
  PropertyUniqueOptionDtos,
} from "../../redux/features/types";
import { RootState } from "../../redux/store";
import {
  authorizationToken,
  draft_property_url,
  edit_property_url,
  property_details_url,
} from "../../utils/api_urls";
import EditPropertyInfo from "./EditPropertyInfo";
import { editPropertyInitTypes } from "./types";
import { usePropertyContext } from "../../context/PropertyContext";
import { PropertyDetailTypes } from "../Property/types";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";

// facing data
export const facingsData = [
  { title: "North", abbre: "N", isSelect: false },
  { title: "North West", abbre: "NW", isSelect: false },
  { title: "West", abbre: "W", isSelect: false },
  { title: "North East", abbre: "NE", isSelect: false },
  { title: "South", abbre: "S", isSelect: false },
  { title: "South West", abbre: "SW", isSelect: false },
  { title: "South East", abbre: "SE", isSelect: false },
];

// generate property context types
type generatePropertyContextTypes = {
  propertyExtraOptions: PropertyExtraOptionDtos[];
  propertyUniqueOptions: PropertyUniqueOptionDtos[];
  propertyServiceOptions: PropertyServicesDtos[];
  setPropertyExtraOptions: Dispatch<
    React.SetStateAction<PropertyExtraOptionDtos[]>
  >;
  setPropertyUniqueOptions: Dispatch<
    React.SetStateAction<PropertyUniqueOptionDtos[]>
  >;
  setPropertyServiceOptions: Dispatch<
    React.SetStateAction<PropertyServicesDtos[]>
  >;
  propertyGeneralInfo: PropertyGeneralInfoDtos[];
  setPropertyGeneralInfo: Dispatch<SetStateAction<PropertyGeneralInfoDtos[]>>;
  facingData: {
    title: string;
    abbre: string;
    isSelect: boolean;
  }[];
  setFacingData: Dispatch<
    SetStateAction<
      {
        title: string;
        abbre: string;
        isSelect: boolean;
      }[]
    >
  >;
  isLoading: boolean;
};

// generate property context
const EditPropertyContext = createContext({} as generatePropertyContextTypes);

const EditProperty = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [propertyGeneralInfo, setPropertyGeneralInfo] = useState<
    PropertyGeneralInfoDtos[]
  >([]);
  const [propertyDetailData, setPropertyDetailData] = useState(
    {} as PropertyDetailTypes
  );
  const [error, setError] = useState("");
  const [propertyLoading, setPropertyLoading] = useState(true);
  const { id } = useParams();

  const { data: loginData } = useAppSelector((state) => state.loginData);
  const {
    generatePropertyType,
    generatePropertyNumber,
    propertyPurpose,
    createPropertyData,
    propertyLocation,
    propertyStatusId,
    setIsPropertyTypeSelected,
    setPropertyStatusId,
    groundFloorInfoItems,
    setGroundFloorInfoItems,
    firstFloorInfoItems,
    setFirstFloorInfoItems,
    secondFloorInfoItems,
    setSecondFloorInfoItems,
    thirdFloorInfoItems,
    setThirdFloorInfoItems,
    resetPropertyDataContext,
  } = usePropertyContext();

  const [facingData, setFacingData] = useState(facingsData);
  const {
    data: { propertyAppData },
  } = useAppSelector((state: RootState) => state.appData);

  // property detail data
  const {
    propertyData: {
      city = "",
      longitude = 0,
      latitude = 0,
      country = "",
      state = "",
      unparsedAddress = "",
      streetNumber = "",
      streetName = "",
      postalCode = "",
      isMpac = false,
      purpose = "",
      propertySubTypeId = 1,
      propertyId = 1,
      propertyTypeId = 1,
      title = "",
      price = "",
      location = "",
      description = "",
      groundFloorInfo = [],
      firstFloorInfo = [],
      secondFloorInfo = [],
      thirdFloorInfo = [],
      propertySize: { keyName: propertySizeType = "", size = "" } = {},
      propertyGeneralInfo: {
        actualYearBuilt = "",
        furnishedYear = "",
        others: others = {},
      } = {},
      propertyExtraOptions: propertyExtraOption = {},
      propertyServices: propertyService = {},
      propertyUniqueOptions: propertyUniqueOption = {},
      facingAndFlorInfo: {
        numberOfFloors = null,
        yourFloor = null,
        direction = null,
      } = {},
      basicResidentialOptions: {
        actualDepth = null,
        actualFrontage = null,
        actualLotSize = null,
        actualLotSizeUom = null,
        airConditioningFlag = null,
        assessmentValue = null,
        heatingSystemTypeCode = null,
        hydroServiceDescription = null,
        saleAmount = null,
        saleDate = null,
        sanitaryServiceDescription = null,
        waterServiceDescription = null,
      } = {},
      primaryResidence: {
        actualYear = null,
        additionYear = null,
        basementArea = null,
        basementFinished = null,
        effectiveYear = null,
        firstFloorArea = null,
        renovationYear = null,
        secondFloorArea = null,
        thirdFloorArea = null,
      } = {},
      garageStructure: {
        garageName = null,
        garageType = null,
        garageDescription = null,
        actualYear: garageActualYear = null,
        effectiveYear: garageEffectiveYear = null,
        renovationYear: garageRenovationYear = null,
        floorArea = null,
        totalGarageSpacesCount = null,
      } = {},
      imageURLS = [],
      videoULRS = null,
    } = {},
  } = propertyDetailData || {};

  console.log("propertyTypeId at top", propertyTypeId);
  // property data
  const propertyData = propertyAppData[propertyTypeId - 1];

  console.log({ propertyTypeId });

  const [propertyExtraOptions, setPropertyExtraOptions] = useState(
    [] as PropertyExtraOptionDtos[]
  );
  const [propertyUniqueOptions, setPropertyUniqueOptions] = useState(
    [] as PropertyUniqueOptionDtos[]
  );
  const [propertyServiceOptions, setPropertyServiceOptions] = useState(
    [] as PropertyServicesDtos[]
  );

  // property info values
  const initialValues: editPropertyInitTypes = {
    city,
    longitude,
    latitude,
    streetNumber: streetNumber || "1",
    state,
    country,
    unparsedAddress,
    streetName,
    postalCode,
    groundFloorInfo,
    firstFloorInfo,
    secondFloorInfo,
    thirdFloorInfo,
    isMpac,
    // data came from mpac property from api property

    // roll:
    //   (mpacCreatePropertyData && mpacCreatePropertyData?.results[0]?.roll) ||
    //   "",
    // streetNumberSuffix:
    //   mpacCreatePropertyData?.results[0]?.address?.streetNumberSuffix || "",
    // upperStreetNumber:
    //   mpacCreatePropertyData?.results[0]?.address?.unparsedAddress || "",
    // streetType: mpacCreatePropertyData?.results[0]?.address?.streetType || "",
    // streetDirection:
    //   mpacCreatePropertyData?.results[0]?.address?.streetDirection || "",
    // municipality:
    //   mpacCreatePropertyData?.results[0]?.address?.municipality || "",
    // unitDesignator:
    //   mpacCreatePropertyData?.results[0]?.address?.unitDesignator || "",
    // unitNumber: mpacCreatePropertyData?.results[0]?.address?.unitNumber || "",
    // province: mpacCreatePropertyData?.results[0]?.address?.province || "",
    // propertyId: mpacAdditionalDetailsData?.mapcListPlanRsp?.propertyId || "",

    propertyId: propertyId || 1,
    purpose: purpose,

    saveFavourite: false,
    readComments: false,
    boostPermission: false,
    sharingEnabled: false,

    propertyTypeId: propertyTypeId,
    propertySubTypeId: propertySubTypeId,
    title: title,
    location,
    propertySize: {
      keyName:
        propertyData?.propertySizeDTOS?.find(
          ({ keyName }: any) => keyName === propertySizeType
        )?.keyName ?? "",
      size: size,
    },
    price: price,
    description: description,
    basicResidentialOptions: {
      assessmentValue,
      saleDate,
      saleAmount,
      hydroServiceDescription,
      waterServiceDescription,
      sanitaryServiceDescription,
      heatingSystemTypeCode,
      airConditioningFlag,
      actualFrontage,
      actualLotSize,
      actualDepth,
      actualLotSizeUom,
    },
    primaryResidence: {
      actualYear,
      effectiveYear,
      renovationYear,
      additionYear,
      firstFloorArea,
      secondFloorArea,
      thirdFloorArea,
      basementArea,
      basementFinished,
    },
    secondaryResidenceDTO: {
      actualYearBuilt: null,
      totalFloorArea: null,
    },
    garageStructure: {
      garageName,
      garageType,
      garageDescription,
      actualYear: garageActualYear,
      effectiveYear: garageEffectiveYear,
      renovationYear: garageRenovationYear,
      floorArea,
      totalGarageSpacesCount,
    },
    buildingPermit: {
      permitNumber: null,
      issueDate: null,
      expiryDate: null,
      permitDescription: null,
    },
    propertyGeneralInfo: {
      actualYearBuilt: actualYearBuilt || null,
      furnishedYear: furnishedYear || null,
      others: others,
    },
    facingAndFlorInfo: {
      numberOfFloors: numberOfFloors,
      yourFloor: yourFloor,
      direction: direction,
    },

    propertyExtraOptions: propertyExtraOption,
    propertyUniqueOptions: propertyUniqueOption,
    propertyServices: propertyService,
    tagPeople: null,
    contactRequestPermission: true,
    postBoostPermission: true,
    boost_permission: true,
    read_comments: true,
    save_favourite: true,
    sharing_enabled: true,
    hideLikesAndViewsCounts: false,
    turnOffCommenting: false,
    images: imageURLS,
    video: videoULRS,
    isImageEdited: false,
    isVideoEdited: false,
  };

  // handle sumbit in generate new property
  const onSubmit = async (
    values: editPropertyInitTypes,
    { resetForm }: any
  ) => {
    // imagess handling in form submission
    const imageUrls: string[] = [];
    const formImageFiles: File[] = [];
    let videoFile = "";
    let videoURL = "";

    // checking images
    values.images.forEach((image: any) => {
      if (typeof image === "string") {
        imageUrls.push(image);
      } else {
        formImageFiles.push(image);
      }
    });

    const formData = new FormData();
    if (propertyStatusId === 3 && values.images.length < 3) {
      return;
    }

    // checking video is local images or image urls
    if (formImageFiles.length > 0) {
      formImageFiles.forEach((file: any) => {
        formData.append("images", file);
      });
      values.isImageEdited = true;
      values.images = imageUrls;
    } else {
      values.images = imageUrls;
      values.isImageEdited = false;
    }

    // checking video is local or video url
    if (values.video) {
      if (typeof values.video === "string") {
        videoURL = values.video;
        values.isVideoEdited = false;
      } else {
        formData.append("video", values.video);
        videoFile = values.video;
        videoURL = "";
        values.isVideoEdited = true;
      }
    }

    const { images, video, ...otherPropertyValues } = values;
    try {
      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              ...otherPropertyValues,
              purpose: propertyPurpose,
              imageURLS: imageURLS,
              videoURL: videoURL,
              propertyTypeId: generatePropertyNumber + 1,
              propertyStatusId,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      setIsLoading(true);

      const { data } = await axios.post(
        propertyStatusId === 3 ? edit_property_url : draft_property_url,
        formData,
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      formData.append("video", values.video);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/property-listing");
      }, 2000);
      resetForm();
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  const propertyGeneralInfoSchema = propertyGeneralInfo
    .slice(2)
    .reduce((schema, field) => {
      const { keyName, displayName, isRequired, maxRange, value } = field;

      schema[keyName] = Yup.number()
        .typeError(`${displayName} must be a number`)
        .min(1, `${displayName} must be at least 1`);

      if (displayName === "Full Bathroom" || displayName === "Full Bedroom") {
        schema[keyName] = schema[keyName].required(
          `${displayName} is required`
        );
      }

      return schema;
    }, {} as { [key: string]: any });

  // edit user validation schema
  const validationSchema = Yup.object().shape({
    images: Yup.array()
      .required("At least three images are required")
      .min(3, "At least three images are required"),
    propertySubTypeId: Yup.string().required(),
    propertySize: Yup.object({
      keyName: Yup.string().required(),
      size: Yup.string().required(),
    }),
    price: Yup.string().required(),
    title: Yup.string().required(),
    description: Yup.string().required(),
    video: Yup.mixed().test("file", "A file is required", (value) => {
      return !value || value !== undefined;
    }),
    propertyGeneralInfo: Yup.object().shape({
      actualYearBuilt:
        generatePropertyNumber === 0 || generatePropertyNumber === 2
          ? Yup.string().required()
          : Yup.string().nullable(),
      others: Yup.object().shape(propertyGeneralInfoSchema),
    }),
  });

  // create user validation schema
  const draftValidationSchema = Yup.object().shape({
    title: Yup.string().required(),
  });

  // add extra properties value
  useEffect(() => {
    console.log({ propertyData });
    setPropertyExtraOptions(
      propertyData?.propertyExtraOptionDTOS
        ? propertyData.propertyExtraOptionDTOS.map(
            (propertyExtraOption: any) => ({
              ...propertyExtraOption,
              value: 0,
            })
          )
        : []
    );
    setPropertyUniqueOptions(
      propertyData.propertyUniqueOptionDTOS.map(
        (propertyUniqueOption: any) => ({
          ...propertyUniqueOption,
          isSelect: false,
        })
      )
    );
    setPropertyServiceOptions(
      propertyData.propertyServicesDTOS.map((propertyServiceOption: any) => ({
        ...propertyServiceOption,
        isSelect: false,
      }))
    );
    setPropertyGeneralInfo(
      propertyData.propertyGeneralInfoDTOS.map((generalInfoData: any) => ({
        ...generalInfoData,
        value: 0,
      }))
    );
    setGroundFloorInfoItems(
      groundFloorInfo && groundFloorInfo.length > 0
        ? groundFloorInfo.map((_, index) => index)
        : [0, 1].map((_, index) => index)
    );
    setFirstFloorInfoItems(
      firstFloorInfo && firstFloorInfo.length > 0
        ? firstFloorInfo.map((_, index) => index)
        : [0, 1].map((_, index) => index)
    );
    setSecondFloorInfoItems(
      secondFloorInfo && secondFloorInfo.length > 0
        ? secondFloorInfo.map((_, index) => index)
        : [0, 1].map((_, index) => index)
    );
    setThirdFloorInfoItems(
      thirdFloorInfo && thirdFloorInfo.length > 0
        ? thirdFloorInfo.map((_, index) => index)
        : [0, 1].map((_, index) => index)
    );
  }, [
    generatePropertyType,
    // groundFloorInfo,
    // firstFloorInfo,
    // secondFloorInfo,
    // thirdFloorArea,
  ]);

  // property details
  const getPropertyDetails = async () => {
    try {
      setPropertyLoading(true);
      const { data } = await axios.post(
        property_details_url,
        {
          propertyId: id!,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setPropertyDetailData(data.results);
      setPropertyLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setError(error.response.data.responseMessage);
      setPropertyLoading(false);
    }
  };

  // property promise effect
  useEffect(() => {
    setIsPropertyTypeSelected(false);
    setPropertyStatusId(2);
    if (id) {
      getPropertyDetails();
    }
  }, [id]);

  if (propertyLoading) {
    return <Loader />;
  }
  if (error) {
    return <NoDataAvailable title={error} />;
  }

  // do task
  const doTask = () => {
    navigate("/property-listing");
  };

  return (
    <EditPropertyContext.Provider
      value={{
        propertyExtraOptions,
        propertyServiceOptions,
        propertyUniqueOptions,
        setPropertyExtraOptions,
        setPropertyServiceOptions,
        setPropertyUniqueOptions,
        facingData,
        setFacingData,
        propertyGeneralInfo,
        setPropertyGeneralInfo,
        isLoading,
      }}
    >
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title={propertyStatusId === 3 ? "Property Updated" : "Property Draft"}
          description={
            propertyStatusId === 3
              ? "Congratulations! Property Updated Successfully."
              : "Congratulations! Property Drafted Successfully."
          }
          doTask={doTask}
        />
      </CustomModal>
      {isLoading && <LoadingOverlay message="Property Posting..." />}
      {propertyStatusId === 3 ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <EditPropertyInfo />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={draftValidationSchema}
        >
          {() => {
            return (
              <Form>
                <EditPropertyInfo />
              </Form>
            );
          }}
        </Formik>
      )}
    </EditPropertyContext.Provider>
  );
};

// make sure use
export const useEditPropetyContext = () => {
  return useContext(EditPropertyContext);
};

export default EditProperty;

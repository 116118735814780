import React from "react";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../../components/GlobalStyle";
import CustomButton from "../../components/CustomButton/CustomButton";
import { RegistrationErrorModalStyle } from "./style";

interface RegistrationErrorModalProps {
  errorMessage: string;
  dismissHandler: () => void;
}

const RegistrationErrorModal: React.FC<RegistrationErrorModalProps> = ({
  errorMessage,
  dismissHandler,
}) => {
  const navigate = useNavigate();

  return (
    <RegistrationErrorModalStyle>
      <div className="inner-container">
        {/* Customize the content based on the error */}
        <h6 className="error-message my-2">Registration Error</h6>
        <p className="message">{errorMessage}</p>
        <div className="mt-1">
          <CustomButton
            bgcolor="transparent"
            color={primaryColor}
            padding="8px 8px"
            width="100%"
            type="button"
            title="DISMISS"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="18px"
            clicked={dismissHandler}
          />
        </div>
      </div>
    </RegistrationErrorModalStyle>
  );
};

export default RegistrationErrorModal;

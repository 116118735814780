import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey2,
  primaryColor,
  fontFamilyBold,
} from "../GlobalStyle";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  p {
    margin: 0;
  }
  figure {
    margin: 0;
    height: 60px;
    width: 60px;
    border-radius: 7px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    &.highlighted {
      font-family: ${fontFamilyMedium};
      color: ${primaryColor};
    }
  }
  .heading {
    font-size: 18px;
    font-family: ${fontFamilyMedium};
  }
  .address {
    font-family: ${fontFamilyMedium};
  }
  .icon-container {
    position: relative;
    z-index: 10;
    button {
      padding: 4px;
      border-radius: 5px;
      img {
        height: 35px;
        width: 35px;
        padding: 5px;
      }
      &:disabled {
        background: #ccc; /* Change the background color for disabled buttons */
      }
      &:not(:disabled).active {
        background: #c4102a;
      }
      &:nth-child(2):not(:disabled),
      &:nth-child(2).active {
        background: #0a84ff; /* Background color for active nth-child(2) button */
      }

      &:nth-child(3):not(:disabled),
      &:nth-child(3).active {
        background: #34c759; /* Background color for active nth-child(3) button */
      }
    }
  }
  .reason-section {
    border-top: 1px solid ${lightGrey2};
    padding-top: 6px;
  }
`;

export default Wrapper;

export const BookingOptionsStyle = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  transition: all 0.4s;
  &:focus {
    width: 100%;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    text-decoration: none;
    .title {
      font-family: ${fontFamilyBold};
    }
    li {
      padding: 7px 7px;
      color: rgba(0, 0, 0, 0.7);
      box-shadow: 0px 0.2px 0.2px rgba(0, 0, 0, 0.3);
      font-weight: 500;
      cursor: pointer;
      transition: all 0.1s;
      font-size: 18px;
      font-family: ${fontFamilyMedium};

      /* &:hover {
        box-shadow: 0.2px 0.1px 0.1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
      } */
      &:focus {
        filter: brightness(50%);
        background-color: rgba(0, 0, 0, 0.9);
        color: rgba(255, 255, 255, 0.3);
        transform: scale(1);
      }
      /* &:active {
        background-color: rgba(0, 0, 0, 0.7);
        color: rgba(255, 255, 255, 0.7);
      } */
    }
  }

  /* scale up amazing animation if want to remove then remove it */
  /* 
li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  transform: translateZ(0);
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: ease-out;
}

li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transform: translateX(-50%);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

li:hover {
  transform: translateY(-10px) translateZ(0);
}

li:hover:before {
  opacity: 1;
} */
`;

export const ShortNoteModalStyle = styled.div`
  background-color: white;
  padding: 12px;
  border-radius: 6px;

  .heading {
    font-family: ${fontFamilyMedium};
    text-align: center;
  }
  .hr.rounded {
    border-top: 3px solid #bbb;
  }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
`;

import { Form } from "react-bootstrap";
import { PostPrivacyStyle } from "./style";
import { useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import { TertiraryHeadingMini, primaryColor } from "../GlobalStyle";
import { authorizationToken, privacy_policy_url } from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useNavigate } from "react-router-dom";

// privacy options
const privacyOptions = [
  {
    title: "Hide like and view counts",
    sub_title:
      "Only you will see the total number of likes and views on this post. You can change this later by going to the menu at the top of the post.",
    name: "hideLikesAndViewsCounts",
  },
  {
    title: "Turn off commenting",
    sub_title:
      "You can change this later by going to the menu at the top of your post.",
    name: "turnOffCommenting",
  },
  {
    title: "Read comments",
    sub_title:
      "This post's comment and view count is only visible to you. You can change this later in the post's menu.",
    name: "readComments",
  },
  {
    title: "Share or copy post",
    sub_title:
      "This post can be shared on other media and its link can be copied for sharing on other social media platforms. You can modify this later in the post's menu.",
    name: "sharingEnabled",
  },
  {
    title: "Saved favorites",
    sub_title:
      "This post has been saved and added to your favorites list. You can modify this later in the post's menu",
    name: "saveFavourite",
  },
];

const StoryPrivacy = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const { selectedStory } = useNewsfeedContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [postPrivacyOptions, setPostPrivacyOptions] = useState({
    hideLikesAndViewsCounts: false,
    turnOffCommenting: false,
    sharingEnabled: false,
    saveFavourite: false,
    readComments: false,
    contactRequestPermission: false,
    boostPermission: false,
  });

  // update privacy policy
  const updatePrivacyPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        privacy_policy_url,
        {
          useCase: "STORY",
          id: selectedStory.storyId,
          contactRequestPermission: true,
          boostPermission: true,
          hideLikesAndViewsCounts: false,
          turnOffCommenting: false,
          readComments: true,
          sharingEnabled: false,
          saveFavourite: false,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  console.table(postPrivacyOptions);
  return (
    <>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Update Story Privacy"
          description="Story privacy updated successfully"
          doTask={() => navigate("/explore-nearby")}
        />
      </CustomModal>
      <PostPrivacyStyle className="mt-3 mt-md-0">
        <TertiraryHeadingMini className="my-3">
          Post Privacy
        </TertiraryHeadingMini>
        <div className="inner-section">
          <div className="mt-2 pb-2 top-section">
            <div className="d-flex justify-content-between btm-border">
              <p className="text mini-title mb-2">Contact Request</p>
              <Form.Check
                onChange={(event) =>
                  setPostPrivacyOptions({
                    ...postPrivacyOptions,
                    contactRequestPermission: event.target.checked,
                  })
                }
                type="switch"
                id="custom-switch"
              />
            </div>
            <div className="d-flex justify-content-between post-request mt-2">
              <p className="text mini-title mb-0">Post Boost</p>
              <Form.Check
                type="switch"
                id="custom-switch"
                onChange={(event) =>
                  setPostPrivacyOptions({
                    ...postPrivacyOptions,
                    boostPermission: event.target.checked,
                  })
                }
              />
            </div>
          </div>
          <p className="advanced-title mt-2 mb-1">Advanced settings</p>
          <div className="advanced-settings">
            {privacyOptions.map(({ title, sub_title, name }, index) => (
              <div key={index}>
                <div className="d-flex justify-content-between">
                  <p className=" mini-title mb-0">{title}</p>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    onChange={(event) =>
                      setPostPrivacyOptions({
                        ...postPrivacyOptions,
                        [name]: event.target.checked,
                      })
                    }
                  />
                </div>
                <p className="sub-title">{sub_title}</p>
              </div>
            ))}

            <div>
              <div className="d-flex justify-content-between">
                <p className=" mini-title mb-0">Allow Permissions</p>
              </div>
              <p className="sub-title">
                You can change this later by going to the menu at the top of
                your post.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="4px"
                width="90%"
                type="submit"
                title="Submit"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                loading={isLoading}
                clicked={updatePrivacyPromise}
              />
            </div>
          </div>
        </div>
      </PostPrivacyStyle>
    </>
  );
};

export default StoryPrivacy;

import { time } from "console";
import { formatDistanceToNowStrict, parseISO, formatRelative, subDays, formatDistance, formatISO } from "date-fns";
import { date } from "yup";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";


const getTimeAgo = (dateTimeString: string): string => {
  console.log("date",dateTimeString);

  const dateTime = new Date(dateTimeString);
  var offset = (dateTime.getTimezoneOffset());
  const milliseconds = Date.UTC(
    dateTime.getFullYear(),
    dateTime.getMonth(),
    dateTime.getDate(),
    dateTime.getHours(),
    dateTime.getMinutes(),
    dateTime.getSeconds(),
  );
  const localTime = new Date(milliseconds)
  const timeAgo = formatDistanceToNowStrict(localTime, { addSuffix: true });
return  timeAgo


};

export default getTimeAgo;

export const FormattedDate = (dateString: string, serverTimeZone:string): string => {
  console.clear()
  console.log(serverTimeZone)
  // console.log("date",dateString);
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  const timeZone = serverTimeZone.slice(-3)
  return date.toLocaleDateString( timeZone, options);
};

import styled from "styled-components";
import { fontFamilyMedium } from "../../components/GlobalStyle";

const AddAccountStyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    height: 144px;
    width: 144px;
  }
  h6 {
    font-family: ${fontFamilyMedium};
    font-size: 18px;
  }
`;

export default AddAccountStyle;

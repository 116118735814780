import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Head from "../../../components/Head/Head";
import UserInfo from "../../../components/ItemInfo/ItemInfo";

import { ProfileDetailsStyle } from "./style";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import { getprofessionalDetail } from "../../../redux/features/professional/professionalDetailSlice";
import { truncateString } from "../../../utils/utilities";
import axios from "axios";
import { authorizationToken, user_profile_url } from "../../../utils/api_urls";
import { userProfileTypes } from "../types";
import ProfileDetailsBox from "./ProfileDetailBox";
import ProfileBoxSkeleton from "../../../components/Skeletons/Profile";
import ProfessionalInfoSkeleton from "../../../components/Skeletons/ProfessionalInfo";

function ProfileDetails() {
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState("");
  const [userProfileData, setUserProfileData] = useState(
    {} as userProfileTypes
  );
  const { data: loginData } = useAppSelector((state) => state.loginData);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { loading, result, error } = useAppSelector(
    (state) => state.professionalDetails
  );
  const professions = useAppSelector((state) => state.appData.data.professions);

  // user profile promise
  const userProfileDetailsPromise = async () => {
    try {
      setUserLoading(true);
      const { data } = await axios.post(
        user_profile_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setUserProfileData(data.results);
      console.log({ data });
      setUserLoading(false);
    } catch (error: any) {
      console.log({ error });
      setUserError(error.response.data.responseMessage);
      setUserLoading(false);
      console.log(error.response.data, "error in user profile promise data");
    }
  };
  useEffect(() => {
    userProfileDetailsPromise();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getprofessionalDetail(+id));
    }
  }, [id]);

  if (error) return <NoDataAvailable title={error} />;

  console.log({ result });
  const {
    professionalDetails: {
      address,
      bannerImage,
      businessName,
      businessRegisterDocURL,
      businessRegisterNumber,
      businessStartedDate,
      idProfileDocURL,
      professionalId,
      professionTypeId,
      professionalStatusId,
    } = {
      address: "",
      bannerImage: "",
      businessName: "",
      businessRegisterDocURL: "",
      businessRegisterNumber: "",
      businessStartedDate: "",
      idProfileDocURL: "",
      professionalId: "",
      professionTypeId: "",
      professionalStatusId: "",
    },
  } = result;

  return (
    <ProfileDetailsStyle>
      <Head title="user-details" />
      <Container className="inner-section">
        {userLoading || loading ? (
          <ProfileBoxSkeleton />
        ) : (
          <ProfileDetailsBox
            userData={userProfileData}
            professionalData={result}
          />
        )}
        {loading ? (
          <ProfessionalInfoSkeleton />
        ) : (
          <Row className="professional-section">
            {professionTypeId && (
              <Col sm={6} md={4}>
                <UserInfo
                  heading="Profession"
                  title={
                    (professions.find(({ id }) => id === professionTypeId) &&
                      professions.find(({ id }) => id === professionTypeId)
                        ?.name) ||
                    ""
                  }
                />
              </Col>
            )}

            {address && (
              <Col sm={6} md={4}>
                <UserInfo
                  heading="Address"
                  title={truncateString(address, 30)}
                />
              </Col>
            )}
            {businessName && (
              <Col sm={6} md={4}>
                <UserInfo heading="Business Name" title={businessName} />
              </Col>
            )}
            {businessStartedDate && (
              <Col sm={6} md={4}>
                <UserInfo
                  heading="Bussiness Start Date"
                  title={businessStartedDate.toString()}
                />
              </Col>
            )}
            {businessRegisterDocURL && (
              <Col sm={6} md={4}>
                <UserInfo
                  heading="Document"
                  title={truncateString(businessRegisterDocURL, 30)}
                />
              </Col>
            )}
            {businessRegisterNumber && (
              <Col sm={6} md={4}>
                <UserInfo
                  heading="Business Register Number"
                  title={businessRegisterNumber}
                />
              </Col>
            )}
          </Row>
        )}
      </Container>
    </ProfileDetailsStyle>
  );
}

export default ProfileDetails;

import { Col, Container, Row } from "react-bootstrap";
import SendRequestStyle from "./style";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import verified from "../../assets/icons/ic_verifed.svg";
import message from "../../assets/icons/ic_property_detail_chat.svg";
import call from "../../assets/icons/ic_property_detail_call.svg";
import video from "../../assets/icons/ic_video.svg";
import CustomButton from "../CustomButton/CustomButton";
import {
  lightGrey,
  primaryColor,
  pureDark,
  secondaryDark,
  whiteColor,
} from "../GlobalStyle";
import {
  authorizationToken,
  media_base_url,
  upcoming_requests_url,
} from "../../utils/api_urls";
import { useAppSelector } from "../../app/hooks";
import NoDataAvailable from "../NoData/NoDataAvailable";
import { useEffect, useState } from "react";
import { truncateString } from "../../utils/utilities";
import { Rate } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../redux/store";
import BookingsSkeleton from "../Skeletons/Bookings";
import { useNavigate } from "react-router-dom";

const PreviousRequests = () => {
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const { visitRejectionReasons } = useAppSelector(
    (state: RootState) => state.appData.data.statusList
  );
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { visitRequestStatus } = useAppSelector(
    (state: RootState) => state.appData.data.statusList
  );
  // receive id's
  const historyIds = [3, 5, 6, 7, 10, 11];
  const [visitRequestData, setVisitRequestData] = useState(
    visitRequestStatus
      .map((data) => {
        if (data.id === 3) {
          return { ...data, isSelect: true };
        }
        return { ...data, isSelect: false };
      })
      .filter(({ id }) => historyIds.includes(id))
  );
  const [error, setError] = useState("");
  const [requestHistoryData, setRequestHistoryData] = useState(
    {} as previousDataTypes
  );

  //   history booking promise
  const historyRequestPromise = async (requestStatusId: string) => {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await axios.post(
        upcoming_requests_url,
        {
          userId: loginData?.userDetails.id,
          requestStatusId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setRequestHistoryData(data.results);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  useEffect(() => {
    const selectedStatus = visitRequestData.find(
      ({ isSelect }) => isSelect === true
    );
    if (selectedStatus) {
      historyRequestPromise(selectedStatus.id.toString());
    }
  }, []);

  // toggle visit request
  const toggleHistoryRequests = (index: number) => {
    const oldData = [...visitRequestData];
    const newData = oldData.map((data) => ({ ...data, isSelect: false }));
    newData[index].isSelect = true;
    setVisitRequestData(newData);
  };
  const extractCityFromAddress = (address: string) => {
    const addressParts = address.split(', ');
    // Assuming there are at least two parts in the address
    return addressParts.length > 1 ? addressParts[addressParts.length - 2] : '';
  };
  const opennew = (propId: number, profId: number) => {
    if (propId) { navigate(`/property-details/${propId}`) }
    if (profId)
    //correct the url
    { navigate(`/property-details/${profId}`) }

  }
  return (
    <Container className="py-4">
      <Row>
        {visitRequestData.map(({ name, id, isSelect }, index) => (
          <Col md={2} className="mt-2">
            <CustomButton
              key={id}
              bgcolor={isSelect ? primaryColor : "transparent"}
              color={isSelect ? whiteColor : secondaryDark}
              padding="8px 8px"
              width="100%"
              border={`1px solid ${isSelect ? primaryColor : lightGrey}`}
              type="submit"
              title={name}
              margin="auto"
              textTransform="Captilize"
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              clicked={() => {
                toggleHistoryRequests(index);
                historyRequestPromise(id.toString());
              }}
            />
          </Col>
        ))}
      </Row>
      {isLoading ? (
        <BookingsSkeleton />
      ) : error ? (
        <NoDataAvailable title={error} />
      ) : (
        <Row>
          {requestHistoryData.upComingRequests.map((requestData) => {
            const {
              bookingId,
              visitor,
              description,
              visitType,
              address,
              visitDate,
              visitStartTime,
              visitEndTime,
              requestStatusId,
              shortNote,
              rattingStar,
              rejectReasonId,
              propertyId, 
              professionalId
            } = requestData;
            const { firstName, lastName, profilePicture,phoneNumber } = visitor;
            return (
              <Col sm={6} md={4} key={bookingId}>
                <SendRequestStyle>
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex gap-3">
                      <figure className="m-0 profile">
                        <img
                          onClick={() => navigate(`/professional/${visitor.id}`)}
                          src={
                            visitor.hasOwnProperty("profilePicture")
                              ? media_base_url + profilePicture
                              : placeholder
                          }
                          className="rounded cursor-pointer"
                          alt={firstName}
                        />
                      </figure>
                      <div>
                        <h4                           
                        onClick={() => opennew(propertyId, professionalId)}
                        className="m-0 d-flex gap-2 heading cursor-pointer">
                          {/* <i>
                            <img src={verified} alt="placeholder" />
                          </i> */}
                          {firstName} {lastName}
                        </h4>
                        <p className="m-0 address">
                        {phoneNumber}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end gap-2">
                      <div className="d-flex gap-2 icon-container">
                        {/* <button
                          className="active"
                          disabled={
                            requestStatusId === 6 || requestStatusId === 7
                              ? true
                              : false
                          }
                        >
                          <img src={message} alt="message" />
                        </button> */}
                        {visitType !== "IN_PERSON" && (
                          <button
                            disabled={
                              requestStatusId === 6 || requestStatusId == 7
                                ? true
                                : false
                            }
                          >
                            <img src={video} alt="video" />
                          </button>
                        )}
                        {/* <button
                          disabled={
                            requestStatusId === 6 || requestStatusId == 7
                              ? true
                              : false
                          }
                        >
                          <img src={call} alt="call" />
                        </button> */}
                      </div>
                    </div>
                  </div>

                  <div className="my-3">
                    {visitRequestStatus.find(({ id }) => id === requestStatusId)
                      ?.name !== "Pending" ? (
                      <div className="d-flex">
                        {/* <p className="highlighted text-dark">Status:</p>
                        <p className="highlighted ms-2">
                          {visitRequestStatus.find(
                            ({ id }) => id === requestStatusId
                          )?.name === "Confirmed"
                            ? "Accepted"
                            : visitRequestStatus.find(
                                ({ id }) => id === requestStatusId
                              )?.name}
                        </p> */}
                      </div>
                    ) : (
                      <div className="d-flex gap-2">
                        <CustomButton
                          bgcolor="transparent"
                          color={pureDark}
                          padding="8px"
                          width="100%"
                          type="submit"
                          border={`1px solid ${pureDark}`}
                          title="REJECTED"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          fontSize="16px"
                          clicked={() => {}}
                        />
                        <CustomButton
                          bgcolor={primaryColor}
                          color="white"
                          padding="8px"
                          width="100%"
                          type="submit"
                          title="ACCEPT"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          fontSize="16px"
                        />
                      </div>
                    )}
                  </div>
                  <div className="reason-section d-flex my-2" />

                  <div className="d-flex align-items-center">
                    <p className="highlighted">
                      {visitType === "IN_PERSON" ? "in person" : "virtual"}
                    </p>
                    <p className="ms-3">{visitDate}</p>
                    <p className="ms-3">
                      {visitStartTime}
                      {visitEndTime && ` to ${visitEndTime}`}
                    </p>
                  </div>
                  <div className="reason-section d-flex my-2" />

                  <p className="my-2">{truncateString(description, 116)}</p>
                 
                

                  {requestStatusId === 5 && (
                    <div className="my-2 reason-section">
                      <Rate
                        style={{ color: primaryColor }}
                        defaultValue={rattingStar ?? 0}
                        disabled
                      />
                      {shortNote && <p>{shortNote}</p>}
                    </div>
                  )}
                

                  {requestStatusId === 6 && (
                    <div className="my-2 reason-section">
                      <div className="d-flex">
                        <p className="highlighted">Reject Reason:</p>
                        <p className="ms-2">
                          {visitRejectionReasons.find(
                            ({ id }) => id === rejectReasonId
                          ) &&
                            visitRejectionReasons.find(
                              ({ id }) => id === rejectReasonId
                            )?.name}
                        </p>
                      </div>
                      {shortNote && <p>{shortNote}</p>}
                    </div>
                  )}
                </SendRequestStyle>
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default PreviousRequests;

export interface previousDataTypes {
  totalItems: number;
  upComingRequests: UpComingRequest[];
  totalPages: number;
  currentPage: number;
}

export interface UpComingRequest {
  bookingId: number;
  createdDateTime: string;
  visitType: string;
  description: string;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: any;
  newsFeedId: number;
  requestStatusId: number;
  rejectReasonId?: number;
  address: string;
  postType: string;
  visitor: Visitor;
  shortNote?: string;
  rattingStar?: number;
  propertyId:number, professionalId:number
}

export interface Visitor {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  id: number;
}

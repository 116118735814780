import Card from "react-bootstrap/Card";
import bedroomIcon from "../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../assets/icons/ic_property_area.svg";
import {
  ImgContainer,
  MiniHeadingSecondary,
  primaryColor,
  ProfileMiniImg,
  tertiaryGrey20,
} from "../../components/GlobalStyle";
import { Button } from "react-bootstrap";
import unLikeIcon from "../../assets/icons/ic_newsfeed_like.svg";
import sent from "../../assets/icons/ic_newsfeed_sent.svg";
import comment from "../../assets/icons/ic_newsfeed_comment.svg";
import save from "../../assets/icons/ic_newsfeed_saved.svg";
import { BgImage, PropertyPostStyle } from "./style";
import CustomLink from "../../components/CustomLink/Index";
import moreOptionIcon from "../../assets/icons/ic_newsfeed_more.svg";
import AliceCarousel from "react-alice-carousel";
import { NewsFeedRecord } from "../../utils/types";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  follow_url,
  like_url,
  media_base_url,
  unfollow_url,
} from "../../utils/api_urls";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import likeIcon from "../../assets/icons/ic_heart_like.svg";
import getTimeAgo from "../../components/GetTimeAgo";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { RootState } from "../../redux/store";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import useDeleteEvent from "../../hooks/useDelete";
import PostPrevBtn from "../../components/Custom/PostPrevBtn";
import PostNextBtn from "../../components/Custom/PostNextBtn";
import { numberToLocalString, truncateString } from "../../utils/utilities";
import SlidePrevBtn from "../../components/Custom/SlidePrevBtn";
import SlideNextBtn from "../../components/Custom/SlideNextBtn";
import { FiChevronRight } from "react-icons/fi";
import ViewmoreIcon from "../../components/Custom/ViewmoreIcon";

// carosuel responseive
const responsive = {
  0: { items: 1 },
  //   568: { items: 3.2 },
  //   768: { items: 4.2 },
  //   1024: { items: 5.2 },
  //   1200: { items: 7.2 },
  //   1400: { items: 9.2 },
};

type PostProps = {
  postData: NewsFeedRecord;
  selectedPostHandler: (postData: NewsFeedRecord, post_type: string) => void;
};

const PropertyPost: React.FC<PostProps> = ({
  postData,
  selectedPostHandler,
}) => {
  const [propertyImgIndex, setPropertyImgIndex] = useState(0);
  const {
    createdDateTime,
    imagesURLS,
    description,
    address,
    propertyBedroom,
    propertySize,
    propertyUnit,
    propertyBathroom,
    propertyDiscountPrice,
    propertyOriginalPrice,
    propertyId,
    propertyCurrencySymbol,
    propertyTitle,
    hideLikesAndViewsCounts,
    propertyPurpose,
    contactRequestPermission,
    saveFavourite,
    readComments,
    turnOffCommenting,
    sharingEnabled,
    propertyType,

    lastLikeAndComment: {
      lastLikedUserId,
      lastCommentUserId,
      lastCommentUserFirstName,
      lastLikedUserLastName,
      lastCommentText,
      lastLikedUserFirstName,
      lastCommentUserProfilePicture,
      lastCommentUserLastName,
      totalLikes,
      totalComments,
      isLiked,
    },
    videoURL,
    newsFeedId,
    ownerData: {
      firstName,
      lastName,
      id: ownerId,
      profilePicture,
      isFollower,
      isFollowing,
    },
  } = postData;
  const [error, setError] = useState("");
  const [likesLoading, setLikesLoading] = useState(false);
  const [likesData, setLikesData] = useState(false);
  const [likesError, setLikesError] = useState(false);
  const { newsfeedData, setNewsfeedData } = useNewsfeedContext();
  const navigate = useNavigate();
  const likeToastId = useRef<any>();
    // unfollow promise
    const {
      apiDataPromise: unFollowPromise,
      loading,
      data: unFollowResults,
      error: unFollowError,
    } = useDeleteEvent();
  
  const userData = useAppSelector(
    (state: RootState) => state.loginData.data?.userDetails
  );
    const propertySizeTypes = useAppSelector(
    (state) =>
      state.appData.data.propertyAppData[propertyType! - 1].propertySizeDTOS
  );
  console.log({ propertyType });
  // login data
  const { data } = useAppSelector((state: RootState) => state.loginData);

  // property slides items
  const items = [
  ...imagesURLS.map((img) => (
  <BgImage>
  <div className="post position-relative">
  <div className="post-image">
  <img src={media_base_url + img} alt="post" />
  </div>
  </div>
  </BgImage>
  )),
  ];

  // checking whether item is video?
  if (videoURL) {
  items.unshift(
  <video width="100%" height="300" controls>
  <source src={media_base_url + videoURL} type="video/mp4" />
  Your browser does not support the video tag.
  </video>
  );
  }

  // getting loginData from redux
  const { data: loginData } = useAppSelector((state) => state.loginData);

  // follow api promsie
  const followPromise = async () => {
    try {
      const data: any = await axios.post(
        follow_url,
        {
          followToUserId: ownerId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );

      toast(data.responseMessage);
      navigate("/explore-nearby");

    } catch (err: any) {
      toast(err.response.data.responseMessage);
      setError(err.response.data.responseMessage);
    }
  };


  //add like types
  type addLikeTypes = {
    newsFeedId: number;
    userId: number;
    isLike: boolean;
  };
  console.log("owner id",ownerId,"login",loginData?.userDetails.id);
  
  // add likes promise handler
  const addLikesPromiseHandler = async ({
    isLike,
    newsFeedId,
    userId,
  }: addLikeTypes) => {
    console.log({ isLike });
    try {
      setLikesLoading(true);
      const { data: likesData } = await axios.post(
        like_url,
        {
          newsFeedId: newsFeedId.toString(),
          userId: userId.toString(),
          isLike: !isLike,
        },
        {
          headers: {
            Authorization: `Bearer ${data?.jwtDetails.token}`,
          },
        }
      );

      setLikesData(likesData.results);
      setLikesLoading(false);

      likeToastId.current = toast("add like successfully", {
        type: "success",
      });
    } catch (error: any) {
      setLikesError(error.response.data.responseMessage);
      setLikesLoading(false);
      likeToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  console.log({ propertySize });

  // old property post data
  const oldNewsfeedData = { ...newsfeedData };

  return (
    <PropertyPostStyle>
      {/* top profile details */}
      <div className="post-owner-section d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <ImgContainer
            src={profilePicture ? media_base_url + profilePicture : placeholder}
            alt={firstName}
            height="40px"
            width="40px"
            className="cursor-pointer"
            onClick={() => navigate(`/professional/${ownerId}`)}
          />
          <div className="ms-3">
            <div className="d-flex">
              <p
                className="name mb-0 cursor-pointer"
                onClick={() => navigate(`/professional/${ownerId}`)}
              >
                {firstName} {lastName}
              </p>
              {ownerId !== data?.userDetails.id && (
                <div className="ms-2">
                  <CustomButton
                    bgcolor="transparent"
                    color={primaryColor}
                    padding="0px"
                    width="auto"
                    type="submit"
                    textTransform="Captilize"
                    title={isFollowing ? "UnFollow" : "Follow"}
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    clicked={() => {
                      setNewsfeedData({
                        ...oldNewsfeedData,
                        newsFeedRecords: oldNewsfeedData.newsFeedRecords.map(
                          (newsfeed) => {
                            if (postData?.newsFeedId === newsfeed.newsFeedId) {
                              return {
                                ...newsfeed,
                                ownerData: {
                                  ...newsfeed.ownerData,
                                  isFollowing: !newsfeed.ownerData.isFollowing,
                                },
                              };
                            }
                            return newsfeed;
                          }
                        ),
                      });
                      isFollowing
                        ? unFollowPromise(unfollow_url, {
                            unFollowToUserId: ownerId,
                          }).then(() => {
                            toast("Unfollow Successsfully");
                          })
                        : followPromise();
                    }}
                  />
                </div>
              )}
            </div>
            <p className="time mb-0">{getTimeAgo(createdDateTime)}</p>
          </div>
        </div>
        <ViewmoreIcon
          onClick={() => {
            selectedPostHandler(postData, "property_options");
          }}
        />
      </div>
      <Card>
        <div className="position-relative">
          <AliceCarousel
            mouseTracking
            items={items}
            activeIndex={propertyImgIndex}
            responsive={responsive}
            controlsStrategy="alternate"
            disableButtonsControls
            disableDotsControls={items.length < 2}
          />
          {propertyImgIndex > 0 && (
            <SlidePrevBtn
              clicked={() => setPropertyImgIndex((prev) => prev - 1)}
            />
          )}
          {imagesURLS.length > 1 &&
            propertyImgIndex < imagesURLS.length - 1 && (
              <SlideNextBtn
                clicked={() => setPropertyImgIndex((prev) => prev + 1)}
              />
            )}
          <div
            className="visited-text d-flex justify-content-between w-100 align-items-center"
            onClick={() => navigate(`/property-details/${propertyId}`)}
          >
            <h6 className="mb-0 text-light">Visit Now</h6>
            <span className="fs-3">
              <FiChevronRight color="white" />
            </span>
          </div>
        </div>
        {/* location and description  */}
        <Card.Body
          className="cursor-pointer pb-0"
          onClick={() => {
            navigate(`/property-details/${propertyId}`);
          }}
        >
          <MiniHeadingSecondary>{propertyTitle}</MiniHeadingSecondary>
          <Card.Text className="mt-1">
            <p className="sub-title">{address}</p>
          </Card.Text>
          <div className="d-flex justify-content-between align-items-center property-description mb-2">
            <Button variant="primary">For {propertyPurpose}</Button>
            <p className="price m-0">
              {propertyCurrencySymbol}
              {numberToLocalString(+propertyOriginalPrice)}
              {/* <span className="discount ms-2">
              {propertyCurrencySymbol}
              {propertyDiscountPrice}
              </span> */}
            </p>
          </div>
          <div className="property-details d-flex justify-content-between align-items-center">
            {/* property bedrooms handler */}
            {propertyBedroom && (
              <div className="bed d-flex justify-content-between align-items-center">
                <span>
                  <img src={bedroomIcon} alt="bed" />
                </span>
                <p className="text">{propertyBedroom} Bedroom</p>
              </div>
            )}

            {/* property bathrooms handler */}
            <div className="bathroom d-flex justify-content-between align-items-center">
              <span>
                <img src={bathIcon} alt="bath" />
              </span>
              <p className="text">{propertyBathroom} Bathroom</p>
            </div>

            {/* property size handler */}
            {propertySize && (
              <div className="size d-flex justify-content-between align-items-center">
                <span>
                  <img src={areaIcon} alt="area" />
                </span>
                <p className="text">
                  {propertySize}
                  {
                    propertySizeTypes.find(
                      ({ keyName }) => keyName === propertyUnit
                    )?.displayName
                  }
                </p>
              </div>
            )}
          </div>
        </Card.Body>
        <div className="react d-flex justify-content-between align-items-center">
          <div className="react-icons mt-2 px-2">
                          <img
                className="cursor-pointer like-icon"
                src={isLiked ? likeIcon : unLikeIcon}
                onClick={(event) => {event.stopPropagation();
                  setNewsfeedData({
                    ...oldNewsfeedData,
                    newsFeedRecords: oldNewsfeedData.newsFeedRecords.map(
                      (newsfeed) => {
                        if (postData?.newsFeedId === newsfeed.newsFeedId) {
                          return {
                            ...newsfeed,
                            lastLikeAndComment: {
                              ...newsfeed.lastLikeAndComment,
                              isLiked: !newsfeed.lastLikeAndComment.isLiked,
                            },
                          };
                        }
                        return newsfeed;
                      }
                    ),
                  });

                  addLikesPromiseHandler({
                    isLike: isLiked,
                    newsFeedId: newsFeedId!,
                    userId: ownerId!,
                  });
                }}
                alt={isLiked ? "likeicon" : "unlike"}
              />
                 {saveFavourite && (
              <span>
                <img
                  className="ms-2 cursor-pointer save-icon"
                  src={save}
                  alt="save"
                />
              </span>
            )}
            {/* checking contact us allow or not */}
            {!turnOffCommenting && (
              <button
                disabled={turnOffCommenting || readComments}
                onClick={(event) => {
                  event.stopPropagation();
                  selectedPostHandler(postData, "comment");
                }}
                className="bg-transparent"
              >
                <img src={comment} alt="comment" />
              </button>
            )}
</div>
            {sharingEnabled && (
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  selectedPostHandler(postData, "social");
                }}
                className="bg-transparent"
              >
                <img src={sent} alt="send" />
              </button>
            )}
          </div>
          <div className="d-flex align-items-center">
          
         
          </div>
          <div className="d-flex align-items-center">
            {loginData?.userDetails.id === ownerId ? (
               ""
              ) : (
                <CustomButton
                  bgcolor="transparent"
                  color={primaryColor}
                  padding="8px 8px"
                  width="100%"
                  type="submit"
                  title="Contact"
                  margin="auto"
                fontFamily="EnnVisionsMedium"
                  fontSize="16px"
                  textTransform="Captilize"
                  clicked={() => {
                    navigate("/in-person", {
                      state: {
                        newsFeedId,
                        visitType: "IN_PERSON",
                      },
                    });
                  }}
                />
             )}
           </div> 
                {/* checking like exist in post */}
        <div className="caption-section px-2">
          {lastLikedUserId && (
            <div
              className="d-flex align-items-center"
              onClick={() => {
                selectedPostHandler(postData, "like");
              }}
            >
              {/* <ProfileMiniImg
                  width="21px"
                  height="21px"
                  borderRadius="50%"
                  src={profile}
                  alt="profile"
                /> */}
              <p className="mb-0 ms-2 cursor-pointer  liked-by">
                Liked by
                <strong className="ms-2">
                  {lastLikedUserFirstName} {lastLikedUserLastName}
                </strong>
                {totalLikes > 1 && (
                  <span className="ms-2">
                    and
                    <strong className="ms-2">{totalLikes} </strong>
                    others
                  </span>
                )}
              </p>
            </div>
          )}
          {lastCommentUserId && (
            <p className="post-caption my-1 ms-2">
              <ProfileMiniImg
                width="21px"
                height="21px"
                className="mb-2"
                borderRadius="50%"
                src={media_base_url + lastCommentUserProfilePicture}
                alt={lastCommentUserFirstName}
              />
              <strong className="ms-2">
                {lastCommentUserFirstName} {lastCommentUserLastName}
              </strong>
              <span className="ms-2">
                {truncateString(lastCommentText, 220)}
              </span>
            </p>
          )}
          {/* checking contact exist in post */}
          {lastCommentUserId && (
            <button
              disabled={!readComments}
              onClick={(event) => {
                event.stopPropagation();
                selectedPostHandler(postData, "comment");
              }}
              className="p-1 rounded ms-1 mb-2"
            >
              <CustomLink
                fontSize="15px"
                color={tertiaryGrey20}
                link="#"
                title={`View all ${totalComments} Comments`}
                textDecoration="none"
              />
            </button>
          )}
        </div>
      </Card>
    </PropertyPostStyle>
  );
};

export default PropertyPost;

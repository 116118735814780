import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { location_url } from "../../../utils/api_urls";
import axios from "axios";

type searchUserByAdminTypes = {
  keyword: string;
};
type initialStateTypes = {
  loading: boolean;
  result: null | searchUserByAdminTypes;
  error: any;
};
const initialState: initialStateTypes = {
  loading: false,
  result: {} as searchUserByAdminTypes,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const searchUserByAdmin = createAsyncThunk(
  "user/searchUserByAdmin",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios({
        url: location_url,
      });
      console.log(data, "user location");
      return data.results;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const userLocationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchUserByAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      searchUserByAdmin.fulfilled,
      (state, action: PayloadAction<searchUserByAdminTypes>) => {
        state.result = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(searchUserByAdmin.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload || "Something went wrong";
    });
  },
});

export default userLocationSlice.reducer;

import { Col, Row } from "react-bootstrap";
import selectIcon from "../../assets/icons/ic_select.svg";
import unSelectIcon from "../../assets/icons/ic_add_property_additional.svg";
import Headbar from "../../components/Headbar/Headbar";
import { FeatureDetailsStyle } from "./style";
import addFeatureIcon from "../../assets/icons/ic_add_property_general_info_add.svg";
import { useFormikContext } from "formik";
import { TertiraryHeading } from "../../components/GlobalStyle";
import plusIcon from "../../assets/icons/ic_maximum.svg";
import minusIcon from "../../assets/icons/ic_minimum.svg";
import InputField from "../../components/InputField/InputField";
import { useGlobalContext } from "../../context/context";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../redux/store";
import Loader from "../../components/Loader/Loader";
import TextField from "../../components/InputField/TextField";
import { useGeneratePropetyContext } from "./CreateProperty";
import { generatePropertyInitTypes } from "./types";
import { usePropertyContext } from "../../context/PropertyContext";
import { ChangeEvent } from "react";

const FeatureDetails = () => {
  const {
    propertyExtraOptions,
    propertyServiceOptions,
    propertyUniqueOptions,
    setPropertyExtraOptions,
    setPropertyServiceOptions,
    setPropertyUniqueOptions,
    facingData,
    setFacingData,
  } = useGeneratePropetyContext();

  const { generatePropertyNumber } = usePropertyContext();

  const {
    data: { propertyAppData },
  } = useAppSelector((state: RootState) => state.appData);

  // formik context provider
  const { values, setFieldValue, setValues, errors, touched } =
    useFormikContext<generatePropertyInitTypes>();
  console.log(">>added Updated values:", values);
  // property data
  const propertyData = propertyAppData[generatePropertyNumber];
 const extraOptions=["balcony","SwimmingPool","homeTheatre","gardenLawn","outdoorKitchen","parkingSpaces","walkOutBasement","finishedBasement" ,"detachedGarage"]
console.log("propertyServiceOptions",propertyExtraOptions)
  //   property unique options
  const handlePropertyUniqueOptions = (index: number) => {
    const oldUniqueOptions = [...propertyUniqueOptions];
    const selectedOptionData = oldUniqueOptions.find(({ id }) => id === index);
    if (!selectedOptionData) {
      return;
    }
    selectedOptionData.isSelect = !selectedOptionData.isSelect;
    setFieldValue(selectedOptionData.keyName, !selectedOptionData.isSelect);
    setPropertyUniqueOptions(oldUniqueOptions);
    setFieldValue(
      `propertyUniqueOptions.${selectedOptionData.keyName}`,
      selectedOptionData.isSelect
    );
  };

  //   property service options
  const handlePropertyServiceOptions = (index: number) => {
    const oldPropertyServiceOptions = [...propertyServiceOptions];
    const selectedOptionData = oldPropertyServiceOptions.find(
      ({ id }) => id === index
    );
    if (!selectedOptionData) {
      return;
    }
    selectedOptionData.isSelect = !selectedOptionData.isSelect;
    setPropertyServiceOptions(oldPropertyServiceOptions);
    setFieldValue(
      `propertyServices.${selectedOptionData.keyName}`,
      selectedOptionData.isSelect
    );
  };

  // property extra options
  const changeExtraOptionHandles = (index: number, action: string) => {
    const oldExtraOptions = [...propertyExtraOptions];
    const selectedPropertyInfo = oldExtraOptions.find(({ id }) => id === index);
    console.log(">>addedSelected", selectedPropertyInfo);

    if (!selectedPropertyInfo) {
      return;
    }

    if (action === "add") {
      console.log(">>added");
      selectedPropertyInfo.value = selectedPropertyInfo.value + 1;
      // Update the corresponding property in the Formik values object
      setFieldValue(
        `propertyExtraOptions.${selectedPropertyInfo.keyName}`,
        selectedPropertyInfo.value
      );
    } else {
      selectedPropertyInfo.value = selectedPropertyInfo.value - 1;
      if (selectedPropertyInfo.value <= 0) {
        console.log(">>addedAfter");
        // Check if the property exists before removing it
        if (
          values.propertyExtraOptions.hasOwnProperty(
            selectedPropertyInfo.keyName
          )
        ) {
          // Remove the property from the Formik values object
          const restValues = values.propertyExtraOptions;
          console.log({ restValues });
          Object.keys(restValues).map((k) => {
            if (restValues[k].value <= 0) delete restValues[k];
          });
          setFieldValue("propertyExtraOptions", { ...restValues });
        }
        return;
      }
      console.log(">>addedAfter12");
    }

    // Update the propertyExtraOptions array
    setPropertyExtraOptions(oldExtraOptions);
  };

  // facing direction handler
  const facingDataHandler = (index: number) => {
    const oldFacingData = [...facingData];
    const updatedData = oldFacingData.map((oldData, itemIndex) => {
      if (itemIndex === index) {
        return { ...oldData, isSelect: true };
      } else {
        return { ...oldData, isSelect: false };
      }
    });
    const facingDataSelected = updatedData.find(
      ({ isSelect }) => isSelect === true
    );
    if (facingDataSelected) {
      setFieldValue("facingAndFlorInfo.direction", facingDataSelected.abbre);
    }
    setFacingData(updatedData);
  };
  console.log({ values });
  return (
    <FeatureDetailsStyle>
      <div className="mt-4">
        <Headbar title="Additional Details" icon={addFeatureIcon} />
      </div>
      {propertyData.propertyExtraOptionDTOS.length !== 0 && (
        <>
          <TertiraryHeading className="mt-3">
            About Your Property(Optional)
          </TertiraryHeading>
          <Row>
            {propertyExtraOptions.length === 0 ? (
              <Loader />
            ) : (
              propertyExtraOptions.filter(room => extraOptions.includes(room.keyName)).map(
                ({ displayName, keyName, id, value }) => (
                  <Col
                    key={id}
                    className="input-section d-flex align-items-center mt-2"
                    sm={6}
                    md={4}
                  >
                    <InputField
                      maxRange={20}
                      textAlign="left"
                      title={displayName}
                      value=""
                    />
                    {value > 0 && (
                      <>
                        <img
                          src={minusIcon}
                          className="arithmetic-icon"
                          alt="minus-icon"
                          onClick={() => {
                            changeExtraOptionHandles(id, "minus");
                            setFieldValue(
                              `propertyExtraOptions.${keyName}`,
                              value - 1
                            );
                          }}
                        />
                        <h6 className="mx-2 fw-bold mb-0">{value}</h6>
                      </>
                    )}
                    <img
                      onClick={() => {
                        changeExtraOptionHandles(id, "add");
                        setFieldValue(
                          `propertyExtraOptions.${keyName}`,
                          value + 1
                        );
                      }}
                      src={plusIcon}
                      className="arithmetic-icon"
                      alt="plus-icon"
                    />
                  </Col>
                )
              )
            )}
          </Row>
          {propertyData.propertyUniqueOptionDTOS.length !== 0 && (
            <div className="features-container pb-3">
              <TertiraryHeading className="mt-3">
                Make Your Property Unique (Optional)
              </TertiraryHeading>
              <Row>
                {propertyUniqueOptions.map(({ displayName, isSelect, id }) => (
                  <Col key={id} sm={6} md={4}>
                    <div
                      onClick={() => handlePropertyUniqueOptions(id)}
                      className="feature cursor-pointer d-flex align-items-center"
                    >
                      <span>
                        <img
                          className="mb-1"
                          src={isSelect ? selectIcon : unSelectIcon}
                          alt={isSelect ? "check-icon" : "uncheck-icon"}
                        />
                      </span>
                      <p className="feature-text mb-0 ms-2">{displayName}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {propertyData.propertyServicesDTOS.length !== 0 && (
            <>
              <div className="features-container pb-3">
                <TertiraryHeading className="mt-3">
                  Specify the Services (Optional)
                </TertiraryHeading>
                <Row>
                  {propertyServiceOptions.map(
                    ({ keyName, displayName, isSelect, id }) => (
                      <Col key={id} sm={6} md={4}>
                        <div
                          onClick={() => handlePropertyServiceOptions(id)}
                          className="feature cursor-pointer d-flex align-items-center"
                        >
                          <span>
                            <img
                              src={isSelect ? selectIcon : unSelectIcon}
                              alt={isSelect ? "check-icon" : "uncheck-icon"}
                            />
                          </span>
                          <p className="feature-text mb-0 ms-2">
                            {displayName}
                          </p>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </>
          )}
          {propertyData.typeName !== "Plot" && (
            <>
              <TertiraryHeading className="mt-3">
                Facing & Floors Info (Optional)
              </TertiraryHeading>
              <Row className="propery-results align-items-center mt-3">
                <Col sm={6} md={4} className="mt-2 mt-sm-0">
                  <TextField
                    name="numberOfFloors"
                    label="Number of levels"
                    placeholder="Number of levels"
                    padding="8px"
                    type="number"
                    labelMarginBottom="12px"
                    // onPaste={()=>{}}
                    value={values.facingAndFlorInfo.numberOfFloors}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (+event.target.value >= 0) {
                        setFieldValue(
                          "facingAndFlorInfo.numberOfFloors",
                          event.target.value.toString()
                        );
                      }
                    }}
                  />
                </Col>
                {/* <Col sm={6} md={4} className="mt-2 mt-sm-0">
                  <TextField
                    name="yourFloor"
                    label="Your Floor"
                    placeholder="Your Floor"
                    padding="8px"
                    type="number"
                    labelMarginBottom="12px"
                    value={values.facingAndFlorInfo.yourFloor}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (+event.target.value >= 0) {
                        setFieldValue(
                          "facingAndFlorInfo.yourFloor",
                          event.target.value.toString()
                        );
                      }
                    }}
                  />
                </Col> */}
                <Col sm={6} md={4} className="mt-2 mt-sm-0"></Col>
              </Row>
            </>
          )}
          <div className="features-container pb-3">
            <TertiraryHeading className="mt-3">Facing</TertiraryHeading>
            <Row>
              {facingData.map(({ title, isSelect }, index) => (
                <Col key={index} sm={6} md={4}>
                  <div
                    onClick={() => facingDataHandler(index)}
                    className="feature cursor-pointer d-flex align-items-center"
                  >
                    <span>
                      <img
                        src={isSelect ? selectIcon : unSelectIcon}
                        alt={isSelect ? "check-icon" : "uncheck-icon"}
                      />
                    </span>
                    <p className="feature-text mb-0 ms-2">{title}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </FeatureDetailsStyle>
  );
};

export default FeatureDetails;

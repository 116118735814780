import { ChangeEvent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { fontFamilyMedium, TetraHeading } from "../../components/GlobalStyle";
import FormControl from "../../components/FormControl";
import { useFormikContext } from "formik";
import UnselectedIcon from "../../assets/icons/ic_add_property_type.svg";
import selectedIcon from "../../assets/icons/ic_add_property_type_sel.svg";
import PurchaseBtns from "../../components/PurchaseBtns/PurchaseBtns";
import GeneralInfo from "./GeneralInfo";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../redux/store";
import TextField from "../../components/InputField/TextField";
import { borderErrorToggler } from "./MpacAdditionalDetails";
import { generatePropertyInitTypes } from "./types";
import { Input } from "antd";
import { usePropertyContext } from "../../context/PropertyContext";
import useMediaArea from "../../hooks/useMediaArea";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import AutoCompleteAddress from "../../maps/AutoCompleteAddress";
import { FaCheck } from "react-icons/fa";

const { TextArea } = Input;

//property types

const propertyTypes = [
  {
    title: "Residential",
    selected: true,
  },
  {
    title: "Plot",
    selected: false,
  },
  { title: "Commercial", selected: false },
];

const SellOrRent = () => {
  const [propertyTypesData, setPropertyTypesData] = useState(propertyTypes);
  const navigate = useNavigate();
  const { values, setFieldValue, errors, touched, resetForm } =
    useFormikContext<generatePropertyInitTypes>();
  const {
    data: { propertyAppData },
  } = useAppSelector((state: RootState) => state.appData);
  const { width } = useMediaArea();
  const [isAddressEdit, setIsAddressEdit] = useState(false);

  // sell or rent data
  const {
    setGeneratePropertyType,
    generatePropertyNumber,
    setGeneratePropertyNumber,
    propertyPurpose,
    setPropertyPurpose,
  } = usePropertyContext();

  // sub type property options
  const subTypePropertyOptions = propertyAppData[
    generatePropertyNumber
  ].propertySubTypesDTOS.map(({ displayName }: any) => ({
    value: displayName,
    label: displayName,
  }));

  // property size options
  const propertySizeOptions = propertyAppData[
    generatePropertyNumber
  ].propertySizeDTOS.map(({ displayName }: any) => ({
    value: displayName,
    label: displayName,
  }));

  // property select type
  const propertySelectHandler = (index: number) => {
    const data = [...propertyTypesData];
    const initialSelected = data.find(({ selected }) => selected === true);
    initialSelected!.selected = false;
    data[index].selected = !data[index].selected;
    setPropertyTypesData(data);
  };
;

  return (
    <>
      <div className="heading-bar">
        <h2 className="heading-bar-title">Create your Listing</h2>
        <p className="heading-bar-subtitle">
         Enter your property details below and get your listing active on HouseUp
        </p>
      </div>
      <div className="heading-bar mt-4">
        <h2 className="heading-bar-title">Property Address</h2>
        <div className="d-flex align-items-center gap-2 mb-2 property-address">
          {isAddressEdit ? (
            <div className="d-flex gap-2 align-items-center w-100">
              <AutoCompleteAddress
                placeholder="Search Address..."
                name="address"
                value={values.unparsedAddress}
                onChange={({
                  address,
                  longitude,
                  latitude,
                  city,
                  country,
                  state,
                  postalCode,
                  streetName,
                  streetNumber,
                }) => {
                  setFieldValue("city", city);
                  setFieldValue("country", country);
                  setFieldValue("state", state);
                  setFieldValue("postalCode", postalCode);
                  setFieldValue("streetName", streetName);
                  setFieldValue("streetNumber", streetNumber);
                  setFieldValue("unparsedAddress", address);
                  setFieldValue("longitude", longitude);
                  setFieldValue("latitude", latitude);
                }}
              />
              <FaCheck
                onClick={() => setIsAddressEdit(false)}
                className="fs-4 cursor-pointer"
              />
            </div>
          ) : (
            <div className="d-flex gap-2 align-items-center">
              <p className="heading-bar-subtitle mb-0">
                {values.unparsedAddress}
              </p>
              <FiEdit2
                onClick={() => setIsAddressEdit(true)}
                className=" cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>
      <Row className="property-for align-items-center">
        <Col md={3} className="type">
          <TetraHeading>Property for *</TetraHeading>
          <PurchaseBtns
            onClick={(purpose: string) => {
              setPropertyPurpose(purpose);
              setFieldValue("purpose", purpose);
            }}
          />
        </Col>
        <Col md={6} className="mt-2 mt-sm-0">
          <TextField
            border={borderErrorToggler(errors.title)}
            name="title"
            label="Property Title *"
            placeholder="Property Title"
            padding="9px"
            labelMarginBottom="14px"
            value={values.title}
            onChange={(event) => {
              const { value } = event.target;
              const alphanumericRegex = /^[A-Za-z0-9\s]*$/;

              if (alphanumericRegex.test(value)) {
                setFieldValue("title", value);
              }
            }}
            // onPaste={(event) => {
            //   const pastedText = event.clipboardData.getData('text');
            //   const alphanumericRegex = /^[A-Za-z0-9\s]*$/;
          
            //   if (!alphanumericRegex.test(pastedText)) {
            //     event.preventDefault(); // Prevent pasting invalid content
            //   }
            // }}
          />
        </Col>
        {/* <Col md={3} className="align-self-end generate-btn mt-3 mt-md-0">
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="8px"
            width="100%"
            type="submit"
            title="Use generated title"
            margin="auto"
            fontSize="16px"
          />
        </Col> */}
      </Row>
      <Row className="property-types align-items-center">
        <Col md={6} className="property-types-type align-self-start">
          <h6 className="heading">Type of Property *</h6>
          <div className="types-radio d-flex type gap-3 w-100 mt-3">
            {propertyTypesData.map(({ title, selected }, index) => (
              <div
                onClick={() => {
                  setGeneratePropertyType(title);
                  setGeneratePropertyNumber(index);
                  propertySelectHandler(index);
                  resetForm();
                  setPropertyPurpose("Rent");
                }}
                key={index}
                className="d-flex cursor-pointer"
              >
                <img
                  src={selected ? selectedIcon : UnselectedIcon}
                  alt="emptyBox"
                />
                <p className="property-types-type-text mb-0 ms-3">{title}</p>
              </div>
            ))}
          </div>
        </Col>
        <Col md={6}>
          <h6 className="heading mb-0 mt-3 mt-md-0">Sub-type of Property *</h6>
          <div className="mt-2">
            <FormControl
              control="selects"
              name="propertySubTypeId"
              border={borderErrorToggler(errors.propertySubTypeId)}
              placeholder="Sub-type of Property"
              value={
                propertyAppData[
                  generatePropertyNumber
                ].propertySubTypesDTOS.find(
                  ({ id }: any) => id === values.propertySubTypeId
                )
                  ? propertyAppData[
                      generatePropertyNumber
                    ].propertySubTypesDTOS.find(
                      ({ id }: any) => id === values.propertySubTypeId
                    )?.displayName
                  : ""
              }
              defaultValue="Sub-type of Property"
              options={subTypePropertyOptions}
              onChange={(value: any) => {
                const selectedOption = propertyAppData[
                  generatePropertyNumber
                ].propertySubTypesDTOS.find(
                  ({ displayName }: any) => displayName === value
                );
                if (selectedOption) {
                  setFieldValue("propertySubTypeId", selectedOption.id);
                }
              }}
              showErroMessage={false}
              className={
                errors.propertySubTypeId && touched.propertySubTypeId
                  ? "is-invalid"
                  : "customInput"
              }
            />
          </div>
        </Col>
      </Row>

      <Row className="propery-results align-items-center mt-3">
        <Col md={6}>
          <Row className="align-items-center">
            
          <Col md={8} style={{paddingRight:'5px'}} >
    <TextField
      border={borderErrorToggler(errors.propertySize?.size)}
      name="propertySize.size"
      label="Lot Size *"
      placeholder="Size"
      padding="9px"
      type="text"
      labelMarginBottom="8px"
      value={values.propertySize.size}
      onChange={(event) => {
        const newSize = parseInt(event.target.value);
        if (event.target.value === "" || (!isNaN(newSize) && newSize >= 0)) {
          setFieldValue("propertySize.size", event.target.value);
        }
      }}
      // onPaste={()=>{}}
    />
    {errors.propertySize?.size && (
      <div className="invalid-feedback">{errors.propertySize.size}</div>
    )}
  </Col>
            {/* <Col md={1}></Col> */}
            <Col md={4} className="p-0">
              <div
                style={{
                  marginTop: width > 786 ? "32px" : "0px",
                  width: "96%",
                }}
              >
                <FormControl
                  control="selects"
                  type="text"
                  name="propertySize.keyName"
                  border={borderErrorToggler(errors.propertySize?.keyName)}
                  placeholder="Property Size Type"
                  onChange={(value: any) => {
                    const selectedOption = propertyAppData[
                      generatePropertyNumber
                    ].propertySizeDTOS.find(
                      ({ displayName }: any) => displayName === value
                    );
                    if (selectedOption) {
                      setFieldValue(
                        "propertySize.keyName",
                        selectedOption.keyName
                      );
                    }
                  }}
                  value={
                    propertyAppData[
                      generatePropertyNumber
                    ].propertySizeDTOS.find(
                      ({ keyName }: any) =>
                        keyName === values.propertySize.keyName
                    )
                      ? propertyAppData[
                          generatePropertyNumber
                        ].propertySizeDTOS.find(
                          ({ keyName }: any) =>
                            keyName === values.propertySize.keyName
                        )?.displayName
                      : ""
                  }
                  showErroMessage={false}
                  className={
                    errors.propertySize?.keyName &&
                    touched.propertySize?.keyName
                      ? "is-invalid"
                      : "customInput"
                  }
                  options={propertySizeOptions}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="mt-2 mt-sm-0">
          <TextField
            border={borderErrorToggler(errors.price)}
            name="price"
            label="Property Price *"
            placeholder="Property Price"
            padding="8px"
            // type="number"
            labelMarginBottom="8px"
            value={values.price || ''}
            onChange={(event) =>{
              const rawValue = event.target.value;
              // Remove any non-numeric characters
              const numericValue = rawValue.replace(/\D/g, '');
              // Convert to a number
              const numericPrice = parseFloat(numericValue);
              // Format with thousand separators
              const formattedPrice = isNaN(numericPrice) ? '' : numericPrice.toLocaleString();
              // Update the form field
              setFieldValue("price", formattedPrice)}}
              // onPaste={()=>{}}
            suffix={
              <span
                  style={{
                      fontSize: '20px',
                  }}
              >
                 $
              </span>
          }
          
          />
        </Col>
      </Row>
      <label
        htmlFor="description"
        className="my-2"
        style={{
          fontFamily: fontFamilyMedium,
        }}
      >
        Description *
      </label>
      <TextArea
        rows={4}
        placeholder="Description"
        value={values.description ? values.description : ""}
        onChange={(event) => setFieldValue("description", event.target.value)}
      />
      {errors.description && (
        <p className="text-danger mt-2">Description is required</p>
      )}
      {propertyAppData[generatePropertyNumber].propertyGeneralInfoDTOS
        .length !== 0 && <GeneralInfo />}
    </>
  );
};

export default SellOrRent;

import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Drawer } from "antd";
import { singlePropertyTypes } from "../../utils/types";
import Property from "../../components/Property/Property";
import { PropertyDrawerStyle } from "./style";
import { PropertyDetailTypes } from "../Property/types";
import PropertySkeleton from "../../components/Skeletons/PropertySkeleton";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";

type propertyDrawer = {
  isShowProperty: boolean;
  setIsShowProperty: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  error: string;
  propertyData: PropertyDetailTypes;
};
const PropertyDrawer: React.FC<propertyDrawer> = ({
  isShowProperty,
  setIsShowProperty,
  propertyData,
  loading,
  error,
}) => {
  console.log({ propertyData });

  const showDrawer = () => {
    setIsShowProperty(true);
  };

  const onClose = () => {
    setIsShowProperty(false);
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    height: 450,
    padding: 48,
    overflow: "hidden",
    textAlign: "center",
    background: "transparent",
    // border: `1px solid ${token.colorBorderSecondary}`,
    // borderRadius: token.borderRadiusLG,
  };

  const {
    ownerData: { firstName = "", lastName = "", id = 0, userName = "" } = {},
    propertyData: {
      imageURLS = [],
      title = "",
      purpose = "",
      price = "",
      location = "",
      propertyGeneralInfo: {
        others: { fullBathrooms = "", fullBedrooms = "" } = {
          fullBathrooms: "",
          fullBedrooms: "",
        },
      } = {},
      propertySize: { size = "", keyName = "" } = {
        keyName: "",
        size: "",
      },
      latitude = 0,
      longitude = 0,
      propertyStatusId = 0,
      propertySubTypeId = 0,
      propertyTypeId = 0,
      videoULRS = "",
      propertyId = 0,
    } = {},
  } = propertyData;

  // const { ownerData = {
  //   firstName = '',
  //   lastName = '',
  //   id = '',
  //   userName = '',
  // }, propertyData: selectPropertyData = {} } = propertyData;

  // const {
  //   imageURLS = [],
  //   title = "",
  //   purpose = "",
  //   price = "",
  //   location = "",
  //   propertyGeneralInfo: {
  //     others: { fullBathrooms, fullBedrooms } = {
  //       fullBathrooms: "",
  //       fullBedrooms: "",
  //     },
  //   },
  //   propertySize: { size, keyName } = {
  //     keyName: "",
  //     size: "",
  //   },
  //   latitude = 0,
  //   longitude = 0,
  //   propertyStatusId = 0,
  //   propertySubTypeId = 0,
  //   propertyTypeId = 0,
  //   videoULRS = "",
  //   propertyId = 0,
  // } = propertyData.propertyData;
  // const { firstName, lastName, id, userName } = ownerData;

  const selectedPropertyData = {
    propertyId,
    imageURLS,
    title,
    location,
    purpose,
    price,
    fullBathrooms: fullBathrooms.toString(),
    fullBedrooms: fullBedrooms.toString(),
    propertySize: { keyName, size },
    latitude,
    longitude,
    ownerData: {
      emailAddress: "",
      firstName,
      id,
      lastName,
      phoneNumber: "",
      profilePicture: "",
      userName,
    },
    propertyStatusId,
    propertySubTypeId,
    propertyTypeId,
    videoULRS,
  };
  return (
    <PropertyDrawerStyle style={containerStyle}>
      {isShowProperty && (
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          open={isShowProperty}
          getContainer={false}
        >
          {loading ? (
            <div className="d-flex justify-content-end w-100">
              <PropertySkeleton />
            </div>
          ) : error ? (
            <NoDataAvailable title={error} />
          ) : (
            <Property {...selectedPropertyData} />
          )}
        </Drawer>
      )}
    </PropertyDrawerStyle>
  );
};

export default PropertyDrawer;

import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { SendRequest } from "../components/Requests/Sent";

type bookingContextTypes = {
  bookingId: number;
  setBookingId: Dispatch<SetStateAction<number>>;
  shortNote: string;
  setShortNote: Dispatch<SetStateAction<string>>;
  rejectionReasonId: number;
  setRejectionReasonId: Dispatch<SetStateAction<number>>;
  selectedBooking: selectedBookingTypes;
  setSelectedBooking: Dispatch<SetStateAction<selectedBookingTypes>>;
};

const BookingContext = createContext({} as bookingContextTypes);
type selectedBookingTypes = {
  newsFeedId: number;
  visitType: string;
  description?: string;
  visitDate?: string;
  visitStartTime?: string;
  visitEndTime?: string;
  bookingId: number;
  requestStatusId: number;
};

const BookingProvider = ({ children }: { children: React.ReactNode }) => {
  const [bookingId, setBookingId] = useState(0);
  const [shortNote, setShortNote] = useState("");
  const [rejectionReasonId, setRejectionReasonId] = useState(0);
  const [selectedBooking, setSelectedBooking] = useState(
    {} as selectedBookingTypes
  );

  return (
    <BookingContext.Provider
      value={{
        bookingId,
        setBookingId,
        rejectionReasonId,
        setRejectionReasonId,
        shortNote,
        setShortNote,
        setSelectedBooking,
        selectedBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

// make sure use
const useBookingContext = () => {
  return useContext(BookingContext);
};

export { useBookingContext, BookingProvider };

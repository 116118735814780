import { Card, Col, Row } from "react-bootstrap";
import { ResourcesListStyled } from "./style";
import IconWithText from "../../../components/IconText/Index";
import user from "../../../assets/icons/ic_blog_user.svg";
import calender from "../../../assets/icons/ic_blog_calender.svg";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { get_blogs_url, media_base_url } from "../../../utils/api_urls";
import Loader from "../../../components/Loader/Loader";
import NoDataFound from "../../../components/NoData/NoDataFound";

type resoucesListTypes = {
  sm: number;
  md: number;
  lg: number;
};

type blogsDataPromise = {
  loading: boolean;
  data: blogs;
  error: string;
};

const ResourcesList: React.FC<resoucesListTypes> = ({ md, sm, lg }) => {
  const navigate = useNavigate();
  const { loading, data, error }: blogsDataPromise = useFetch(get_blogs_url);

  return (
    <ResourcesListStyled>
      {loading ? (
        <Loader />
      ) : error ? (
        <NoDataFound />
      ) : (
        <Row>
          {data.blogs.map((blog) => {
            const { imageUrl, id, title } = blog;
            return (
              <Col key={id} sm={sm} md={md} lg={lg}>
                <Card
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/resources/${id}`, {
                      state: blog,
                    })
                  }
                >
                  <Card.Img
                    variant="top"
                    src={media_base_url + imageUrl}
                    alt={imageUrl}
                  />
                  <Card.Body>
                    <Card.Title className="mb-0">{title}</Card.Title>
                    {/* <div className="d-flex justify-content-between">
                  <IconWithText
                    icon={calender}
                    title="August 20, 2021"
                    marginTop="10px"
                  />
                  <IconWithText
                    icon={user}
                    title="John Smith"
                    marginTop="10px"
                  />
                </div> */}
                    {/* <Card.Text className="mt-1">
                      I neglect my talents Far far away, behind the word
                      mountains, far from the countries Vokalia and Consonantia,
                      there live the blind texts. Separated they live in
                      Bookmarksgrove right at the coast of the Semantics, a
                      large language ocean. A small river named Duden flows
                      by...
                    </Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </ResourcesListStyled>
  );
};

export default ResourcesList;

export interface blogs {
  totalItems: number;
  blogs: Blog[];
  totalPages: number;
  currentPage: number;
}

export interface Blog {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  questionAnswerDTOList: QuestionAnswerDtolist[];
}

export interface QuestionAnswerDtolist {
  question: string;
  answer: string;
}

import React, { useState, useRef, useEffect } from "react";

interface VideoInViewProps {
  videoUrl: string;
  isMuted: boolean;
  onVolumeChange: (muted: boolean) => void;
}

const VideoInView: React.FC<VideoInViewProps> = ({
  videoUrl,
  isMuted,
  onVolumeChange,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isInView, setIsInView] = useState(false);

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      setIsInView(true);
      if (videoRef.current) {
        videoRef.current.play();
      }
    } else {
      setIsInView(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  const handleVolumeChange = () => {
    if (videoRef.current) {
      if (videoRef.current) {
        onVolumeChange(videoRef.current.muted);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", handleVolumeChange);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "volumechange",
          handleVolumeChange
        );
      }
    };
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        controls
        width="100%"
        height={300}
        muted={isMuted}
        autoPlay
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoInView;

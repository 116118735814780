import axios from "axios";
import { useState } from "react";
import { authorizationToken, tour_guide_url } from "../utils/api_urls";
import { tourGuide } from "../context/types";
import { loginDataTypes } from "../redux/features/types";
import { useGlobalContext } from "../context/context";
import { useAppDispatch } from "../app/hooks";
import { setLoginData } from "../redux/features/user/getLoginDataSlice";

const useTourGuides = () => {
  const [tourLoading, setTourLoading] = useState(false);
  const { setProfessionalTourGuideData, setUserTourGuideData } =
    useGlobalContext();
  const dispatch = useAppDispatch();

  // tour guid promise
  const getTourGuidePromise = async (
    tourType: string,
    loginData: loginDataTypes
  ) => {
    try {
      setTourLoading(true);
      const { data } = await axios.post(
        tour_guide_url,
        {
          tourType,
        },
        {
          headers: {
            ...authorizationToken(loginData),
          },
        }
      );
      if (data) {
        setTourLoading(false);
      }
      console.log({ data });
      return data.results;
    } catch (error) {
      setTourLoading(false);

      console.log({ error });
    }
  };

  const getTourGuidesData = async (loginData: loginDataTypes) => {
    const professionalData = await getTourGuidePromise(
      "PROFESSIONAL",
      loginData
    );
    const propertyData = await getTourGuidePromise("PROPERTY", loginData);
    const postData = await getTourGuidePromise("POST", loginData);
    const storyData = await getTourGuidePromise("STORY", loginData);
    const professionalTourData = professionalData.map(
      (professional: tourGuide, index: number) => {
        if (index === 0) {
          return { ...professional, isOpen: true };
        } else {
          return { ...professional, isOpen: false };
        }
      }
    );
    setProfessionalTourGuideData([
      {
        ...professionalData[1],
        isOpen: true,
      },
      ...postData.map((post: tourGuide, index: number) => {
        return { ...post, isOpen: false };
      }),
      ...storyData.map((story: tourGuide, index: number) => {
        return { ...story, isOpen: false };
      }),
    ]);
    setUserTourGuideData(
      [
        ...professionalTourData,
        ...propertyData.map((property: tourGuide, index: number) => {
          return { ...property, isOpen: false };
        }),
      ].filter((_: tourGuide, index: number) => index !== 1)
    );
    dispatch(setLoginData(loginData));
  };
  return {
    getTourGuidesData,
    tourLoading,
  };
};

export default useTourGuides;

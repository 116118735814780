import styled from "styled-components";
import moreOptionIcon from "../../assets/icons/ic_newsfeed_more.svg";

type viewmoreIconProps = {
  onClick: () => void;
};
const ViewmoreIcon: React.FC<viewmoreIconProps> = ({ onClick }) => {
  return (
    <Wrapper>
      <div className="clickable-area cursor-pointer" onClick={(onClick)}>
        <span className="three-dots-icon">
          <img
            className="view-more-icon  align-self-start"
            src={moreOptionIcon}
            alt="newFeed"
          />
        </span>
      </div>
    </Wrapper>
  );
};

export default ViewmoreIcon;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  /* Set the desired size for the clickable area */
  width: 20px;
  height: 30px;

  .clickable-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* Add any styling you want for the clickable area */
  }

  /* Style your three dots icon */
  .three-dots-icon {
    height: 20px; /* Set the desired icon size */
  }
`;

import { Field, ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
import { Input } from "antd";
import CustomInputStyle from "./style";
import { useGlobalContext } from "../../context/context";

type customInputProps = {
  label: string;
  prefix: string;
  defaultValue: string;
  maxLength: string;
  disabled: string;
  placeholder: string;
  className: string;
  name: string;
  onFocus: boolean;
  fontFamily: string;
  bgColor: string;
  border: string;
  padding: string;
  showErrorMessage: string;
  suffix: null | React.ReactNode;
  readyOnly: boolean;
  labelMarginBottom: string;
  labelFamily: string;
};

const CustomInput: React.FC<customInputProps> = ({
  label,
  prefix,
  defaultValue,
  maxLength,
  disabled,
  placeholder,
  className,
  name,
  onFocus = false,
  value,
  formik,
  fontFamily = "EnnVisions",
  bgColor = "white",
  border = "1px solid #d9d9d9",
  padding = "8px 8px",
  showErrorMessage = true,
  suffix = null,
  readOnly = false,
  labelMarginBottom = "10px",
  labelFamily = "EnnVisions",
  ...rest
}: any) => {
  return (
    <CustomInputStyle
      fontFamily={fontFamily}
      bgColor={bgColor}
      border={border}
      padding={padding}
      labelMarginBottom={labelMarginBottom}
      labelFamily={labelFamily}
    >
      <label htmlFor={name}>{label}</label>
      <Field name={name} id={name}>
        {({ field }: any) => (
          <Input
            disabled={disabled}
            prefix={prefix}
            className={className}
            type="text"
            readOnly={readOnly}
            maxLength={maxLength}
            // onFocus={() => setIsShowProperty((prev) => !prev)}
            // {...rest}
            placeholder={placeholder}
            defaultValue={defaultValue}
            {...field}
            // value={value}
            // onChange={(e) => console.log(e)}
            suffix={suffix}
          />
        )}
      </Field>
      {showErrorMessage && <ErrorMessage name={name} component={ErrorMsg} />}
    </CustomInputStyle>
  );
};

export default CustomInput;

import { useRef, useState } from "react";
import { EditPostStyle } from "./style";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { primaryColor } from "../../../components/GlobalStyle";
import { Col, Container, Row } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../../components/Head/Head";
import { Form } from "antd";
import galleryIcon from "../../../assets/icons/ic_post_image_select.svg";
import CustomizedFileInput from "../../../components/CustomFileInput/CustomizedFileInput";
import placeholder from "../../../assets/icons/ic_res_placeholder.svg";
import {
  authorizationToken,
  media_base_url,
  post_details_url,
  update_post_url,
} from "../../../utils/api_urls";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import AliceCarousel from "react-alice-carousel";
import { toast } from "react-toastify";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionModal from "../../../components/CustomModals/EnnvisionModal";
import { usePostContext } from "../../../context/PostContext";
import check from "../../../assets/icons/ic_post_enable.svg";
import unCheck from "../../../assets/icons/ic_post_disable.svg";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import useFetchById from "../../../hooks/useFetchById";
import closeIcon from "../../../assets/icons/ic_remove_selected.svg";
import NextBtn from "../../../components/Custom/NextBtn";
import PrevBtn from "../../../components/Custom/PrevBtn";

type editPostInitialTypes = {
  // tagPeoples: string;
  // media_item: any;
  description: string;
  address: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
  isVideoEdited: boolean;
  isImageEdited: boolean;
  videosURLS: any;
  imagesURLS: any[];
};
type editPostDataTypes = {
  data: postDetailTypes;
  error: string;
  loading: boolean;
};

const EditPost = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const [postItems, setPostItems] = useState<any>([]);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const carousel = useRef<AliceCarousel>(null);
  const navigate = useNavigate();
  const { createPostFiles, setCreatePostFiles } = usePostContext();
  const { id } = useParams();
  const [slideIndex, setSlideIndex] = useState(0);
  const {
    loading,
    data: postData,
    error,
  }: editPostDataTypes = useFetchById(post_details_url, id);
  console.log({ postData, error, loading });

  const {
    description = "",
    address = "",
    contactRequestPermission = false,
    boostPermission = false,
    saveFavourite = false,
    sharingEnabled = false,
    turnOffCommenting = false,
    readComments = false,
    hideLikesAndViewsCounts = false,
    videosURLS = "",
    imagesURLS = [],
    postId = 0,
    newsFeedId = 0,
  } = postData;

  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );

  const FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/quicktime",
    "video/x-ms-wmv",
  ];

  const validationSchema = Yup.object({
    description: Yup.string().required("description is required"),
    address: Yup.string().required("address is required"),
    contactRequestPermission: Yup.boolean(),
    boostPermission: Yup.boolean(),
    saveFavourite: Yup.boolean(),
    sharingEnabled: Yup.boolean(),
    turnOffCommenting: Yup.boolean(),
    readComments: Yup.boolean(),
    hideLikesAndViewsCounts: Yup.boolean(),
  });

  const handleSubmit = async (values: editPostInitialTypes) => {
    const formData = new FormData();
    const imageFiles: any = [];
    const images = [];
    let video = "";
    let videoFile = "";

    console.log(values.videosURLS, "vidoe urls ");
    // checking where image is file or string
    if (values.imagesURLS.length > 0) {
      for (let i = 0; i < values.imagesURLS.length; i++) {
        if (typeof values.imagesURLS[i] === "string") {
          console.log("string i is", i);
          images.push(values.imagesURLS[i]);
        } else {
          imageFiles.push(values.imagesURLS[i]);
          console.log("string i isa", i);
        }
      }
    }
    // checking where video is file or string
    if (values.videosURLS) {
      if (typeof values.videosURLS === "string") {
        video = values.videosURLS;
      } else {
        videoFile = values.videosURLS;
      }
    }

    // appending images in formdata
    if (imageFiles.length > 0) {
      values.isImageEdited = true;
      imageFiles.forEach((file: any) => {
        formData.append("images", file);
      });
      values.imagesURLS = images;
    } else {
      values.isImageEdited = false;
      values.imagesURLS = images;
    }

    // appending video file

    if (videoFile) {
      formData.append("video", videoFile);
      values.videosURLS = "";
      values.isVideoEdited = true;
    } else {
      values.isVideoEdited = false;
      values.videosURLS = video;
    }

    console.log({ images, video, videoFile, imageFiles }, "date in onsubmit");
    console.log(values.imagesURLS, images, imageFiles, "video urls in values");

    const userLocation = {
      city: locationData?.city || "Toronto",
      state: locationData?.state || "Ontario",
      country: locationData?.country || "Canada",
      latitude: locationData?.latitude || 43.7878817018061,
      longitude: locationData?.longitude || -79.4973328386334,
    };
    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...values,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            postId,
            newsFeedId,
            ...userLocation,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(update_post_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);

      console.log({ data });
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setIsCreatingPost(false);
    }
  };

  const responsive = {
    0: { items: 1 },
  };

  // do task
  const doTask = () => {
    navigate("/explore-nearby");
  };

  if (loading) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;
  console.log({ imagesURLS, videosURLS }, "images url");

  let initialValues: editPostInitialTypes = {
    // tagPeoples: "",
    description,
    address,
    contactRequestPermission,
    boostPermission,
    saveFavourite,
    sharingEnabled,
    turnOffCommenting,
    readComments,
    hideLikesAndViewsCounts,
    videosURLS: videosURLS,
    imagesURLS: imagesURLS.filter((image) => image !== ""),
    isImageEdited: false,
    isVideoEdited: false,
  };

  console.log({ imagesURLS, videosURLS });
  console.log({ postData, initialValues });

  return (
    <EditPostStyle>
      <Head title="create-post" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Post Updated"
          description="Post Updated Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          console.log(formik.values, "formik values", formik.errors);
          return (
            <Container>
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <div className="inner-section d-flex justify-content-center align-items-center">
                  <Row>
                    <Col sm={8}>
                      <div className="position-relative">
                        {!formik.values.imagesURLS.length &&
                        !formik.values.videosURLS ? (
                          <div>
                            <img
                              src={placeholder}
                              alt="placeholder"
                              className="media-post-item rounded"
                            />
                            <p className="text-danger mt-2">File is Required</p>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <AliceCarousel
                              mouseTracking
                              items={
                                !loading && formik.values.imagesURLS.length > 0
                                  ? formik.values.imagesURLS.map(
                                      (img, index: number) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              backgroundImage: `url(${
                                                typeof img === "string"
                                                  ? media_base_url + img
                                                  : URL.createObjectURL(img)
                                              })`,
                                              backgroundRepeat: "no-repeat",
                                              height: "82vh",
                                              backgroundSize: "100%",
                                              backgroundPosition: "center",
                                              // if cropped is 1/1 then 2/1
                                              // if cropped is 16 /9 then 1/1
                                              // if cropped is 4/2 then 2/1
                                              // aspectRatio: "16/9",
                                            }}
                                          />
                                        );
                                      }
                                    )
                                  : videosURLS
                                  ? [
                                      <video
                                        src={
                                          typeof formik.values.videosURLS ===
                                          "string"
                                            ? media_base_url +
                                              formik.values.videosURLS
                                            : URL.createObjectURL(
                                                formik.values.videosURLS
                                              )
                                        }
                                        className="media-post-item rounded"
                                        controls
                                      />,
                                    ]
                                  : []
                              }
                              disableButtonsControls
                              ref={carousel}
                              activeIndex={slideIndex}
                              disableDotsControls
                              responsive={responsive}
                            />
                            <div
                              className="d-flex align-items-center gap-2 position-absolute mini-imgs-section"
                              style={{
                                maxWidth: "700px",
                                bottom: "12%",
                                left: "10%",
                                overflowX: "auto",
                              }}
                            >
                              {formik.values.imagesURLS.map((item, index) => {
                                return (
                                  <div
                                    className="mini-imgs-section-image"
                                    key={index}
                                  >
                                    <img
                                      src={
                                        typeof item === "string"
                                          ? media_base_url + item
                                          : URL.createObjectURL(item)
                                      }
                                      onClick={() => setSlideIndex(index)}
                                      alt={"image-" + index}
                                      className="cursor-pointer"
                                      style={{
                                        width: "120px",
                                        height: "94px",
                                        border:
                                          slideIndex === index
                                            ? `2px solid ${primaryColor}`
                                            : "none",
                                      }}
                                    />
                                    <img
                                      src={closeIcon}
                                      alt="close"
                                      className="close-icon cursor-pointer"
                                      onClick={() => {
                                        console.log({ index });
                                        formik.setFieldValue(
                                          "imagesURLS",
                                          formik.values.imagesURLS.filter(
                                            (_, itemIndex) =>
                                              itemIndex !== index
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>

                            {formik.values.imagesURLS.length > 1 && (
                              <div key="btns" className="b-refs-buttons">
                                {slideIndex > 0 && (
                                  <PrevBtn
                                    clicked={() =>
                                      setSlideIndex(slideIndex - 1)
                                    }
                                  />
                                )}
                                {slideIndex <
                                  formik.values.imagesURLS.length - 1 && (
                                  <NextBtn
                                    clicked={() =>
                                      setSlideIndex(slideIndex + 1)
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        <div className="position-absolute cursor-pointer media-post">
                          <CustomizedFileInput>
                            <img
                              src={galleryIcon}
                              alt="galleryIcon"
                              className="gallery-icon upload-wrapper-item"
                            />
                            <input
                              type="file"
                              multiple={
                                postData.imagesURLS.length > 0 ? true : false
                              }
                              accept={
                                postData.imagesURLS.length > 0
                                  ? "image/*"
                                  : "video/*"
                              }
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                let files = event.target.files;
                                if (files) {
                                  if (postData.imagesURLS.length > 0) {
                                    formik.setFieldValue("imagesURLS", [
                                      ...formik.values.imagesURLS,
                                      ...Array.from(files),
                                    ]);
                                  } else {
                                    formik.setFieldValue(
                                      "videosURLS",
                                      files[0]
                                    );
                                  }
                                }
                              }}
                            />
                          </CustomizedFileInput>
                        </div>
                      </div>
                    </Col>
                    <Col
                      sm={4}
                      className="mt-3 mt-md-0 right-section"
                      style={{
                        overflowY: "auto",
                        height: "82vh",
                      }}
                    >
                      <FormControl
                        control="textarea"
                        height="100px"
                        type="text"
                        name="description"
                        border="1px solid #EFEFF4"
                        placeholder="Write a Caption"
                        className={
                          formik.errors.description &&
                          formik.touched.description
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                      <div className="tag-section">
                        <div className="add-location mt-2">
                          <p className="mini-title mb-1 border-0">
                            Add Location
                          </p>
                          <FormControl
                            control="input"
                            type="text"
                            name="address"
                            placeholder="Enter Address"
                            className={
                              formik.errors.address && formik.touched.address
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                        </div>
                      </div>
                      <div className="request-section mt-2 pb-2">
                        <div className="d-flex justify-content-between btm-border">
                          <p className="text mini-title mb-2">
                            Contact Request
                          </p>
                          <span>
                            <img
                              src={
                                formik.values.contactRequestPermission
                                  ? check
                                  : unCheck
                              }
                              alt={`${
                                formik.values.contactRequestPermission
                                  ? check
                                  : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "contactRequestPermission",
                                  !formik.values.contactRequestPermission
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>

                        <div className="d-flex justify-content-between post-request mt-2">
                          <p className="text mini-title mb-0">Post Boost</p>
                          <span>
                            <img
                              src={
                                formik.values.boostPermission ? check : unCheck
                              }
                              alt={`${
                                formik.values.boostPermission ? check : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "boostPermission",
                                  !formik.values.boostPermission
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                      </div>
                      <p className="advanced-title mt-2 mb-1">
                        Advanced settings
                      </p>
                      <div className="advanced-settings ">
                        <div className="d-flex justify-content-between">
                          <p className=" mini-title mb-0">
                            Hide like and view counts in this post
                          </p>

                          <span>
                            <img
                              src={
                                formik.values.hideLikesAndViewsCounts
                                  ? check
                                  : unCheck
                              }
                              alt={`${
                                formik.values.hideLikesAndViewsCounts
                                  ? check
                                  : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "hideLikesAndViewsCounts",
                                  !formik.values.hideLikesAndViewsCounts
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                        <p className="sub-title">
                          Only you will see the total number of likes and views
                          on this post. You can change this later by going to
                          the menu at the top of the post.
                        </p>
                        <div className="d-flex justify-content-between mt-2">
                          <p className=" mini-title mb-0">
                            Turn Off Commenting
                          </p>

                          <span>
                            <img
                              src={
                                formik.values.turnOffCommenting
                                  ? check
                                  : unCheck
                              }
                              alt={`${
                                formik.values.turnOffCommenting
                                  ? check
                                  : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "turnOffCommenting",
                                  !formik.values.turnOffCommenting
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                        <p className="sub-title">
                          You can change this later by going to the menu at the
                          top of your post.
                        </p>
                        <div className="d-flex justify-content-between mt-2">
                          <p className=" mini-title mb-0">Enable Sharing</p>

                          <span>
                            <img
                              src={
                                formik.values.sharingEnabled ? check : unCheck
                              }
                              alt={`${
                                formik.values.sharingEnabled ? check : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "sharingEnabled",
                                  !formik.values.sharingEnabled
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                        <p className="sub-title">
                          This post can be shared on other media and its link
                          can be copied for sharing on other social media
                          platforms. You can modify this later in the post's
                          menu.
                        </p>
                        <div className="d-flex justify-content-between mt-2">
                          <p className=" mini-title mb-0">Read Comments</p>

                          <span>
                            <img
                              src={formik.values.readComments ? check : unCheck}
                              alt={`${
                                formik.values.readComments ? check : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "readComments",
                                  !formik.values.readComments
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                        <p className="sub-title">
                          This post's comment and view count is only visible to
                          you. You can change this later in the post's menu.
                        </p>
                        <div className="d-flex justify-content-between mt-2">
                          <p className=" mini-title mb-0">Save Favourite</p>
                          <span>
                            <img
                              src={
                                formik.values.saveFavourite ? check : unCheck
                              }
                              alt={`${
                                formik.values.saveFavourite ? check : unCheck
                              }`}
                              onClick={() =>
                                formik.setFieldValue(
                                  "saveFavourite",
                                  !formik.values.saveFavourite
                                )
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </div>
                        <p>
                          You can change this later by going to the menu of top
                          of your post
                        </p>
                        <div className="d-flex justify-content-between">
                          <p className=" mini-title mb-0">Allow Permissions</p>
                        </div>
                        <p>
                          You can change this later by going to the menu of top
                          of your post
                        </p>
                      </div>
                      <div className="my-2">
                        <CustomButton
                          bgcolor={primaryColor}
                          color="white"
                          padding="6px"
                          width="100%"
                          type="submit"
                          title="Post Now"
                          loading={isCreatingPost}
                          margin="auto"
                          fontSize="16px"
                          fontFamily="EnnVisionsMedium"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </EditPostStyle>
  );
};

export default EditPost;

// post detail types

export interface postDetailTypes {
  newsFeedId: number;
  postType: string;
  postId?: number;
  address: string;
  cityName: string;
  professionTypeId: any;
  professionalId: any;
  professionalBusinessName: any;
  professionalBannerImage: any;
  postLayout?: number;
  description: string;
  propertyId?: number;
  propertyPurpose?: string;
  propertyType?: number;
  propertyTitle?: string;
  propertyBathroom: number;
  propertyBedroom: number;
  propertySize?: number;
  propertyUnit?: string;
  propertyOriginalPrice: number;
  propertyDiscountPrice: number;
  propertyCurrencySymbol?: string;
  imagesURLS: string[];
  videosURLS: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  createdDateTime: string;
  updatedDateTime: any;
  isEdited: boolean;
  isLiked: any;
}

import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  primaryColor,
  tertiaryGrey21,
  whiteColor,
} from "../../components/GlobalStyle";

const FindHomeStyle = styled.div`
  padding-top: 20px;
  .ant-select-selector {
    height: 36px !important;
    width: 96% !important;
    font-family: ${fontFamilyRegular};
  }
  .multiple-marker-parent {
    height: 440px;
    z-index: 1;
    .property-drawer {
      right: 0;
      top: 0;
      height: 400px;
      
    }
  }
  .properties {
    margin-top: 20px;
  }
`;

export default FindHomeStyle;

export const PropertyDrawerStyle = styled.div`
  .ant-drawer-body {
    padding: 0px !important;
  }
  .ant-drawer-mask {
    background: transparent;
  }
  .ant-drawer-title {
    font-family: ${fontFamilyMedium};
  }
  .ant-drawer-content-wrapper {
    width: 370px !important;
  }

  .ant-drawer-content-wrapper {
    width: 370px !important;
  }
  .ant-drawer-content {
    background-color: transparent;
  }

  .ant-drawer-body {
    margin-top: 6px;
  }
`;

export const FindHomeFilter = styled.div`
  border: 1px solid ${tertiaryGrey21};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color:#fff;
  .title {
    font-family: ${fontFamilyMedium};
  }
  img {
    height: 8px;
  }
`;
export const SearchCityStyle = styled.div`
  input {
    border: 1px solid rgb(217, 217, 217);
    padding: 9px;
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
  }
  // @media screen and (max-width: 320px) {
  //   width: 100%;
  // }
`;

export const PriceRangeStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  input {
    border: 1px solid ${tertiaryGrey21};
    border-radius: 4px;
    padding: 4px;
  }
  .to-text {
    font-family: ${fontFamilyMedium};
  }
`;
export const AreaFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  input {
    border: 1px solid ${tertiaryGrey21};
    border-radius: 4px;
    padding: 4px;
  }
  .to-text {
    font-family: ${fontFamilyMedium};
  }
`;

export const BathroomFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  button {
    border-radius: 10px;
    border: 1px solid ${primaryColor};
    padding: 4px;
    font-family: ${fontFamilyMedium};
  }
`;

export const BedroomFilterStyle = styled.div`
  background-color: ${whiteColor};
  padding: 20px;
  border: 1px solid ${tertiaryGrey21};
  border-radius: 4px;

  h6 {
    font-family: ${fontFamilyMedium};
  }
  button {
    border-radius: 10px;
    padding: 4px;
    font-family: ${fontFamilyMedium};
  }
`;

// auto complete address

export const AutoCompleteSuggestionsStyle = styled.div`
  label {
    font-family: ${fontFamilyMedium};
  }
  input {
    padding: 9px;
    border: 1px solid #d9d9d9;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
  }
  .autocomplete-suggestions {
    background-color: red; // Set the background color of the suggestions dropdown
    border-radius: 4px; // Set the border radius of the suggestions dropdown
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); // Set the box shadow of the suggestions dropdown
    font-size: 14px; // Set the font size of the suggestions dropdown
    width: 100%; // Set the width
    font-family: ${fontFamilyMedium};
  }
  .autocomplete-item {
    /* Apply your desired styling here */
    background-color: red;
    padding: 8px;
    cursor: pointer;
    /* Add any additional CSS styles to customize the appearance of autocomplete suggestions */
  }
`;

import React, { useState, useRef } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { AutoCompleteSuggestionsStyle } from "./style";

type autoCompleteCityProps = {
  label?: string;
  placeholder: string;
  name: string;
  onCityChange: (city: string) => void;
};

const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];

const autocompleteOptions = {
  componentRestrictions: { country: "ca" },
  types: ["(cities)"],
};

const AutoCompleteCity: React.FC<autoCompleteCityProps> = ({
  label,
  placeholder,
  name,
  onCityChange,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onLoadAutocomplete = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        const city = place.formatted_address.split(",")[0];
        onCityChange(city);
      }
      // Clear the autocomplete suggestions
      autocomplete.setFields([]);
    }
  };

  const handleInputChange = () => {
    const input = inputRef.current;
    if (input) {
      const inputValue = input.value.trim();
      if (inputValue === "") {
        onCityChange("");
      }
    }
  };

  return (
    <AutoCompleteSuggestionsStyle>
      {label && <label htmlFor="label">{label}</label>}
      <Autocomplete
        options={autocompleteOptions}
        onLoad={onLoadAutocomplete}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          ref={inputRef}
          name={name}
          type="text"
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      </Autocomplete>
    </AutoCompleteSuggestionsStyle>
  );
};

export default React.memo(AutoCompleteCity);

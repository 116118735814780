import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { authorizationToken, user_stories_url } from "../../utils/api_urls";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { useAppSelector } from "../../app/hooks";
import { Container } from "react-bootstrap";
import HeadingViewMore from "../../components/HeadingViewMore/HeadingViewMore";
import {
  TertiraryHeadingMini,
  primaryColor,
  whiteColor,
} from "../../components/GlobalStyle";
import SingleStory from "./ViewProfile/SingleStory";
import CustomButton from "../../components/CustomButton/CustomButton";
import StorySlider from "./ViewProfile/StorySlider";
import { useGlobalContext } from "../../context/context";
import { UserStoriesStyle } from "./style";
import { useParams } from "react-router-dom";

const UserStories = () => {
  const [loading, setloading] = useState(true);
  const [userStoriesData, setUserStoriesData] = useState(
    {} as userStoriesTypes
  );
  const [isShowStories, setIsShowStories] = useState(false);
  const [error, setError] = useState("");
  const loginData = useAppSelector((state) => state.loginData.data);
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const { setSlideIndex } = useGlobalContext();
  // user properties promise
  const handleUserStoriesPromise = async () => {
    try {
      setloading(true);
      const {
        data: { results },
      } = await axios.post(
        user_stories_url + `?pageNo=${page}`,
        {
          userId: id,
          statusId: null,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast("got stories successfully", {
        type: "success",
        autoClose: 1000,
      });
      if (page > 0) {
        setUserStoriesData({
          ...userStoriesData,
          storiesList: [...userStoriesData.storiesList, ...results.storiesList],
        });
      } else {
        setUserStoriesData(results);
      }
      setloading(false);
    } catch (error: any) {
      console.log({ error });
      setloading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setError(error.response.data.responseMessage);
    }
  };

  useEffect(() => {
    handleUserStoriesPromise();
  }, [page, id]);

  console.log({ userStoriesData });
  if (loading && page < 1) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;

  return (
    <UserStoriesStyle className="py-4">
      {isShowStories && (
        <StorySlider
          setIsShowStories={setIsShowStories}
          isShowStories={isShowStories}
          userStoriesData={userStoriesData.storiesList}
        />
      )}

      <Container>
        <HeadingViewMore bottomLines={false} title="Stories List" />
        {userStoriesData?.storiesList?.length !== 0 ? (
          <>
            <div className="stories mt-3">
              {userStoriesData.storiesList.map((storyData, index) => (
                <div
                  onClick={() => {
                    setSlideIndex(index);
                    setIsShowStories(true);
                  }}
                  key={storyData.storyId}
                >
                  <SingleStory
                    key={storyData.storyId}
                    storyData={storyData}
                    width="100%"
                    height="400px"
                  />
                </div>
              ))}
            </div>
            {page + 1 !== userStoriesData?.totalPages && (
              <div className="d-flex justify-content-center my-3">
                <CustomButton
                  bgcolor={primaryColor}
                  color={whiteColor}
                  padding="6px"
                  width="150px"
                  type="button"
                  title="Load More"
                  margin="auto"
                  loading={loading}
                  clicked={() => setPage(page + 1)}
                />
              </div>
            )}
          </>
        ) : (
          <TertiraryHeadingMini className="text-center my-2">
            No Stories Available
          </TertiraryHeadingMini>
        )}
      </Container>
    </UserStoriesStyle>
  );
};

export default UserStories;

// user stories types

export interface userStoriesTypes {
  totalItems: number;
  storiesList: StoriesList[];
  totalPages: number;
  currentPage: number;
}

export interface StoriesList {
  storyId: number;
  newsFeedId: number;
  storyText: string;
  imagesURLS: string;
  videosURLS: string;
  longitude: number;
  latitude: number;
  address: string;
  city: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  statusId: number;
  lastLikeAndComment: LastLikeAndComment;
  ownerData: OwnerData;
  createdDateTime: string;
  isFavourite: boolean;
}

export interface LastLikeAndComment {
  isLiked: boolean;
  totalLikes: number;
  lastLikedUserId?: number;
  lastLikedUserFirstName?: string;
  lastLikedUserLastName?: string;
  totalComments: number;
  lastCommentUserId: any;
  lastCommentUserFirstName: any;
  lastCommentUserLastName: any;
  lastCommentText: any;
  lastCommentUserProfilePicture: any;
}

export interface OwnerData {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  isFollower: boolean;
  isFollowing: boolean;
  id: number;
}

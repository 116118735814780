import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import { user_profile_url } from "../../../utils/api_urls";
import UserProfileStyle from "./style";
import Property from "../../../components/Property/Property";
import ProfesseionalProfileBox, {
  professionalProfileTypes,
} from "./ProfileBox";
import HeadingViewMore from "../../../components/HeadingViewMore/HeadingViewMore";
import Stories from "../../Story/Stories";
import Posts from "../../professional/ProfessionalProfile/Posts";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionWarningModal from "../../../components/CustomModals/WarningModal";
import DashboardSection from "../../../components/DashboardSection/DashboardSection";
import { useAppSelector } from "../../../app/hooks";
import ProfileBoxSkeleton from "../../../components/Skeletons/Profile";
import StoriesSkeleton from "../../../components/Skeletons/Stories";
import PropertiesSkeleton from "../../../components/Skeletons/Properties";
import PostsSkeleton from "../../../components/Skeletons/Posts";

function ViewProfessionalProfile() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [professionalProfileData, setProfessionalProfileData] = useState(
    {} as professionalProfileTypes
  );
  const loginData = useAppSelector((state) => state.loginData.data);

  const navigate = useNavigate();
  const [isLoginModal, setIsLoginModal] = useState(false);
  const { id } = useParams();
  // properties promise
  const getPropertiesPromise = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(user_profile_url, {
        userId: id,
      });
      setProfessionalProfileData(data.results);
      setLoading(false);
    } catch (error: any) {
      console.log({ error });
      setError(error.response.data.responseMessage);
      setLoading(false);
      console.log(error.response.data, "error in professional profile");
    }
  };

  useEffect(() => {
    if (id) {
      getPropertiesPromise();
    }
  }, [id]);

  return (
    <UserProfileStyle>
      <CustomModal
        isModalVisible={isLoginModal}
        setIsModalVisible={setIsLoginModal}
        showCloseBtn={false}
        onCancel={() => {}}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsLoginModal}
          isLoading={false}
          title="Log in to HouseUp"
          description="Log in to see posts and story details. connect with Professionals. from friends, and discover other accounts you'll love."
          doTask={() => navigate("/login")}
        />
      </CustomModal>
      <Container>
        {error ? (
          <NoDataAvailable title={error} />
        ) : (
          <div className="mt-3">
            {loginData?.userDetails.id === professionalProfileData.id &&
              professionalProfileData.professionalDetails?.id && (
                <div className="d-flex justify-content-end">
                  <DashboardSection
                    title="Professional Dashboard"
                    subtitle="Manage your professional accounts"
                    onClick={() => navigate(`/profile-details/${id}`)}
                  />
                </div>
              )}
            {loading ? (
              <ProfileBoxSkeleton />
            ) : (
              <ProfesseionalProfileBox {...professionalProfileData} />
            )}
            <div className="mt-3">
              {loading ? (
                <StoriesSkeleton />
              ) : (
                professionalProfileData.storiesNewsFeedList && (
                  <>
                    <HeadingViewMore bottomLines={false} title="Stories List" />
                    <div className="d-flex stories-list">
                      <Stories
                        stories={
                          professionalProfileData.storiesNewsFeedList
                            .storiesList
                        }
                      />
                    </div>
                  </>
                )
              )}
            </div>
            <div className="mt-3">
              {loading ? (
                <PropertiesSkeleton />
              ) : (
                <>
                  {professionalProfileData.propertiesList && (
                    <div className="mt-3">
                      <HeadingViewMore
                        bottomLines={false}
                        title="Property List"
                      />
                      <Row>
                        {professionalProfileData.propertiesList.propertyList.map(
                          (propertyData: any) => (
                            <Col
                              key={propertyData.propertyId}
                              md={6}
                              lg={4}
                              xl={4}
                              xxl={3}
                            >
                              <Property {...propertyData} />
                            </Col>
                          )
                        )}
                      </Row>
                    </div>
                  )}
                </>
              )}
              {loading ? (
                <PostsSkeleton />
              ) : (
                <>
                  {professionalProfileData.postList && (
                    <div className="mt-3">
                      <HeadingViewMore
                        bottomLines={false}
                        title="Post Listing"
                      />
                      <Posts
                        posts={professionalProfileData.postList.postList}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Container>
    </UserProfileStyle>
  );
}

export default ViewProfessionalProfile;

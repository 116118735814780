import Skeleton from "react-loading-skeleton";

const ProfessionalSkeleton = () => {
  return (
    <div
      className="inner-container bg-white rounded"
      style={{
        width: 440,
        height: 380,
      }}
    >
      <Skeleton height={250} />
      <div className="mt-2">
        <Skeleton width="86%" className="mt-2" />
        <Skeleton width="60%" />
      </div>
    </div>
  );
};

export default ProfessionalSkeleton;

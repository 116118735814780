import React, { Dispatch, SetStateAction, useState } from "react";
import { Drawer } from "antd";
import { ProfesionalDrawerStyle } from "./style";
import Professional from "./Professional";
import { ProfessionalStyle } from "../Home/style";
import DrawerProfessional from "./DrawerProfessional";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import ProfessionalSkeleton from "../../components/Skeletons/ProfessionalSkeleton";
import { userProfileTypes } from "../User/types";

type professionalProps = {
  showProfesional: boolean;
  setShowProfesional: Dispatch<SetStateAction<boolean>>;
  professionalData: userProfileTypes;
  loading: boolean;
  error: string;
};
const ProfessionalDrawer: React.FC<professionalProps> = ({
  showProfesional,
  setShowProfesional,
  professionalData,
  loading,
  error,
}) => {
  //   const { token } = theme.useToken();

  const showDrawer = () => {
    setShowProfesional(true);
  };

  const onClose = () => {
    setShowProfesional(false);
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    height: 360,
    padding: 48,
    overflow: "hidden",
    textAlign: "center",
    background: "transparent",
  };

  console.log({ loading }, "loading in drawer");
  return (
    <ProfesionalDrawerStyle style={containerStyle}>
      {showProfesional && (
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          open={showProfesional}
          getContainer={false}
        >
          {loading ? (
            <div className="d-flex justify-content-end w-100">
              <ProfessionalSkeleton />
            </div>
          ) : error ? (
            <NoDataAvailable title={error} />
          ) : (
            <DrawerProfessional {...professionalData} />
          )}
        </Drawer>
      )}
    </ProfesionalDrawerStyle>
  );
};

export default ProfessionalDrawer;

import FindProfessionalStyle from "./style";
import { useEffect, useState } from "react";
import axios from "axios";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import MultipleMarkerMap, { markerDataTypes } from "./MultipleMarkers";
import ProfessionalDrawer from "./ProfessionalDrawer";
import Head from "../../components/Head/Head";
import Loader from "../../components/Loader/Loader";
import {
  get_all_professional,
  get_locations_url,
  user_profile_url,
} from "../../utils/api_urls";
import Professionals from "./Professionals";
import FindProfessionalFilters from "./ProfessionalFilters";
import { useSearchContext } from "../../context/SearchContext";
import ProfessionalsSkeleton from "../../components/Skeletons/Professional";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { userProfileTypes } from "../User/types";
import usePostNoAuthEvent from "../../hooks/usePostEventNoAuth";
import usePostNoAuth from "../../hooks/usePostNoAuth";

export type mapData = {
  id: number;
  newsFeedId: number;
  latitude: number;
  longitude: number;
};
type mapDataTypes = {
  loading: boolean;
  data: mapData[];
  error: string;
};

const FindProfessional = () => {
  const {
    loading: latLngLoading,
    data: mapData,
    error: latLngError,
  }: mapDataTypes = usePostNoAuth(get_locations_url, {
    useCase: "PROFESSION",
    state: "ON",
  });
  const {
    professionalsLoading,
    setProfessionalsLoading,
    professionalError,
    professionalSearchCriteria,
    professionals,
    setProfessionals,
  } = useSearchContext();
  const professionalsMarker =
    professionalsLoading || professionalError
      ? []
      : professionals?.professionalRecords?.map(
          ({ id, userId, longitude, latitude }) => ({
            latitude,
            longitude,
            id,
            userId,
          })
        );

  const [showProfessional, setShowProfessional] = useState(false);
  const [page, setPage] = useState(0);

  // get professional promise
  const {
    loading: getProfessionalLoading,
    data,
    error: getProfessionalError,
    apiDataPromise: getPropertyDetailsPromise,
  } = usePostNoAuthEvent();

  console.log({ mapData });

  // search professional promise
  const allProfessionalsPromsie = async () => {
    try {
      setProfessionalsLoading(true);
      const { data } = await axios(get_all_professional + page, {});
      console.log("propfessional data map",data)
      const totalData = {
        currentPage: data?.results.currentPage,
        professionalRecords: data?.results.professionalRecords,
        totalItems: data?.results.totalItems,
        totalPages: data?.results.totalPages,
      };
      if (page < 1) {
        setProfessionals(totalData);
      } else {
        setProfessionals({
          ...professionals,
          professionalRecords: [
            ...professionals.professionalRecords,
            ...data.results.professionalRecords,
          ],
        });
      }
      console.log(data.results, "all professional....s");
      setProfessionalsLoading(false);
      console.log(data);
    } catch (error: any) {
      // if (error.response.data.responseCode === "500") {
      //   setProfessionalError(error.response.data.responseMessage);
      // }
      setProfessionalsLoading(false);

      console.log({ error });
    }
  };

  useEffect(() => {
    if (professionalSearchCriteria.length < 1) {
      allProfessionalsPromsie();
    }
  }, [page]);

  console.log({ professionalSearchCriteria, data });

  // selected location handler
  const selectedLocationHandler = async (id: number) => {
    setShowProfessional(true);
    await getPropertyDetailsPromise(user_profile_url, {
      userId: id,
    });
  };

  console.log({ latLngLoading, professionalsLoading, getProfessionalLoading });
  return (
    <>
      <Head title="find professional" />
      <FindProfessionalFilters />
      <FindProfessionalStyle className="pb-3">
        <div className=" position-relative multiple-marker-parent" >
          <div className="position-absolute w-100 property-drawer">
            <ProfessionalDrawer
              showProfesional={showProfessional}
              setShowProfesional={setShowProfessional}
              professionalData={data as userProfileTypes}
              loading={getProfessionalLoading}
              error={getProfessionalError}
            />
          </div>
          {professionalsLoading ? (
          <Container>
            <ProfessionalsSkeleton />
          </Container>
        ) : professionalError ? (
          <NoDataAvailable title={professionalError} />
        ) : (
          <Professionals
            {...professionals}
            page={page}
            loading={professionalsLoading}
            setPage={setPage}
          />
        )}
          {/* {latLngLoading ? (
            <Skeleton height={440} />
          ) : latLngError ? (
            <NoDataAvailable />
          ) : (
            <MultipleMarkerMap
              markers={
                latLngLoading
                  ? []
                  : professionalsMarker?.map(
                      ({ id, latitude, longitude, userId }) => ({
                        latitude,
                        longitude,
                        id,
                        userId,
                      })
                    )
              }
              selectedLocationHandler={selectedLocationHandler}
            />
          )} */}
        </div>
       
      </FindProfessionalStyle>
    </>
  );
};

export default FindProfessional;

import { Card } from "react-bootstrap";
import { BackgroundImage } from "../../../components/GlobalStyle";
import { media_base_url } from "../../../utils/api_urls";
import { SingleStoryStyle } from "../style";
import { StoriesList } from "../types";
import { FiMessageCircle } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import { truncateString } from "../../../utils/utilities";

type StoryProps = {
  storyData: StoriesList;
  width: string;
  height: string;
};

const SingleStory: React.FC<StoryProps> = ({ storyData, height = "144px" }) => {
  const { storyText, imagesURLS, videosURLS, storyId } = storyData;

  console.log({ storyText, imagesURLS, videosURLS });
  return (
    <SingleStoryStyle height={height}>
      <div key={storyId}>
        <Card className="story-card d-flex justify-content-center align-items-center">
          <div className="overlay">
            <div className="overlay-inner-container d-flex justify-content-between gap-2">
              <span className="d-flex">
                <p className="me-2 likes">12</p>
                <AiFillHeart color="white" className="fs-2 " />
              </span>
              <span className="d-flex">
                <p className="me-2 comments">12</p>
                <FiMessageCircle color="white" className="fs-2" />
              </span>
            </div>
          </div>
          {storyText && storyText.trim() ? (
            <div className="status-text d-flex align-items-center justify-content-center">
              <h6>{truncateString(storyText, 60)}</h6>
            </div>
          ) : imagesURLS ? (
            <BackgroundImage
              className="card-img-top"
              repeat="no-repeat"
              url={media_base_url + imagesURLS}
              size="cover"
              postion="center"
              height={height}
              width="100px"
            />
          ) : (
            <video id="video1" className="video-section">
              <source src={media_base_url + videosURLS} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          )}
        </Card>
      </div>
    </SingleStoryStyle>
  );
};

export default SingleStory;

import { Container } from "react-bootstrap";
import { SingleResourceStyle } from "./style";
import resource_title_img from "../../assets/images/single_resource_title.png";
import resource_center_img from "../../assets/images/single_resource_center.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Blog } from "../professional/ProfessionalProfile/ResourcesList";
import Loader from "../../components/Loader/Loader";
import { useEffect } from "react";
import { media_base_url } from "../../utils/api_urls";

const SingleResource = () => {
  const navigate = useNavigate();

  const {
    state,
  }: {
    state: Blog;
  } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/resources");
    }
  }, []);

  return (
    <>
      {!state ? (
        <Loader />
      ) : (
        <SingleResourceStyle>
          <div className="hero-box">
            <h6 className="hero-box-title">{state.title}</h6>
          </div>
          <Container className="resource-content">
            <div className="d-flex justify-content-center">
              <img
                className="mt-2"
                src={media_base_url + state.imageUrl}
                alt={state.imageUrl}
              />
            </div>

            <p className="mt-2">{state.description}</p>
            {state.questionAnswerDTOList.map(({ question, answer }, index) => (
              <div key={index} className="mt-2">
                <h6 className="title">{question}</h6>
                <p>{answer}</p>
              </div>
            ))}
          </Container>
        </SingleResourceStyle>
      )}
    </>
  );
};

export default SingleResource;

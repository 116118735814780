import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey3,
  primaryColor,
  pureDark,
  secondaryDark4,
  secondaryDark6,
  whiteColor,
} from "../../components/GlobalStyle";

const ResourcesStyle = styled.div``;
export default ResourcesStyle;

export const SingleResourceStyle = styled.div`
  .resource-content {
    max-width: 900px;
  }
  .hero-box {
    background-color: ${primaryColor};
    height: 376px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-title {
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      color: ${whiteColor};
      font-family: ${fontFamilyMedium};
      width: 70%;
      @media screen and (max-width: 991px) {
        font-size: 30px;
        line-height: 36px;
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 34px;
        width: 90%;
      }
      @media screen and (max-width: 480px) {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
    }
  }
  .title {
    font-size: 18px;
    font-family: ${fontFamilyMedium};
    line-height: 28px;
    color: ${secondaryDark6};
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }
`;

export const RecentResoucesStyle = styled.div`
  .resouce-box {
    border: 1px solid #e9e9e9;
    padding: 8px;
    margin-top: 14px;
    border-radius: 5px;
    .left-img {
      width: 76px;
      height: 76px;
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      object-fit: cover;
    }
    .heading {
      font-size: 16px;
      font-family: "EnnVisionsMedium";
      margin-bottom: 0;
    }
  }
`;
export const TextWidgetStyled = styled.section`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 10px;
  margin-top: 14px;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const ResourcesTagsStyle = styled.section`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ResourcesCatagoryStyle = styled.div`
  margin-top: 15px;
  .ant-collapse {
    background-color: unset;
    border: none;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    margin-top: 10px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    border: 1px solid #d9d9d9;
    border-top: none;
  }
  .ant-collapse-header {
    border: 1px solid #d9d9d9;
  }
  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 6px 16px;
    padding-right: 40px;
  }
`;
export const ShowCaseStyle = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  .large-img {
    object-fit: cover;
  }
  .bottom-section {
    padding: 16px;
    .main-heading {
      font-size: 26px;
      font-family: "EnnVisionsMedium";
    }
    .details {
      color: ${secondaryDark4};
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
`;

export const HeroSectionStyle = styled.div`
  background: ${primaryColor};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 82vh;
  width: 100%;
  .footer {
    bottom: 10%;
  }
  .inner-container {
    height: 80vh;
    &-main-heading {
      font-size: 52px;
      color: ${whiteColor};
      @media screen and (max-width: 991px) {
        font-size: 40px;
      }
      @media screen and (max-width: 786px) {
        text-align: center;
        font-size: 32px;
      }

      margin: 0;
      .professional-text {
        font-family: "EnnVisionsMedium";
      }
    }

    .location-select-section {
      max-width: 1000px;
      margin-top: 45px;
      background-color: ${whiteColor};
      border-radius: 7px;
      width: 95%;
      .title {
        color: ${pureDark};
        font-size: 16px;
        font-family: "EnnVisionsMedium";
      }
      .sub-title {
        color: ${secondaryDark4};
        font-size: 14px;
      }
      .property-type {
        border-left: 1px solid ${lightGrey3};
        height: 70%;
        .search-box {
          background-color: ${primaryColor};
          border-radius: 4px;
          padding: 12px;

          img {
            vertical-align: super;
          }
        }
        @media screen and (max-width: 575px) {
          border-left: none;
          .search-box {
            img {
              height: 20px;
            }
            height: 40px;
          }
        }
      }
    }
  }
`;

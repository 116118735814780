import axios from "axios";
import { Form, Formik } from "formik";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAppSelector } from "../../app/hooks";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import CustomModal from "../../components/Modal/CustomModal";
import {
  PropertyExtraOptionDtos,
  PropertyGeneralInfoDtos,
  PropertyServicesDtos,
  PropertyUniqueOptionDtos,
} from "../../redux/features/types";
import { RootState } from "../../redux/store";
import {
  authorizationToken,
  create_property_url,
  draft_property_url,
} from "../../utils/api_urls";
import PropertyInfo from "./CreatePropertyInfo";
import { generatePropertyInitTypes } from "./types";
import { usePropertyContext } from "../../context/PropertyContext";
import { objectNotEmpty } from "../../utils/utilities";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";

// facing data
export const facingsData = [
  { title: "North", abbre: "N", isSelect: false },
  { title: "North West", abbre: "NW", isSelect: false },
  { title: "West", abbre: "W", isSelect: false },
  { title: "North East", abbre: "NE", isSelect: false },
  { title: "South West", abbre: "SW", isSelect: false },
  { title: "South", abbre: "S", isSelect: false },
  { title: "South East", abbre: "SE", isSelect: false },
  { title: "West", abbre: "W", isSelect: false },
];

// generate property context types
type generatePropertyContextTypes = {
  propertyExtraOptions: PropertyExtraOptionDtos[];
  propertyUniqueOptions: PropertyUniqueOptionDtos[];
  propertyServiceOptions: PropertyServicesDtos[];
  setPropertyExtraOptions: Dispatch<
    React.SetStateAction<PropertyExtraOptionDtos[]>
  >;
  setPropertyUniqueOptions: Dispatch<
    React.SetStateAction<PropertyUniqueOptionDtos[]>
  >;
  setPropertyServiceOptions: Dispatch<
    React.SetStateAction<PropertyServicesDtos[]>
  >;
  propertyGeneralInfo: PropertyGeneralInfoDtos[];
  setPropertyGeneralInfo: Dispatch<SetStateAction<PropertyGeneralInfoDtos[]>>;
  facingData: {
    title: string;
    abbre: string;
    isSelect: boolean;
  }[];
  setFacingData: Dispatch<
    SetStateAction<
      {
        title: string;
        abbre: string;
        isSelect: boolean;
      }[]
    >
  >;
  isLoading: boolean;
};

// generate property context
const GenaratePropertyContext = createContext(
  {} as generatePropertyContextTypes
);

const GenerateProperty = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [propertyGeneralInfo, setPropertyGeneralInfo] = useState<
    PropertyGeneralInfoDtos[]
  >([]);

  const { data: loginData } = useAppSelector((state) => state.loginData);
  const {
    mpacAdditionalDetailsData,
    generatePropertyType,
    generatePropertyNumber,
    propertyPurpose,
    createPropertyData,
    mpacCreatePropertyData,
    propertyLocation,
    propertyStatusId,
    setIsPropertyTypeSelected,
    setPropertyStatusId,
    resetPropertyDataContext,
    setGroundFloorInfoItems,
    setFirstFloorInfoItems,
    setSecondFloorInfoItems,
    setThirdFloorInfoItems,
  } = usePropertyContext();

  if (
    !objectNotEmpty(createPropertyData) &&
    mpacAdditionalDetailsData &&
    !objectNotEmpty(mpacAdditionalDetailsData) &&
    !objectNotEmpty(mpacCreatePropertyData)
  ) {
    navigate("/create-property");
  }
  const [facingData, setFacingData] = useState(facingsData);
  const {
    data: { propertyAppData },
  } = useAppSelector((state: RootState) => state.appData);

  const [propertyExtraOptions, setPropertyExtraOptions] = useState(
    [] as PropertyExtraOptionDtos[]
  );
  const [propertyUniqueOptions, setPropertyUniqueOptions] = useState(
    [] as PropertyUniqueOptionDtos[]
  );
  const [propertyServiceOptions, setPropertyServiceOptions] = useState(
    [] as PropertyServicesDtos[]
  );

  // property data
  const propertyData = propertyAppData[generatePropertyNumber];

  // property info values
  const initialValues: generatePropertyInitTypes = {
    unparsedAddress:
      createPropertyData?.address ||
      mpacCreatePropertyData?.results?.[0]?.address?.unparsedAddress ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.unparsedAddress ||
      "",
    streetNumber:
      createPropertyData?.streetNumber ||
      mpacCreatePropertyData?.results?.[0]?.address?.streetNumber ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.streetNumber ||
      "",
    streetName:
      createPropertyData?.streetName ||
      mpacCreatePropertyData?.results?.[0]?.address?.streetName ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.streetName ||
      "",
    postalCode:
      createPropertyData?.postalCode ||
      mpacCreatePropertyData?.results?.[0]?.address?.postalCode ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.postalCode ||
      "",
    // data came from mpac property from api property
    roll:
      mpacCreatePropertyData?.results?.[0]?.roll ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.roll ||
      null,
    streetNumberSuffix:
      mpacCreatePropertyData?.results?.[0]?.address?.streetNumberSuffix ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.streetNumberSuffix ||
      null,
    upperStreetNumber:
      mpacCreatePropertyData?.results?.[0]?.address?.upperStreetNumber ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.upperStreetNumber ||
      null,
    streetType:
      mpacCreatePropertyData?.results?.[0]?.address?.streetType ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.streetType ||
      null,
    streetDirection:
      mpacCreatePropertyData?.results?.[0]?.address?.streetDirection ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.streetDirection ||
      null,
    municipality:
      mpacCreatePropertyData?.results?.[0]?.address?.municipality ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.municipality ||
      null,
    unitDesignator:
      mpacCreatePropertyData?.results?.[0]?.address?.unitDesignator ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.unitDesignator ||
      null,
    unitNumber:
      mpacCreatePropertyData?.results?.[0]?.address?.unitNumber ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.unitNumber ||
      null,
    province:
      mpacCreatePropertyData?.results?.[0]?.address?.province ||
      mpacAdditionalDetailsData?.mapcListPlanRsp?.province ||
      null,
    propertyId: mpacAdditionalDetailsData?.mapcListPlanRsp?.propertyId || null,
    // end of data came from mpac details
    purpose: "",
    saveFavourite: false,
    readComments: false,
    boostPermission: false,
    sharingEnabled: false,
    propertyTypeId: null,
    propertySubTypeId: null,
    title: null,
    location: null,
    propertySize: {
      keyName: propertyData.propertySizeDTOS[0].keyName,
      size: null,
    },
    price: null,
    description: null,
    basicResidentialOptions: {
      assessmentValue:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.assessmentValue || null,
      saleDate:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.saleDate || null,
      saleAmount:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.saleAmount || null,
      hydroServiceDescription:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.hydroServiceDescription || null,
      waterServiceDescription:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.waterServiceDescription || null,
      sanitaryServiceDescription:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.sanitaryServiceDescription || null,
      heatingSystemTypeCode:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.heatingSystemTypeCode || null,
      airConditioningFlag:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.airConditioningFlag || null,
      actualFrontage:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.actualFrontage || null,
      actualLotSize:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.actualLotSize || null,
      actualDepth:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.actualDepth || null,
      actualLotSizeUom:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.basicResidentialOptions
          ?.actualLotSizeUom || null,
    },
    primaryResidence: {
      actualYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.actualYear || null,
      effectiveYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.effectiveYear || null,
      renovationYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.renovationYear || null,
      additionYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.additionYear || null,
      firstFloorArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.firstFloorArea || null,
      secondFloorArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.secondFloorArea || null,
      thirdFloorArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.thirdFloorArea || null,
      basementArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.basementArea || null,
      basementFinished:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.primaryResidence
          ?.basementFinished || null,
    },
    secondaryResidenceDTO: {
      actualYearBuilt:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.secondaryResidenceDTO
          ?.actualYearBuilt || null,
      totalFloorArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.secondaryResidenceDTO
          ?.totalFloorArea || null,
    },
    garageStructure: {
      garageName:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.garageName || null,
      garageType:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.garageType || null,
      garageDescription:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.garageDescription || null,
      actualYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.actualYear || null,
      effectiveYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.effectiveYear || null,
      renovationYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.renovationYear || null,
      floorArea:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.floorArea || null,
      totalGarageSpacesCount:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.garageStructure
          ?.totalGarageSpacesCount || null,
    },
    buildingPermit: {
      permitNumber: null,
      issueDate: null,
      expiryDate: null,
      permitDescription: null,
    },
    propertyGeneralInfo: {
      actualYearBuilt:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.propertyGeneralInfo
          ?.actualYearBuilt || null,
      furnishedYear:
        mpacAdditionalDetailsData?.mapcListPlanRsp?.propertyGeneralInfo
          ?.furnishedYear || null,
      others: {},
    },
    facingAndFlorInfo: {
      numberOfFloors: null,
      yourFloor: null,
      direction: null,
    },
    groundFloorInfo: [],
    firstFloorInfo: [],
    secondFloorInfo: [],
    thirdFloorInfo: [],
    propertyExtraOptions: {},
    propertyUniqueOptions: {},
    propertyServices: {},
    tagPeople: null,
    contactRequestPermission: true,
    postBoostPermission: true,
    boost_permission: true,
    read_comments: true,
    save_favourite: true,
    sharing_enabled: true,
    hideLikesAndViewsCounts: false,
    turnOffCommenting: false,
    images: [],
    video: null,
  };

  // handle sumbit in generate new property
  const onSubmit = async (
    values: generatePropertyInitTypes,
    { resetForm }: any
  ) => {

    try {
      console.log("handle submit is called")
      const formData = new FormData();
      if (propertyStatusId === 3 && values.images.length < 3) {
        return;
      }
      values.images.forEach((file: any) => {
        formData.append("images", file);
      });
      if (values.video) {
        formData.append("video", values.video);
      }
      const { images, video, ...otherPropertyValues } = values;
      formData.append(
        "data",
        new Blob(
          [
            JSON.stringify({
              ...otherPropertyValues,
              price:otherPropertyValues.price && parseFloat(otherPropertyValues.price.toString().replace(/,/g, '')),
              purpose: propertyPurpose,
              propertyTypeId: generatePropertyNumber + 1,
              propertyStatusId,
              location:
                createPropertyData?.address ||
                mpacCreatePropertyData?.results[0]?.address?.unparsedAddress ||
                mpacAdditionalDetailsData?.mapcListPlanRsp?.unparsedAddress,
              ...propertyLocation,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      setIsLoading(true);

      formData.append("video", values.video);
      const { data } = await axios.post(
        propertyStatusId === 2 ?  draft_property_url : propertyStatusId === 3 ?create_property_url: "",
        formData,
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        resetPropertyDataContext();
        navigate("/property-listing");
      }, 2000);
      resetForm();
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };
  console.log("property scehma",propertyGeneralInfo)
  const propertyGeneralInfoSchema = propertyGeneralInfo
    .slice(2)
    .reduce((schema, field) => {
      const { keyName, displayName, isRequired, maxRange, value } = field;
      schema[keyName] = Yup.number()
        .typeError(`${displayName} must be a number`)
        .min(1, `${displayName} must be at least 1`);

      if (displayName === "Full Bathroom" || displayName === "Full Bedroom") {
        schema[keyName] = schema[keyName].required(
          `${displayName} is required`
        );
      }

      return schema;
    }, {} as { [key: string]: any });

  // create user validation schema
  const validationSchema = Yup.object().shape({
    images: Yup.array()
      .required("At least three images are required")
      .min(3, "At least three images are required"),
    propertySubTypeId: Yup.string().required(),
    propertySize: Yup.object({
      keyName: Yup.string().required(),
      size: Yup.string().required(),
    }),
    price: Yup.string().required(),
    title: Yup.string().required(),
    description: Yup.string().required(),
    video: Yup.mixed().test("file", "A file is required", (value) => {
      return !value || value !== undefined;
    }),
    propertyGeneralInfo: Yup.object().shape({
      actualYearBuilt:
        generatePropertyNumber === 0 || generatePropertyNumber === 2 || generatePropertyNumber === 3
          ? Yup.string().required()
          : Yup.string().nullable(),
      others: Yup.object().shape(propertyGeneralInfoSchema),
    }),
  });
  // create user validation schema
  const draftValidationSchema = Yup.object().shape({
    title: Yup.string().required(),
  });

  // add extra properties value
  useEffect(() => {
    setIsPropertyTypeSelected(false);
    setPropertyStatusId(2);
    setPropertyExtraOptions(
      propertyData.propertyExtraOptionDTOS.map((propertyExtraOption: any) => ({
        ...propertyExtraOption,
        value: 0,
      }))
    );

    setPropertyUniqueOptions(
      propertyData.propertyUniqueOptionDTOS.map(
        (propertyUniqueOption: any) => ({
          ...propertyUniqueOption,
          isSelect: false,
        })
      )
    );
    setPropertyServiceOptions(
      propertyData.propertyServicesDTOS.map((propertyServiceOption: any) => ({
        ...propertyServiceOption,
        isSelect: false,
      }))
    );
    setPropertyGeneralInfo(
      propertyData.propertyGeneralInfoDTOS.map((generalInfoData: any) => ({
        ...generalInfoData,
        value: 0,
      }))
    );
    setGroundFloorInfoItems([1, 2].map((_, index) => index));
    setFirstFloorInfoItems([1, 2].map((_, index) => index));
    setSecondFloorInfoItems([1, 2].map((_, index) => index));
    setThirdFloorInfoItems([1, 2].map((_, index) => index));
  }, [generatePropertyType]);

  // do task
  const doTask = () => {
    resetPropertyDataContext();

    navigate("/property-listing");
  };

  return (
    <GenaratePropertyContext.Provider
      value={{
        propertyExtraOptions,
        propertyServiceOptions,
        propertyUniqueOptions,
        setPropertyExtraOptions,
        setPropertyServiceOptions,
        setPropertyUniqueOptions,
        facingData,
        setFacingData,
        propertyGeneralInfo,
        setPropertyGeneralInfo,
        isLoading,
      }}
    >
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title={propertyStatusId === 3 ? "Property Created" : "Property Draft"}
          description={
            propertyStatusId === 3
              ? "Congratulations! Property Created Successfully."
              : "Congratulations! Property Drafted Successfully."
          }
          doTask={doTask}
        />
      </CustomModal>
      {isLoading && <LoadingOverlay message="Property Posting..." />}
      {propertyStatusId === 3 ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <Form>
                <CustomModal
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  showCloseBtn={false}
                >
                  <EnnvisionModal
                    title="Property Created"
                    description="Congratulations! Property Created Successfully."
                    doTask={doTask}
                  />
                </CustomModal>
                {isLoading && <LoadingOverlay message="Property Posting..." />}
                <PropertyInfo />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={draftValidationSchema}
        >
          {() => {
            return (
              <Form>
                <PropertyInfo />
              </Form>
            );
          }}
        </Formik>
      )}
    </GenaratePropertyContext.Provider>
  );
};

// make sure use
export const useGeneratePropetyContext = () => {
  return useContext(GenaratePropertyContext);
};

export default GenerateProperty;

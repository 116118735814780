import { Col, Container, Row } from "react-bootstrap";
import { BillSummaryStyle } from "./style";
import master from "../../assets/icons/ic_add_property_payment_master.svg";
import visa from "../../assets/icons/ic_add_property_payment_visa.svg";

import selectIcon from "../../assets/icons/ic_add_property_sel_payment.svg";
import unselectIcon from "../../assets/icons/ic_add_property_payment.svg";
import plusIcon from "../../assets/icons/ic_add_property_add_card.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  BlackDot,
  primaryColor,
  TertiraryHeading,
  UnderlineRed,
} from "../../components/GlobalStyle";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  authorizationToken,
  charge_On_plan_url,
  credit_cards__list_url,
  delete_credit_card_url,
  local_storage_web_key,
  mark_credit_card_default_url,
} from "../../utils/api_urls";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../context/context";
import { removeLoginData } from "../../redux/features/user/getLoginDataSlice";
import useLogout from "../../hooks/useLogout";
import { objectNotEmpty } from "../../utils/utilities";

export type creditCardTypes = {
  id: number;
  brand: string;
  cardNumber: string;
  cardHolderName: string;
  expiryMonth: string;
  expiryYear: string;
  cvcNumber: string;
  stripeCardId: string;
  isDefault: boolean;
};

const BillSummary = () => {
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState([] as creditCardTypes[]);
  const [error, setError] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [chargeOnCardLoading, setChargeOnCardLoading] = useState(false);
  const deleteCardToastId = useRef<any>(null);
  const cardDefaultToastId = useRef<any>(null);
  const { professionalPlan } = useGlobalContext();
  const logout = useLogout();
  console.log(Object.keys(professionalPlan).length === 0);

  const { planName, planId, getProfessionalPlansPriceResponse } =
    professionalPlan;
  const cardBrands: any = {
    VisaCard: visa,
    Mastercard: master,
  };

  const billSummaryData = [
    { title: "Plan Name", sub_title: planName },
    {
      title: "Subscription Fee",
      sub_title:
        "$" + getProfessionalPlansPriceResponse?.[0]?.subscriptionAmount,
    },
    {
      title: "Service Fee",
      sub_title: "$" + getProfessionalPlansPriceResponse?.[0]?.totalServiceFee,
    },
    {
      title: "Discount",
      sub_title: "$" + getProfessionalPlansPriceResponse?.[0]?.discountAmount,
    },
    {
      title: "GST",
      sub_title: "$" + getProfessionalPlansPriceResponse?.[0]?.gst,
    },
    {
      title: "Total",
      sub_title: "$" + getProfessionalPlansPriceResponse?.[0]?.grandTotal,
    },
  ];
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const {
    countryName: {
      results: { name: countryName },
    },
  } = useAppSelector((state) => state.appData.data);

  const togglePayment = (index: number) => {
    // const data = [...payments];
    // const unSelectData = data.map((paymentData) => {
    //   return paymentData.select === true
    //     ? { ...paymentData, select: false }
    //     : paymentData;
    // });
    // unSelectData[index].select = !data[index].select;
    // setPayments(unSelectData);
  };
  console.log({ professionalPlan });
  // handle credit cards list
  const creditCardListHandler = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        credit_cards__list_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
            "Content-Type": "application/json",
          },
        }
      );
      setCardsData(data.results);
      setLoading(false);
      console.log(data);
    } catch (error: any) {
      const { data } = error.response;
      if (data.responseCode == "400") {
        setCardsData([]);
      }
      setError(data);
      console.log({ error });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!objectNotEmpty(professionalPlan)) {
      navigate("/professional-plans");
    }
    creditCardListHandler();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // delete credit card
  type deleteCardTypes = {
    cardId: number;
    stripeCardId: string;
    userId: number;
  };
  const deleteCreditCard = async ({
    cardId,
    stripeCardId,
    userId,
  }: deleteCardTypes) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        delete_credit_card_url,
        {
          cardId,
          stripeCardId,
          userId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
            "Content-Type": "application/json",
          },
        }
      );
      setCardsData(data.results);
      setLoading(false);
      deleteCardToastId.current = toast("card deleted successfully...", {
        type: "success",
      });
      console.log(data);
      creditCardListHandler();
    } catch (error: any) {
      const { data } = error.response;
      deleteCardToastId.current = toast(error.responseMessage, {
        type: "success",
      });
      setError(data);
      console.log({ error });
      setLoading(false);
    }
  };

  // make card default promise
  const makeCardDefaultPromise = async (cardId: number, isDefault: boolean) => {
    // if card is already default then do nothing
    if (isDefault) return;
    try {
      const { data } = await axios.post(
        mark_credit_card_default_url,
        {
          cardId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      const defaultCardsData = cardsData.map((cardData) => ({
        ...cardData,
        isDefault: false,
      }));
      const updatedCardsData = defaultCardsData.map((cardData) =>
        cardData.id === cardId ? { ...cardData, isDefault: true } : cardData
      );
      setCardsData(updatedCardsData);
      cardDefaultToastId.current = toast("card default successfully...", {
        type: "success",
      });

      console.log({ data });
    } catch (error) {
      console.log({ error });
      cardDefaultToastId.current = toast("something went wrong ", {
        type: "error",
      });
    }
  };
  // charge on card promise
  const chargeOnCardPromise = async () => {
    // find default card
    const defaultCard = cardsData.find(({ isDefault }) => isDefault === true);
    // if card is already default then do nothing
    try {
      setChargeOnCardLoading(true);
      if (cardsData.length == 0) {
        alert("please add credit card");
        setChargeOnCardLoading(false);
        return;
      }
      const { data } = await axios.post(
        charge_On_plan_url,
        {
          userId: loginData?.userDetails.id,
          cardId: defaultCard?.id,
          planId,
          countryName,
          planPriceId: +getProfessionalPlansPriceResponse[0].id+10,
        },
        
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      console.log(data,"Maha");

      cardDefaultToastId.current = toast("charge on card successfully...", {
        type: "success",
      });
      logout();
      setChargeOnCardLoading(false);

      console.log({ data });
    } catch (error: any) {
      console.log("ali",{ error });
      cardDefaultToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
      setChargeOnCardLoading(false);
    }
  };
  return (
    <BillSummaryStyle>
      <div className="inner-container">
        <Container>
          <TertiraryHeading>Billing Summary</TertiraryHeading>
          <div>
            <UnderlineRed />
            <BlackDot />
          </div>
          <Row className="mt-3">
            <Col md={6}>
              <div className="bill-info-side">
                <div className="head-bar">
                  <h6 className="head-bar-title">Billing Info</h6>
                </div>
                <div className="features">
                  {billSummaryData.map(({ title, sub_title }, index) => (
                    <div
                      key={index}
                      className="feature d-flex justify-content-between"
                    >
                      <p className="feature-title mb-0">{title}</p>
                      <p className="feature-price mb-0">{sub_title}</p>
                    </div>
                  ))}
                </div>
                <div className="checkout-btn d-flex justify-content-center">
                  <CustomButton
                    bgcolor={`${primaryColor}`}
                    color="white"
                    padding="10px 8px"
                    width="94%"
                    loading={chargeOnCardLoading}
                    type="submit"
                    title="Check Out"
                    margin="auto"
                    fontFamily={"EnnVisionsMedium"}
                    clicked={chargeOnCardPromise}
                    fontSize="16px"
                  />
                </div>
              </div>
            </Col>
            <Col md={6} className="p-0 p-md-auto">
              <div className="credit-card-side mt-4 mt-md-0">
                <div className="head-bar bg-light d-flex justify-content-between">
                  <h6 className="head-bar-title">Credit Card Info</h6>
                  <Link to="/add-credit-card">
                    <img src={plusIcon} alt="plus" />
                  </Link>
                </div>
                {loading ? (
                  <Loader />
                ) : error ? (
                  <h5 className="text-center mt-2 fw-bold">No Cards Found</h5>
                ) : (
                  <div className="payments">
                    {cardsData?.map(
                      ({
                        id,
                        brand,
                        cardHolderName,
                        cardNumber,
                        cvcNumber,
                        expiryMonth,
                        expiryYear,
                        isDefault,
                        stripeCardId,
                      }) => (
                        <div
                          onClick={() => togglePayment(id)}
                          key={id}
                          className="payments-type d-flex align-items-center"
                        >
                          <img
                            src={cardBrands[brand]}
                            className="cursor-pointer"
                            alt="plus"
                          />
                          <div className="ms-3 d-flex justify-content-between w-100 align-items-center cursor-pointer">
                            <div>
                              <h6 className="step-title mb-2">
                                {cardHolderName}
                              </h6>
                              <p className="step-subtitle mb-0">{cardNumber}</p>
                            </div>
                            <img
                              onClick={() =>
                                makeCardDefaultPromise(id, isDefault)
                              }
                              src={isDefault ? selectIcon : unselectIcon}
                              alt={`${
                                isDefault ? "select-icon" : "unselect-icon"
                              }`}
                              className="select me-3"
                            />
                          </div>
                          {/* <DeleteOutlined
                            className="fs-4 ms-auto mt-2 mb-3"
                            onClick={() =>
                              deleteCreditCard({
                                cardId: id,
                                stripeCardId,
                                userId: loginData?.userDetails.id!,
                              })
                            }
                          /> */}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </BillSummaryStyle>
  );
};

export default BillSummary;

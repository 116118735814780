import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icons/ic_logo.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor } from "../../components/GlobalStyle";
import { CreatedUserModalStyle } from "./style";

const CreatedUserModal = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/login");
    }, 4000);
    return () => clearTimeout(timeoutId);
  }, [navigate]);
  return (
    <CreatedUserModalStyle>
      <div className="inner-container">
        {/* <img src={logo} alt="logo" /> */}
        <h6 className="account-created my-2">Account Created</h6>
        <p className="message">
          Congratulations! Your Account Has Been Successfully Created. Thank You
          For Joining Us And We're Excited To Have You On Board
        </p>
        <div className="mt-1">
          <CustomButton
            bgcolor="transparent"
            color={primaryColor}
            padding="8px 8px"
            width="100%"
            type="submit"
            title="DISMISS"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="18px"
            clicked={() => navigate("/login")}
          />
        </div>
      </div>
    </CreatedUserModalStyle>
  );
};

export default CreatedUserModal;

import axios, { AxiosRequestConfig } from "axios";
import { loginDataTypes } from "../redux/features/user/types";

// live base url
// https://houseupapi.com:8443/ennvision_apis/
// dev base url
// https://www.ennvisionapistore.com:8443/ennvision_apis/
// media live url
// https://www.ennvisionapistore.com:8443/ennvision_apis/
// media dev url
// https://ennvisionapistore.com:8443

// base urls
// const liveBaseUrl = "https://houseupapi.com:8443/ennvision_apis/";
const liveBaseUrl = "https://fistastore.com:444/ennvision_apis/";
// const testBaseUrl = "https://www.ennvisionapistore.com:8443/ennvision_apis/";
const testBaseUrl = "https://fistastore.com:444/ennvision_apis/";
const liveMediaUrl = "https://fistastore.com:444";
const testMediaUrl = "https://fistastore.com:444";
const domianLiveUrl = "https://houseup.ca/";
const domianTestUrl = "https://development.houseup.ca/";

const isLive = false;

export const base_url = isLive ? liveBaseUrl : testBaseUrl;
export const live_base_url = isLive ? domianLiveUrl : domianTestUrl;
export const media_base_url = isLive ? liveMediaUrl : testMediaUrl;
export const search_by_city_url = "property/getByCity";
export const home_data_url = "home/getData";
export const get_locations_url = "getLocations";
export const get_blogs_url = "getBlogs";
export const contact_us_url = "public/contactus";

// privacy policy for story,post,property
export const privacy_policy_url = "updatePrivacyPolicy";
// user urls
export const get_app_data_url = "api/getAppData";
export const login_url = "api/auth/signin";
export const refresh_token_url = "api/auth/refreshtoken";
export const admin_getAll_users_url = "user/getAllUsers?pageNo=";
export const user_details_url = "admin/user/getdetails";
export const user_update_status_url = "admin/user/updateStatus";
export const search_user_by_admin_url = "admin/user/search";
export const update_user = "admin/user/updateUser";
export const delete_user_url = "user/deleteUser";
export const user_profile_url = "user/viewUserProfile";
export const upload_profile_url = "user/uploadProfilePicture";
export const user_stories_url = "story/getStoriesByUserId";
export const user_properties_url = "property/getPropertiesByUserId";
export const user_posts_url = "post/getPostsByUserId";
export const block_user_url = "user/blockUser";
export const unblock_user_url = "user/unBlockUser";
// general settings url
export const general_settings_url = "/generalsetting";
export const feedback_url = "/rating/giveRating";
export const online_support_url = "/onlineSupport/create";
// professional urls
export const professional_dropdown_url = "profession/getallprofessions";
export const become_professional_url = "user/becomeProfessional";
export const get_all_professional = "admin/user/professionals/getAll?pageNo=";
export const search_professional_url = "user/professionals/search";
export const edit_professional_url = "/user/becomeProfessional/edit";
export const delete_professional_url = "user/professional/delete";
export const professions_dropdown_url = "profession/getallprofessions";
export const update_professional_url = "user/becomeProfessional/edit";
export const professional_details_url = "user/professionals/getDetails";
export const search_professionals_url = "newsfeed/searchAndFilter?pageNo=";
// story urls
export const create_story_url = "story/create";
export const delete_story_url = "story/delete";
// location urls
export const location_url = "https://ipinfo.io/json?token=11847a6086fc3e";
// register urls
export const generate_otp_url = "otp/generate";
export const verify_otp_url = "otp/verifyOTP";
export const create_user_url = "api/auth/signup";
// property urls
export const create_property_url = "property/create";
export const draft_property_url = "/property/draft";
export const edit_property_url = "property/edit";
export const property_details_url = "property/getPropertyDetails";
export const property_delete_url = "property/delete";
// user forget password url
export const user_reset_password_url = "user/resetPassword";
// credit card urls
export const add_credit_card_url = "creditCard/addCreditCard";
export const get_credit_card_url = "creditCard/getCreditCard";
export const mark_credit_card_default_url = "creditCard/markCreditCardDefault";
export const credit_cards__list_url = "creditCard/loadAllCreditCards";
export const delete_credit_card_url = "creditCard/deleteCreditCard";
export const professional_plans = "/professional/plans/getPlansDetails";
export const charge_On_plan_url = "creditCard/chargeCustomerByPlan";
// post urls
export const create_post_url = "post/create";
export const post_details_url = "post/getPostDetails?postId=";
export const update_post_url = "post/edit";
export const report_post_url = "reporting/report";
export const hide_post_url = "newsfeed/hide";
export const delete_post_url = "post/delete";

// usecases
export const useCaseRegisteration = "REGISTRATION";
export const useCaseForgetPassowrd = "FORGETPASSWORD";

// newsfeed urls
export const newsfeed_url = "newsfeed/getall?pageNo=";
export const follow_url = "user/followto";

// likes urls
export const like_url = "newsfeed/like";
export const get_all_likes_url = "newsfeed/getlikes?newsFeedId=";

// follow unfollow urls
export const unfollow_url = "user/unfollowto";
export const get_all_followers_url = "user/getfollowers?userId=";
export const get_all_following = "/user/getfollowing?userId=";
// comments urls
export const reply_comments_url = "newsfeed/addcomment";
export const add_comments_url = "newsfeed/addcomment";
export const edit_comments_url = "newsfeed/editcomment";
export const get_comments_url = "newsfeed/getAllComments?newsFeedId=";
export const delete_comment_url = "newsfeed/deletecomment";
// stories urls
export const stories_url = "newsfeed/getstories?pageNo=";
export const mpac_create_property_url = "/property/mpac/getMPACProperty";
export const mpac_card_charging_url =
  "/creditcard/property/chargeMpacFeeByPlans";

// property urls
export const get_my_properties_url = "property/getMyProperties";
export const get_property_by_status_url = "property/getMyProperties/seeAll";
export const get_all_properties_url = "property/getAll?pageNo=";
export const search_properties_url = "newsfeed/searchAndFilter?pageNo=";
// contact request url
export const contact_request_url = "property/visit/booking";
export const edit_booking_url = "property/visit/booking/edit";
export const sent_requests_url = "property/visit/getAllSendingRequests";
export const upcoming_requests_url = "property/visit/getAllReceiveBooking";
export const change_booking_status_url = "property/visit/changeStatus";
// user authorized token
export const authorizationToken = (loginData: loginDataTypes) => {
  return {
    Authorization: `Bearer ${loginData.jwtDetails.token}`,
  };
};
// get tour guide
export const tour_guide_url = "getTourGuide";

// user refresh token
export const refreshToken = (loginData: loginDataTypes) => {
  return {
    refreshToken: `Bearer ${loginData.jwtDetails.refreshToken}`,
  };
};

export const local_storage_web_key = "ennvision-web:token";
export const local_storage_web_guides = "ennvision-web:tourGuides";

const baseURL = base_url;

// create for all operation axios inceptor
const client = axios.create({ baseURL });
client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const authorizedClient = axios.create({ baseURL });

type axiosRequest = AxiosRequestConfig;
// normal axios request
export const axiosRequest = ({ ...options }: axiosRequest) => {
  console.log({ options });
  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    // optionaly catch errors and add additional logging here
    return error;
  };
  return client(options).then(onSuccess).catch(onError);
};

// authorized axios request
export const authorizedAxiosRequest = ({ ...options }: axiosRequest) => {
  console.log({ options });
  const onSuccess = (response: any) => response;
  const onError = (error: any) => {
    // optionaly catch errors and add additional logging here
    return error;
  };
  return authorizedClient(options).then(onSuccess).catch(onError);
};

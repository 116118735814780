import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { lightGrey2 } from "../GlobalStyle";
import ProfileBoxSkeleton from "./Profile";

const PropertyDetailsSkeleton = () => {
  return (
    <Wrapper>
      <div className="d-flex justify-content-between gap-3">
        <Skeleton containerClassName="w-75" className="rounded" />
        <Skeleton containerClassName="w-75" className="rounded" />
      </div>
      <div className="d-flex justify-content-between gap-3">
        <Skeleton containerClassName="w-75" className="rounded" />
        <Skeleton containerClassName="w-75" className="rounded" />
      </div>
      <Row className="m-0">
        <Col sm={6} lg={8} className="p-0 pe-sm-2">
          <Skeleton height={500} className="rounded" />
        </Col>
        <Col sm={6} lg={4} className="p-0">
          <Skeleton height={245} className="rounded" />
          <Skeleton height={245} className="rounded mt-2" />
        </Col>
      </Row>
      <div className="property-basics">
        <Row className="inner-container justify-content-center">
          {Array.from({ length: 4 }).map((_, index) => (
            <Col key={index} sm={6} lg={3} className="p-0 pe-sm-2">
              <Skeleton count={2} className="rounded" />
            </Col>
          ))}
        </Row>
      </div>
      <ProfileBoxSkeleton />
      <div className="about-property">
        <Skeleton className="rounded w-75" />
        <Skeleton className="rounded w-50" />
        <Skeleton count={3} className="rounded w-75" />
      </div>
      <div className="key-features mt-3">
        {/* <div className="d-flex justify-content-between gap-2">
          <Skeleton containerClassName="w-75" className="rounded" />
          <Skeleton containerClassName="w-75" className="rounded" />
        </div> */}
        <Row>
          {Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className="d-flex justify-content-between gap-2 my-1"
            >
              <Skeleton containerClassName="w-50" className="rounded w-50" />
              <Skeleton containerClassName="w-50" className="rounded w-50" />
            </div>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
};

export default PropertyDetailsSkeleton;

const Wrapper = styled.div`
  .property-basics {
    margin-top: 12px;
    .inner-container {
      border: 1px solid ${lightGrey2};
      padding: 12px;
      border-radius: 7px;
    }
  }
`;

import CustomButton from "../../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import CreatePropertyReportsStyle from "./style";
import { Col, Container, Row } from "react-bootstrap";
import {
  BlackDot,
  fontFamilyMedium,
  primaryColor,
  pureDark,
  tertiaryGrey18,
  TertiraryHeading,
  UnderlineRed,
  whiteColor,
} from "../../../components/GlobalStyle";
import redTickIcon from "../../../assets/icons/ic_select_red.svg";
import uncheckIcon from "../../../assets/icons/ic_select_gry.svg";
import { useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";
import HeadBarPriceDiscount from "../../../components/Headbar/HeadBarPriceDiscount";
import { mpacAvailableReportingPlan } from "../../../redux/features/types";
import { usePropertyContext } from "../../../context/PropertyContext";

const CreatePropertyReports = () => {
  const navigate = useNavigate();
  const [propertyReportData, setPropertyReportData] = useState<
    null | mpacAvailableReportingPlan[]
  >(null);
  const { createPropertyPlan, setCreatePropertyReport } = usePropertyContext();
  // app data from redux
  const { mpacAvailableReportingPlan } = useAppSelector(
    (state) => state.appData.data
  );

  // add create property data
  const handleProperty = () => {
    const isSelectedReport = propertyReportData?.filter(
      ({ isWant }) => isWant == true
    );
    if (
      createPropertyPlan?.subscriptionAmount === "0.0" &&
      isSelectedReport?.length === 0
    ) {
      navigate("/property-info");
    } else {
      setCreatePropertyReport(isSelectedReport!);
      navigate("/property-checkout");
    }
  };

  // on skip handler
  const handleSkipProperty = () => {
    const isSelectedReports = propertyReportData?.filter(
      ({ isWant }) => isWant == true
    );
    console.log({ isSelectedReports });
    setCreatePropertyReport([]);

    if (createPropertyPlan?.subscriptionAmount === "0.0") {
      console.log({ isSelectedReports });
      navigate("/property-info");
    } else {
      navigate("/property-checkout");
    }
  };

  useEffect(() => {
    setPropertyReportData(
      mpacAvailableReportingPlan.map((data) => ({
        ...data,
        isWant: false,
      }))
    );
  }, []);
  // is want toggler
  const isWantToggler = (plan_id: number) => {
    if (propertyReportData) {
      setPropertyReportData(
        propertyReportData?.map((reportData) => {
          if (reportData.planId === plan_id) {
            return { ...reportData, isWant: !reportData.isWant };
          }
          return reportData;
        })
      );
    }
  };

  if (Object.keys(createPropertyPlan!).length == 0) {
    navigate("/create-property-plans");
  }
  return (
    <CreatePropertyReportsStyle>
      <Container>
        <div className="d-flex justify-content-between">
          <div>
            <TertiraryHeading>You May Also Like</TertiraryHeading>
            <div>
              <UnderlineRed />
              <BlackDot />
            </div>
          </div>
          <div className="d-flex">
            <div className="me-2">
              <CustomButton
                bgcolor={tertiaryGrey18}
                color={pureDark}
                padding="8px 8px"
                width="150px"
                type="submit"
                title="SKIP"
                margin="auto"
                fontSize="16px"
                fontFamily={fontFamilyMedium}
                clicked={handleSkipProperty}
              />
            </div>
            <CustomButton
              bgcolor={primaryColor}
              color="white"
              padding="8px 8px"
              width="150px"
              type="submit"
              title="Next"
              margin="auto"
              fontSize="16px"
              fontFamily={fontFamilyMedium}
              clicked={handleProperty}
            />
          </div>
        </div>
        <Row>
          {propertyReportData?.map(
            ({
              displayName,
              subscriptionAmount,
              propertySlabs,
              currencySymbol,
              planId,
              isWant,
              subTotal,
            }) => (
              <Col key={planId} md={6} className="property-card">
                <HeadBarPriceDiscount
                  currencySymbol={currencySymbol}
                  displayName={displayName}
                  subTotal={subTotal}
                  subscriptionAmount={subscriptionAmount}
                />
                <div className="property-card-inner-section">
                  <div className="property-card-inner-section-items">
                    {propertySlabs.map(({ slabId, slabName, isActive }) => (
                      <div key={slabId} className="d-flex feature mt-2">
                        <img
                          src={isActive ? redTickIcon : uncheckIcon}
                          alt={isActive ? "check-icon" : "uncheck-icon"}
                        />
                        <p className="feature-text ms-2 mb-0">{slabName}</p>
                      </div>
                    ))}
                    <div className="property-below-btn d-flex justify-content-end">
                      <CustomButton
                        bgcolor={isWant ? primaryColor : tertiaryGrey18}
                        color={isWant ? whiteColor : pureDark}
                        padding="8px 8px"
                        width="120px"
                        type="submit"
                        title={isWant ? "Yes I Want" : "You Want?"}
                        margin="auto"
                        fontSize="16px"
                        clicked={() => isWantToggler(planId)}
                        fontFamily={fontFamilyMedium}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </Container>
    </CreatePropertyReportsStyle>
  );
};

export default CreatePropertyReports;

import Navbar from "../../components/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import search_icon from "../../assets/icons/ic_search-1.svg";
import { HeroSectionStyle } from "./style";

const HeroSection = () => {
  return (
    <>
      <Container>
        <Navbar />
      </Container>
      <HeroSectionStyle>
        {/* <div
          className="position-absolute w-100"
          style={{
            bottom: "2%",
          }}
        >
          <SocialFooter />
        </div> */}
        <div className="inner-container d-flex align-items-center flex-column justify-content-center">
          <h4 className="inner-container-main-heading">
            <span>Own the way you sell your home</span>
            <span className="d-block text-center">Only with  HouseUp </span>
          </h4>
        </div>
      </HeroSectionStyle>
    </>
  );
};

export default HeroSection;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { stories_url } from "../../../utils/api_urls";

import { storyDataTypes } from "../../../utils/types";
import { RootState } from "../../store";

type storiesInitialTypes = {
  loading: boolean;
  error: any;
  storiesData: null | storyDataTypes;
};

const initialState: storiesInitialTypes = {
  storiesData: {} as storyDataTypes,
  loading: false,
  error: null,
};

// Generates pending, fulfilled and rejected action types
export const getUserNewsfeedStories = createAsyncThunk(
  "user/newfeedStories",
  async (page: number, thunkAPI) => {
    const {
      loginData: { data },
    } = thunkAPI.getState() as RootState;
    try {
      const { data: storiesData } = await axios(stories_url + +page, {
        headers: {
          Authorization: `Bearer ${data?.jwtDetails?.token}`,
        },
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.responseMessage);
    }
  }
);
const newsfeedStoriesSlice = createSlice({
  name: "features/user/newsfeed",
  initialState,
  reducers: {
    addUserStories: (state, data: PayloadAction<storyDataTypes>) => {
      state.storiesData = data.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserNewsfeedStories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserNewsfeedStories.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.storiesData = action.payload;
      }
    );
    builder.addCase(getUserNewsfeedStories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Something went wrong";
    });
  },
});
export const { addUserStories } = newsfeedStoriesSlice.actions;
export default newsfeedStoriesSlice.reducer;

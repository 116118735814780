import styled from "styled-components";
import {
  fontFamilyMedium,
  lightColor1,
  lightGrey,
  pureDark,
  tertiaryGrey19,
  whiteColor,
} from "../GlobalStyle";

export const PrivacyReportStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 550px;
  .inner-section {
    border: 1px solid ${lightColor1};
    border-radius: 6px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 96%;
      margin: 0 auto;
    }

    .top-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px;
      background: ${whiteColor};
    }
    .mini-title {
      color: ${pureDark};
      font-size: 18px;
      font-family: ${fontFamilyMedium};
    }
    .sub-title {
      color: ${lightGrey};
      font-size: 16px;
      border-bottom: 1px solid ${tertiaryGrey19};
      padding-bottom: 8px;
    }

    .btm-border {
      border-bottom: 1px solid ${tertiaryGrey19};
    }

    .advanced-settings {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: ${lightColor1};
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const ShowReasonModalStyle = styled.div`
  background-color: white;
  padding: 12px;
  border-radius: 6px;

  .heading {
    font-family: ${fontFamilyMedium};
    text-align: center;
  }
`;

import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import CreateUserStyle from "./style";
import { fontFamilyBold, fontFamilyMedium, primaryColor } from "../../components/GlobalStyle";
import Head from "../../components/Head/Head";
import Errormsg from "../../components/ErrorMessage";
import { useAppSelector } from "../../app/hooks";
import CustomPhoneInput from "../../components/CustomInputNumber/CustomPhoneInput";
import CustomModal from "../../components/Modal/CustomModal";
import { useRef, useState } from "react";
import CreatedUserModal from "./RegisteredModal";
import RegistrationErrorModal from "./RegistrationErrorModal";
import axios from "axios";
import { create_user_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import AuthenticationText from "../../components/AuthenticationText/Index";
import { useGlobalContext } from "../../context/context";
import ic_logo from "../../assets/icons/ic_logo.svg";
import { Link } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import { Container } from "react-bootstrap";

// create user initial values types
type initialValuesType = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string | number;
  username: string;
  password: string;
  confirmPassword?: string;
};

const RegisterUser = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userPhoneNumber } = useGlobalContext();
  const [terms, setTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // const scrollViewRef = useRef<any>();
  const createUserToastId = useRef<any>();
  const { result: userLocation } = useAppSelector(
    (state) => state.userLocation
  );
  // get Validations from redux appData
  const {
    countryName: {
      results: {
        countryCode,
        countryFlagURL,
        examplePhoneNumber,
        name,
        phoneNumberLength,
      },
    },
    validations,
  } = useAppSelector((state) => state.appData.data);
  // create user initial values

  const initialValues: initialValuesType = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: userPhoneNumber ? userPhoneNumber : "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  // user validations
  const firstName = validations.find((item) => item.key === "USER_FIRSTNAME")!;
  const lastName = validations.find((item) => item.key === "USER_LASTNAME")!;
  const emailAddress = validations.find(
    (item) => item.key === "EMAIL_ADDRESS"
  )!;
  const phoneNumber = validations.find(
    (item) => item.key === "CANADA_PHONE_NUMBER"
  )!;
  const userName = validations.find((item) => item.key === "USER_NAME")!;
  const password = validations.find((item) => item.key === "PASSWORD")!;
  // user regExpressions
  const firstNameReg = new RegExp(firstName.pattern);
  const lastNameReg = new RegExp(lastName.pattern);
  const emailAddressReg = new RegExp(emailAddress.pattern);
  const phoneNumberReg = new RegExp(phoneNumber.pattern);
  const userNameReg = new RegExp(userName.pattern);
  const passwordReg = new RegExp(password.pattern);
  console.log({ userNameReg, firstNameReg });
  console.log({ phoneNumberReg });
  // create user validation schema
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(firstName.notBlankMsg)
      .matches(firstNameReg, firstName.patternMsg),
    lastName: Yup.string()
      .required(lastName.notBlankMsg)
      .matches(lastNameReg, lastName.patternMsg),
    emailAddress: Yup.string()
      .required(emailAddress.notBlankMsg)
      .matches(emailAddressReg, emailAddress.patternMsg),
    phoneNumber: Yup.string()
      .required(phoneNumber.notBlankMsg)
      .matches(phoneNumberReg, phoneNumber.patternMsg),
    username: Yup.string()
      .required(userName.notBlankMsg)
      .matches(userNameReg, userName.patternMsg),
    password: Yup.string()
      .required(password.notBlankMsg)
      .matches(passwordReg, password.patternMsg),
    confirmPassword: Yup.string()
      .required("confirm password is required!")
      .oneOf([Yup.ref("password")], "passwords must match"),
  });
  // create user data submit
  const onSubmit = async (values: initialValuesType) => {
    if (!terms) {
      setShowTermsError(true);
      setTimeout(() => {
        setShowTermsError(false);
      }, 2000);
      // if (scrollViewRef.current) {
      //   scrollViewRef.current.scrollToEnd({ animated: true });
      // }
      return;
    }
    // get all values other than confirm password
    const allValues = {
      ...values,
    };
    delete allValues.confirmPassword;
    const registerUserValues = {
      ...allValues,
      phoneNumber: values.phoneNumber.toString(),
      countryName: name,
      countryCode: countryCode,
    };
    const userData = {
      ...registerUserValues,
      roleId: 1,
      channel: "Web",
      address: userLocation?.address,
      city: userLocation?.city,
      state: userLocation?.state,
    };
    try {
      setIsLoading(true);
      const { data } = await axios.post(create_user_url, userData);
      setIsShowModal(true);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      // createUserToastId.current = toast(error.response.data.responseMessage, {
      //   type: "error",
      //   autoClose: 1000,
      // });
      setErrorMessage(error.response.data.responseMessage);
      setIsErrorModalVisible(true);
    }
  };

  return (
    <>
      <Head title="Create User" />
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
        showCloseBtn={false}
      >
        <CreatedUserModal />
      </CustomModal>
      {isErrorModalVisible && (
        <CustomModal
          isModalVisible={isErrorModalVisible}
          setIsModalVisible={setIsErrorModalVisible}
          showCloseBtn={false}
        >
          <RegistrationErrorModal
            errorMessage={errorMessage}
            dismissHandler={() => setIsErrorModalVisible(false)}
          />
        </CustomModal>
      )}
      <Container>
      <CreateUserStyle>
        {/* <div className="inner-container" ref={scrollViewRef}> */}
        <div className="inner-container" style={{height:"auto"}} >
          <div className="inner-container-card">
            <div className="inner-container-card-logo">
            <h4 className="title mb-0 text-center mt-10" style={{fontFamily:fontFamilyMedium}}>
                            Create Your Account
                            </h4>
            </div>
         
            <p className="text-center message my-3">
              Please fill in the required information to complete your
              registration and join our us.
            </p>
            {/* <div className="inner-container-card-logo">
              <img src={ic_logo} alt="ic_logo" className="logo" />
            </div> */}
            <div className="inner-container-card-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  console.log(formik.values, "formik values");
                  return (
                    <Form
                      name="basic"
                      onFinish={formik.handleSubmit}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      // validateMessages={validationSchema}
                    >
                      <div className="login-input-fields">
                        <div>
                          <FormControl
                            control="input"
                            type="text"
                            name="firstName"
                            border="1px solid #EFEFF4"
                            placeholder="First Name"
                            className={
                              formik.errors.firstName &&
                              formik.touched.firstName
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                        </div>
                        <div>
                          <FormControl
                            control="input"
                            type="text"
                            name="lastName"
                            border="1px solid #EFEFF4"
                            placeholder="Last Name"
                            className={
                              formik.errors.lastName && formik.touched.lastName
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                        </div>
                        <div>
                          <FormControl
                            control="input"
                            type="email"
                            name="emailAddress"
                            border="1px solid #EFEFF4"
                            placeholder="Enter Email"
                            className={
                              formik.errors.emailAddress &&
                              formik.touched.emailAddress
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <CustomPhoneInput
                            countryNumber={countryCode}
                            placeholder={examplePhoneNumber}
                            phoneLength={phoneNumberLength}
                            countryFlag={countryFlagURL}
                            phoneValueHandler={(value: number | string) =>
                              formik.setFieldValue("phoneNumber", value)
                            }
                            value={formik.values.phoneNumber}
                            name="phoneNumber"
                            countryName={name}
                            disabled
                          />
                          <div className="mt-3">
                            <ErrorMessage
                              name="phoneNumber"
                              component={Errormsg}
                            />
                          </div>
                        </div>
                        <div className="login-input-fields-field mt-2">
                          <FormControl
                            control="input"
                            type="text"
                            name="username"
                            border="1px solid #EFEFF4"
                            placeholder="User Name"
                            className={
                              formik.errors.username && formik.touched.username
                                ? "is-invalid"
                                : "customPasswordInput"
                            }
                          />
                        </div>
                        <div className="login-input-fields-field">
                          <FormControl
                            control="password"
                            type="text"
                            name="password"
                            max={6}
                            border="1px solid #EFEFF4"
                            placeholder="Password"
                            className={
                              formik.errors.password && formik.touched.password
                                ? "is-invalid"
                                : "customPasswordInput"
                            }
                          />
                        </div>
                        <div className="login-input-fields-field">
                          <FormControl
                            control="password"
                            type="text"
                            name="confirmPassword"
                            border="1px solid #EFEFF4"
                            placeholder="Confirm password"
                            className={
                              formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                                ? "is-invalid"
                                : "customPasswordInput"
                            }
                          />
                        </div>
                        <div className="mt-1">
                          <CustomButton
                            bgcolor={primaryColor}
                            color="white"
                            padding="11px 8px"
                            width="100%"
                            type="submit"
                            title="Register"
                            fontSize="16px"
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
            <TermsAndConditions
              terms={terms}
              setTerms={setTerms}
              showTermsError={showTermsError}
            />
            {!isLoading && (
              <AuthenticationText
                link="/login"
                title="Already registered?"
                linkText="Log in here"
                margin="10px 0 0 0"
              />
            )}
          </div>
        </div>
      </CreateUserStyle>
      </Container>
    </>
  );
};

export default RegisterUser;

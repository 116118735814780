import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import bedroomIcon from "../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../assets/icons/ic_property_area.svg";
import { MiniHeadingSecondary } from "../../components/GlobalStyle";
import { Col, Container, Row } from "react-bootstrap";
import PropertiesByStatusStyle from "./style";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  get_property_by_status_url,
} from "../../utils/api_urls";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { propertiesByStatusTypes } from "../../utils/types";
import view_more from "../../assets/icons/ic_newsfeed_more.svg";
import { useGlobalContext } from "../../context/context";
import CustomModal from "../../components/Modal/CustomModal";
import PropertyOptions from "../../components/PostOptions/PropertyOptions";
import { numberToLocalString } from "../../utils/utilities";

const PropertiesByStatus = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [propertiesByStatusData, setPropertiesByStatusData] = useState(
    {} as propertiesByStatusTypes
  );
  const { showPropertyOptions, setShowPropertyOptions, setSelectedPropertyId } =
    useGlobalContext();

  const propertySizeTypes = useAppSelector(
    (state) => state.appData.data.propertyAppData[0].propertySizeDTOS
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log({ state });

  useEffect(() => {
    if (id) {
      getPropertiesByStatusPromise();
    }
  }, [id]);

  const { data: loginData } = useAppSelector((state) => state.loginData);

  // if property title not available then navigate back
  if (!state?.propertyTitle) return <Navigate to="/property-listing" />;

  // properties by status promise
  async function getPropertiesByStatusPromise() {
    try {
      setLoading(true);
      const { data } = await axios.post(
        get_property_by_status_url,
        {
          userId: loginData?.userDetails.id,
          propertyStatusId: +id!,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setPropertiesByStatusData(data.results);
      console.log({ data });
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.responseMessage);
      setLoading(false);
      console.log(error.response.data, "error in properties data");
    }
  }

  const navigateToDetails = (id: any) => {
    navigate(`/property-details/${id}`);
  };

  if (loading) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;

  return (
    <PropertiesByStatusStyle>
      <CustomModal
        isModalVisible={showPropertyOptions}
        setIsModalVisible={setShowPropertyOptions}
        showCloseBtn={false}
      >
        <PropertyOptions />
      </CustomModal>
      <Container className="properties">
        <h6 className="title">{state?.propertyTitle}</h6>
        <Row>
          {propertiesByStatusData.properties.map(
            ({
              propertySize: { keyName, size },
              fullBathrooms,
              fullBedrooms,
              title,
              purpose,
              price,
              location,
              propertyId,
            }) => (
              <Col key={propertyId} md={6} lg={4}>
                <Card onClick={() => navigateToDetails(propertyId)}>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-self-center">
                      <MiniHeadingSecondary>{title}</MiniHeadingSecondary>
                      <img
                        style={{ height: "4px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedPropertyId(propertyId);
                          setShowPropertyOptions(true);
                        }}
                        src={view_more}
                        className="cursor-pointer align-self-start"
                        alt="view-more"
                      />
                    </div>
                    <Card.Text>
                      <p className="sub-title mt-2">{location}</p>
                    </Card.Text>
                    <div className="d-flex justify-content-between align-items-center property-description">
                      <Button variant="primary">{purpose}</Button>
                      <p className="price m-0">
                        ${price && numberToLocalString(+price)}
                      </p>
                    </div>
                    <div className="property-details d-flex justify-content-between align-items-center">
                      <div className="beds d-flex justify-content-between align-items-center">
                        <span>
                          <img src={bedroomIcon} alt="bed" />
                        </span>
                        <p className="text">{fullBedrooms}</p>
                      </div>

                      <div className="bathrooms d-flex justify-content-between align-items-center">
                        <span>
                          <img src={bathIcon} alt="bath" />
                        </span>
                        <p className="text">{fullBathrooms}</p>
                      </div>
                      <div className="length d-flex justify-content-between align-items-center">
                        <span>
                          <img src={areaIcon} alt="area" />
                        </span>
                        <p className="text">
                          {size}

                          {
                            propertySizeTypes.find(
                              (propertySize) => propertySize.keyName === keyName
                            )?.displayName
                          }
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
        </Row>
      </Container>
    </PropertiesByStatusStyle>
  );
};

export default PropertiesByStatus;

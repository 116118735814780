import { Image } from "antd";
import { Dispatch, useEffect, useState } from "react";
import { media_base_url } from "../../../utils/api_urls";

type viewDocProps = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  img_url: string;
};
const ViewDoc: React.FC<viewDocProps> = ({ visible, setVisible, img_url }) => {
  const [scaleStep, setScaleStep] = useState(0.5);
  const [imageUrl, setImageUrl] = useState("");

  // Update imageUrl state when img_url prop changes
  useEffect(() => {
    setImageUrl(media_base_url + img_url);
  }, [img_url]);

  return (
    <Image
      width={200}
      style={{ display: "none" }}
      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
      preview={{
        visible,
        scaleStep,
        src: imageUrl,
        onVisibleChange: (value) => {
          setVisible(value);
        },
      }}
    />
  );
};

export default ViewDoc;

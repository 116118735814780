import styled from "styled-components";
import {
  ChineseSliver,
  fontFamilyMedium,
  lightGrey,
  primaryColor,
  tertiaryGrey21,
} from "../../components/GlobalStyle";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton/CustomButton";

const RetriveUsername = () => {
  const {
    state,
  }: {
    state: { firstName: string; lastName: string; userName: string };
  } = useLocation();
  const navigate = useNavigate();

  if (!state) {
    navigate("/login");
    return null;
  }

  return (
    <Wrapper>
      <div className="inner-container">
        <h2>Reterive Username</h2>
        <p className="your-username">Your username are:</p>
        <p>
          Full Name:
          <span>
            {state.firstName} {state.lastName}
          </span>
        </p>
        <p>
          Username: <span>{state.userName}</span>
        </p>
        <CustomButton
          bgcolor={primaryColor}
          color="white"
          padding="6px"
          width="100%"
          textTransform="none"
          type="button"
          title="Return to Sign in"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            navigate("/login");
          }}
        />
      </div>
    </Wrapper>
  );
};

export default RetriveUsername;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);
  .inner-container {
    width: 400px;
    border: 1px solid ${tertiaryGrey21};
    padding: 10px 10px 10px 20px;
    background-color: white;
    height: 210px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 1px 1px 7px ${ChineseSliver};
    border-radius: 5px;
  }
  h2 {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
    text-align: center;
  }
  .your-username {
    font-size: 16px;
    font-family: ${fontFamilyMedium};
  }
  p {
    font-size: 14px;
    span {
      font-family: ${fontFamilyMedium};
    }
  }
`;

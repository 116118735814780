import React from "react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { primaryColor } from "../../../components/GlobalStyle";
import { OtpGenerationErrorModalStyle } from "./style";

interface OtpGenerationErrorModalProps {
  errorMessage: string;
  closeModal: () => void;
}

const OtpGenerationErrorModal: React.FC<OtpGenerationErrorModalProps> = ({
  errorMessage,
  closeModal,
}) => {
  return (
    <OtpGenerationErrorModalStyle>
      <div className="inner-container">
        <h6 className="account-created my-2">Error</h6>
        <p className="message">{errorMessage}</p>
        <div className="mt-1">
          <CustomButton
            bgcolor="transparent"
            color={primaryColor}
            padding="8px 8px"
            width="100%"
            type="button"
            title="DISMISS"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="18px"
            clicked={closeModal}
          />
        </div>
      </div>
    </OtpGenerationErrorModalStyle>
  );
};

export default OtpGenerationErrorModal;

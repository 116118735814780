import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment, { Moment } from "moment";
import "moment/locale/en-gb"; // Import any locales you need
import "antd/dist/antd.css"; // Import the stylesheet
import { Dispatch, SetStateAction } from "react";
dayjs.extend(customParseFormat);

type CustomProps = {
  time: string;
  setTime: (newTime: string) => void;
  onBlur?: () => void;
};

const CustomTime: React.FC<CustomProps> = ({ time, setTime, onBlur }) => {
  const getTime = (time: Moment | null, timeString: string) => {
    setTime(timeString);
  };

  const momentTime = moment(time, "HH:mm:ss");
  return (
    <TimePicker
      className="w-100"
      value={time ? momentTime : null}
      onChange={getTime}
      format="HH:mm:ss"
      onBlur={onBlur}
    />
  );
};

export default CustomTime;

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BlackDot,
  lightGrey4,
  primaryColor,
  TertiraryHeading,
  UnderlineRed,
  whiteColor,
} from "../../components/GlobalStyle";
import { EditPropertyInfoStyle } from "./style";
import Gallery from "./Gallery";
import FeatureDetails from "./FeatureDetails";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Steps } from "antd";
import SellOrRent from "./SaleOrRent";
import MpacAdditionalDetails from "./MpacAdditionalDetails";
import Head from "../../components/Head/Head";
import { useEditPropetyContext } from "./EditProperty";
import { usePropertyContext } from "../../context/PropertyContext";
import { useParams } from "react-router-dom";
import { objectNotEmpty } from "../../utils/utilities";
import { useFormikContext } from "formik";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import Rooms from "./Rooms";
import { editPropertyInitTypes } from "./types";

const EditPropertyInfo = () => {
  const { errors, handleSubmit, values } =
    useFormikContext<editPropertyInitTypes>();
  const [IsError, setIsErrors] = useState(false);
  const { isLoading } = useEditPropetyContext();
  console.log(">> errors", errors);
  // mpac additional details
  const {
    mpacAdditionalDetailsData,
    setPropertyStatusId,
    setIsPropertyTypeSelected,
    isPropertyTypeSelected,
    propertyStatusId,
    generatePropertyNumber,
  } = usePropertyContext();
  const { id } = useParams();

  // current step of property generate
  const [current, setCurrent] = useState(0);

  // generate property steps
  const steps =
    generatePropertyNumber === 1 && values.isMpac
      ? [
          {
            title: "",
            content: 1,
          },
          {
            title: "",
            content: 2,
          },
          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
        ]
      : generatePropertyNumber === 1
      ? [
          {
            title: "",
            content: 1,
          },

          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
        ]
      : values.isMpac
      ? [
          {
            title: "",
            content: 1,
          },
          {
            title: "",
            content: 2,
          },
          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
          {
            title: "",
            content: 5,
          },
        ]
      : [
          {
            title: "",
            content: 1,
          },

          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
          {
            title: "",
            content: 5,
          },
        ];

  // next step handler
  const next = () => {
    setCurrent(current + 1);
  };

  // previous step handler
  const prev = () => {
    setCurrent(current - 1);
  };

  // mapping steps
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const componentSwitcher = (value: number) => {
    if (generatePropertyNumber === 1) {
      switch (value) {
        case 1:
          return <SellOrRent />;
        case 2:
          return <MpacAdditionalDetails />;
        case 3:
          return <FeatureDetails />;
        case 4:
          return <Gallery />;
        default:
          return <SellOrRent />;
      }
    } else {
      switch (value) {
        case 1:
          return <SellOrRent />;
        case 2:
          return <MpacAdditionalDetails />;
        case 3:
          return <Rooms />;
        case 4:
          return <FeatureDetails />;
        case 5:
          return <Gallery />;
        default:
          return <SellOrRent />;
      }
    }
  };

  // on change for steps
  const onChange = (value: number) => {
    setCurrent(value);
  };

  // handle custom submit by show error of validations
  const handleCustomSubmit = () => {
    console.log(">> im here 0");
    if (objectNotEmpty(errors)) {
      console.log(">> im here 1");
      setIsErrors(true);
      setTimeout(() => {
        setIsErrors(false);
        setCurrent(0);
      }, 2000);
    } else {
      console.log(">> im here 2");
      handleSubmit();
    }
  };

  // do task
  const doTask = () => {
    setIsErrors(false);
    setCurrent(0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [current]);

  return (
    <EditPropertyInfoStyle>
      <Head title={`edit-info/${id}`} />
      <CustomModal
        isModalVisible={IsError}
        setIsModalVisible={setIsErrors}
        showCloseBtn={false}
        onCancel={() => {}}
      >
        <EnnvisionModal
          title="Oops! Something went wrong."
          description="Please enter a valid value in all the required fields before proceeding. Please see the fields marks red and fill information.  Once you have filled in all the details, click the ‘Next’ button to continue."
          doTask={doTask}
        />
      </CustomModal>
      <Container>
        <TertiraryHeading>Fill Property Information</TertiraryHeading>
        <div>
          <UnderlineRed />
          <BlackDot />
        </div>
        <div className="my-3 steps">
          <Steps onChange={onChange} current={current} items={items} />
        </div>
        {/* <div className="inner-section mt-2"><Editors/></div> */}
        <div className="mt-3">
          {componentSwitcher(steps[current].content)}
          <Row className="justify-content-end mt-3">
            {current > 0 && current < steps.length - 1 ? (
              <Col className="d-flex justify-content-end" md={4} xl={4}>
                <CustomButton
                  bgcolor={lightGrey4}
                  color={whiteColor}
                  padding="8px 8px"
                  width="100%"
                  type="button"
                  title="Skip"
                  margin="auto"
                  border="none"
                  fontSize="18px"
                  clicked={() => next()}
                />
              </Col>
            ) : (
              <Col md={4} xl={6} />
            )}
            <Col
              className="d-flex flex-column flex-sm-row justify-content-end mt-2 mt-md-0 gap-2"
              md={current < steps.length ? 4 : 8}
            >
              {current < steps.length - 1 && (
                <CustomButton
                  bgcolor={primaryColor}
                  color={whiteColor}
                  padding="8px 8px"
                  width="100%"
                  type="button"
                  title="Next"
                  margin="auto"
                  border="none"
                  fontSize="18px"
                  clicked={() => next()}
                />
              )}

              {/* {current === steps.length - 1 && (
                <CustomButton
                  bgcolor={lightGrey4}
                  color={whiteColor}
                  padding="10px"
                  width="100%"
                  type="button"
                  title={`${
                    propertyStatusId == 2
                      ? "Save as Draft"
                      : "Submit for Review"
                  }`}
                  margin="auto"
                  border="none"
                  fontSize="16px"
                  clicked={handleCustomSubmit}
                />
              )} */}
              {current === steps.length - 1 && (
                <>
                  <CustomButton
                    bgcolor={lightGrey4}
                    color={whiteColor}
                    padding="10px"
                    width="100%"
                    type="submit"
                    title="Save as Darft "
                    margin="auto"
                    border="none"
                    fontSize="16px"
                    clicked={() => {
                      setIsPropertyTypeSelected(true);
                      {
                        !objectNotEmpty(errors) && handleCustomSubmit();
                      }
                      // next();
                    }}
                  />

                  <CustomButton
                    bgcolor={primaryColor}
                    color={whiteColor}
                    padding="10px"
                    width="100%"
                    type="button"
                    loading={isLoading}
                    title="Save & Review"
                    margin="auto"
                    fontSize="16px"
                    clicked={() => {
                      setPropertyStatusId(3);
                      setIsPropertyTypeSelected(true);
                      // {
                      //   !objectNotEmpty(errors)
                      //     ? handleCustomSubmit()
                      //     : (setCurrent(0), setIsErrors(true));
                      // }
                      if (!objectNotEmpty(errors)) {
                        handleCustomSubmit();
                      } else {
                        setCurrent(0);
                        setIsErrors(true);
                      }

                      // next();
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </EditPropertyInfoStyle>
  );
};

export default EditPropertyInfo;

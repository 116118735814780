import Wrapper from "./style";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { primaryColor } from "../GlobalStyle";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import { authorizationToken, delete_post_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useGlobalContext } from "../../context/context";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import EnnvisionWarningModal from "../CustomModals/WarningModal";

const MyPostOptions = () => {
  const { setShowSocialOptions, setShowPostOptions, setSelectedPost,selectedPost ,selectedStories,newsfeedData, selectedStory,id,postId} =
    useNewsfeedContext();
  const { setCounter } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const copyToClipboard = useCopyToClipboard();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // delete post promise
  const deletePostPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.delete(delete_post_url, {
        headers: {
          ...authorizationToken(loginData!),
        },
        data: {
          postId: selectedPost?.postId,
          newsFeedId: selectedPost?.newsFeedId,
        },
      });
      setIsLoading(false);
      setIsModalVisible(true);

      setTimeout(() => {
        setIsModalVisible(false);
        setShowPostOptions(false);
        navigate("/explore-nearby");
        setCounter((prev) => prev + 1);
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  const whenClickedYes = async () => {
    try {
      setIsLoading(true);
      // setShowPostOptions(false)
      // setIsModalVisible(false)
      const { data } = await axios.delete(delete_post_url, {
        headers: {
          ...authorizationToken(loginData!),
        },
        data: {
          postId: postId,
          newsFeedId: id,
        },
      });
      setIsLoading(false);
      setIsModalVisible(true);
      setIsLoading(false);
      setIsDeleteModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setCounter((prev) => prev + 1);
        setIsModalVisible(false);
        navigate("/explore-nearby");
        setShowPostOptions(false);
      }, 2000);
      // setTimeout(() => {
      //   setCounter((prev) => prev + 1);
      //   setIsModalVisible(false);
      //   setShowPostOptions(false);
      //   setIsConfirmModalVisible((false))
      // }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      setShowPostOptions(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  
  const postOptions = [
    {
      title: "Edit Post",
      doTask: () => {
        console.log("Nada",id);

        navigate(`/edit-post/${postId}`);
        setShowPostOptions(false);

      },
    },
    {
      title: "Edit Post Privacy",
      doTask: () => {
        console.log("clicked",selectedPost.postId);
        
        setShowPostOptions(false);
        navigate("/post-privacy");
      },
    },
    {
      title: "Copy link to share Post",
      doTask: () => {
        console.log("Nada",postId);
        copyToClipboard(`post/${postId}`);
        setShowPostOptions(false);
      },
    },
    {
      title: "Share on other media",
      doTask: () => {
        console.log("Nada",postId);

        setShowSocialOptions(true);
        setShowPostOptions(false);
      },
    },
  ];
  console.log("HI",newsfeedData,selectedPost);
  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Delete Post"
          description="Post Deleted Successfully from your timeline."
          doTask={() => {
            setCounter((prev) => prev + 1);
            setIsModalVisible(false);
            navigate("/explore-nearby");
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isLoading}
          title="Delete Property"
          description="Are You Sure You Want To Delete Your Property? This Action Is Irreversible And All Your Data And Information Associated With The Property Will Be Permanently Deleted."
          doTask={() => {
            // setShowPropertyOptions(false);
            whenClickedYes()
          }}
        />
      </CustomModal>
      <ul>
        <li
          className="title"
          style={{ color: primaryColor, fontWeight: "500" }}
          onClick={()=>{setIsDeleteModal(true)}}
        >
          {isLoading ? "Deleting Post..." : "Delete Post"}
        </li>
        {postOptions.map(({ title, doTask }, index) => {
          
          return (
            <React.Fragment key={index}>
              <li onClick={doTask}>{title}</li>
            </React.Fragment>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default MyPostOptions;

import Wrapper from "./style";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import React, { useState } from "react";
import { primaryRed } from "../GlobalStyle";
import { useNavigate } from "react-router-dom";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import usePropertyDelete from "../../hooks/usePropertyDelete";
import CustomModal from "../Modal/CustomModal";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useGlobalContext } from "../../context/context";

const MyPropertyOptions = () => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isLoading, deletePropertyPromise, error } = usePropertyDelete();
  const { setShowSocialOptions, selectedPost, setShowPropertyOptions } =
    useNewsfeedContext();
  const { setCounter } = useGlobalContext();
  const copyToClipboard = useCopyToClipboard();

  const navigate = useNavigate();
  const propertyOptions = [
    {
      title: "Turn comment off for this Property",
      doTask: () => {
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Edit Property Privacy",
      doTask: () => {
        navigate("/property-privacy");
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Saved A Property",
      doTask: () => {
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Copy link to share this Property",
      doTask: () => {
        copyToClipboard(`property-details/${selectedPost.propertyId}`);
      },
    },
    {
      title: " Share on other media",
      doTask: () => {
        setShowSocialOptions(true);
        setShowPropertyOptions(false);
      },
    },
  ];

  // do task when property deletes
  const doTask = () => {
    setCounter((prev) => prev + 1);
    setIsModalVisible(false);
    setShowPropertyOptions(false);
  };

  console.log({ isModalVisible, isDeleteModal });

  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isLoading}
          title="Delete Property"
          description="Are You Sure You Want To Delete Your Property? This Action Is Irreversible And All Your Data And Information Associated With The Property Will Be Permanently Deleted."
          doTask={() => {
            // setShowPropertyOptions(false);
            deletePropertyPromise({
              setIsDeleteModal,
              setIsModalVisible,
              setShowPropertyOptions,
            });
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Property Deleted"
          description="Property Delete Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <ul>
        <li
          className="title"
          style={{ color: primaryRed, fontWeight: "500" }}
          onClick={() => {
            // setShowPropertyOptions(false);
            setIsDeleteModal(true);
          }}
        >
          Delete Property
        </li>
        {propertyOptions.map(({ title, doTask }, index) => {
          return (
            <React.Fragment key={index}>
              <li onClick={doTask}>{title}</li>
            </React.Fragment>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default MyPropertyOptions;

import { CreatePostTypeStyle } from "./style";
import select_img from "../../../assets/icons/ic_post_image.svg";
import select_video from "../../../assets/icons/ic_post_video.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { usePostContext } from "../../../context/PostContext";

const CreatePostType = () => {
  const { postImages, setPostImages, postVideos, setPostVideos } =
    usePostContext();
  const navigate = useNavigate();

  // on image files selected
  const onImageFilesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageFilesList = Array.from(e.target.files);
      console.log({ imageFilesList });
      const imageUrls = await Promise.all(
        imageFilesList.map((file) => readFile(file))
      );
      console.log({ imageUrls });
      setPostImages(imageUrls);
      navigate("/crop-post-images");
    }
  };

  // on video files selected
  const onVideoFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const videFilesList = Array.from(e.target.files);
      console.log({ videFilesList });

      setPostVideos(videFilesList);
      navigate("/create-post-videos");
    }
  };

  console.log({ postImages, postVideos });
  return (
    <CreatePostTypeStyle>
      <Container>
        <Row className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-4">
          <Col md={2} />
          <Col md={5} className="type-box text-center">
            <label htmlFor="upload-photos">
              <img src={select_img} alt="select-img" />
              <p className="type-box-title">Create Post With Image</p>
            </label>
            <input
              type="file"
              name="photos"
              id="upload-photos"
              multiple
              onChange={onImageFilesChange}
              accept="image/*"
            />
          </Col>
          <Col md={5} className="type-box text-center">
            <label htmlFor="upload-videos">
              <img src={select_video} alt="select-video" />
              <p className="type-box-title">Create Post With Video</p>
            </label>
            <input
              type="file"
              name="photos"
              id="upload-videos"
              onChange={onVideoFileChange}
              accept="video/*"
            />
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </CreatePostTypeStyle>
  );
};

export default CreatePostType;

// read file by input type file
function readFile(file: File): Promise<string> {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
}

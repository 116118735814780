import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { useAppSelector } from "../../app/hooks";
import { UserPostStyle } from "./style";
import { Container } from "react-bootstrap";
import HeadingViewMore from "../../components/HeadingViewMore/HeadingViewMore";
import Posts from "../professional/ProfessionalProfile/Posts";
import {
  TertiraryHeadingMini,
  primaryColor,
  whiteColor,
} from "../../components/GlobalStyle";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useParams } from "react-router-dom";
import { authorizationToken, user_posts_url } from "../../utils/api_urls";

const UserPosts = () => {
  const [loading, setloading] = useState(true);
  const [userPostsData, setUserPostsData] = useState({} as UserPostsTypes);
  const [error, setError] = useState("");
  const loginData = useAppSelector((state) => state.loginData.data);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  // user properties promise
  const handleUserPostsPromise = async () => {
    try {
      setloading(true);
      const {
        data: { results },
      } = await axios.post(
        user_posts_url + `?pageNo=${page}`,
        {
          userId: id,
          statusId: null,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast("got posts successfully", {
        type: "success",
        autoClose: 1000,
      });
      if (page > 0) {
        setUserPostsData({
          ...userPostsData,
          postList: [...userPostsData.postList, ...results.postList],
        });
      } else {
        setUserPostsData(results);
      }
      setloading(false);
    } catch (error: any) {
      console.log({ error });
      setloading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setError(error.response.data.responseMessage);
    }
  };
  useEffect(() => {
    handleUserPostsPromise();
  }, [page]);

  if (loading && page < 1) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;

  return (
    <UserPostStyle>
      <div className="py-3">
        <Container>
          <HeadingViewMore bottomLines={false} title="Post Listing" />
          {userPostsData.postList ? (
            <Posts posts={userPostsData.postList} />
          ) : (
            <TertiraryHeadingMini className="text-center my-2">
              No Posts Available
            </TertiraryHeadingMini>
          )}
          {page + 1 !== userPostsData?.totalPages && (
            <div className="d-flex justify-content-center my-3">
              <CustomButton
                bgcolor={primaryColor}
                color={whiteColor}
                padding="6px"
                width="150px"
                type="button"
                loading={loading}
                title="Load More"
                margin="auto"
                clicked={() => setPage(page + 1)}
              />
            </div>
          )}
        </Container>
      </div>
    </UserPostStyle>
  );
};

export default UserPosts;

// user posts types

export interface UserPostsTypes {
  totalItems: number;
  postList: PostList[];
  totalPages: number;
  currentPage: number;
}

export interface PostList {
  postId: number;
  newsFeedId: number;
  description: string;
  postLayout: number;
  imagesURLS: string[];
  videosURLS: string;
  longitude: number;
  latitude: number;
  address: string;
  city: string;
  state: string;
  country: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  isFavourite: boolean;
  postStatusId: number;
  ownerData: OwnerData;
  lastLikeAndComment: LastLikeAndComment;
  createdDateTime: string;
  updatedDateTime: string;
  isEdited: boolean;
}

export interface OwnerData {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  isFollower: boolean;
  isFollowing: boolean;
  id: number;
}

export interface LastLikeAndComment {
  isLiked: boolean;
  totalLikes: number;
  lastLikedUserId: any;
  lastLikedUserFirstName: any;
  lastLikedUserLastName: any;
  totalComments: number;
  lastCommentUserId: any;
  lastCommentUserFirstName: any;
  lastCommentUserLastName: any;
  lastCommentText: any;
  lastCommentUserProfilePicture: any;
}

import PuffLoader from "react-spinners/PuffLoader";
import LoaderStyle from "./style";
import { primaryColor } from "../GlobalStyle";

const Loader = () => {
  return (
    <LoaderStyle className="mt-3">
      <PuffLoader color={primaryColor} loading={true} />
    </LoaderStyle>
  );
};

export default Loader;

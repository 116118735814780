import { AsceptRatioStyle } from "./style";

type asceptRatio = {
  ratioToggler: (ratio: number) => void;
};
const AsceptRatio: React.FC<asceptRatio> = ({ ratioToggler }) => {
  return (
    <AsceptRatioStyle>
      <p>Original</p>
      <p onClick={() => ratioToggler(1 / 1)}>1:1</p>
      <p onClick={() => ratioToggler(4 / 5)}>4:5</p>
      <p onClick={() => ratioToggler(16 / 9)}>16:9</p>
    </AsceptRatioStyle>
  );
};

export default AsceptRatio;

import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey5,
  lightblue,
  pureDark,
  secondaryDark4,
  tertiaryGrey7,
  tertiaryGrey8,
} from "../../../components/GlobalStyle";
import {
  lightDark,
  primaryColor,
  tertiaryGrewish,
  whiteColor,
} from "../../../components/GlobalStyle";

const PropertyListStyle = styled.div`
  background-color: ${tertiaryGrey8};
  padding: 10px;
  height:1000px;
`;

export default PropertyListStyle;

export const PropertyStyle = styled.div`
  margin-top: 20px;
  .card {
    /* box-shadow: 0px 0px 20px #00000059; */
    border-radius: 10px;
    /* margin-top: 30px; */
    border: none;
    &-body {
      text-align: left;
      padding: 7px;
    }
    .image-container {
      position: relative;
      width: 100%; /* Adjust width as needed */
      height: 0;
      padding-top: 66.67%; //(2/3) * 100% = 66.67%
    }

    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    cursor: pointer;
    .card-img-top {
      /* height: 340px; */
      object-fit: cover;
    }
    .sub-title {
      color: ${lightDark};
      font-size: 14px;
    }
    .property-description {
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 5px;
      .btn-primary {
        color: ${primaryColor};
        background-color: rgb(108 177 193 / 20%) !important;
        font-size: 14px;
        border-radius: 3px;
        border: none;
        font-family: ${fontFamilyMedium};
        @media screen and (max-width: 480px) {
          font-family: "EnnVisionsMedium";
        }
        /* &:hover {
            color: ${whiteColor};
            background-color: ${primaryColor};
          } */
      }
      .price {
        font-size: 18px;
        font-family: "EnnVisionsMedium";
        color: ${primaryColor};
      }
    }
    .property-details {
      margin-top: 10px;
      .img {
        vertical-align: super;
      }
      .text {
        margin-top: 3px;
        margin-left: 4px;
        margin-bottom: 0;
      }
    }
  }
`;

export const StorySliderStyle = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;

  .section-center {
    margin: 0 auto;
    width: 100%;
    /* have to have a height */
    height: 100vh;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  .story-img {
    margin-bottom: 1rem;
    width: 150px;
    height: 100vh;
    object-fit: cover;
    border: 4px solid hsl(210, 31%, 80%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: hsl(21, 62%, 45%);
  }

  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* opacity: 0; */
    display: none;
    transition: var(--transition);
  }
  article.activeSlide {
    /* opacity: 1; */
    display: block;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
    display: none;
  }
  article.nextSlide {
    transform: translateX(100%);
    display: none;
  }
`;

// professional profile box style

export const ProfesseionalProfileBoxStyle = styled.div`
  border-radius: 6px;
  padding-top: 16px;
  max-width: 1040px;
  margin: 0 auto;

  /* upload profile image */
  label.label input[type="file"] {
    position: absolute;
    top: -1000px;
  }
  .label {
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    display: inline-block;
  }
  /* .label:hover {
    background: #5cbd95;
  }
  .label:active {
    background: #9fa1a0;
  } */
  .label:invalid + span {
    color: #000000;
  }
  .label:valid + span {
    color: #ffffff;
  }

  .owner-details {
    border: 1px solid ${lightGrey5};
    border-radius: 5px;
    padding: 14px;
    background: ${whiteColor};

    .profile-img {
      height: 80px;
      border-radius: 50%;
    }

    .bio {
      h6 {
        font-size: 18px;
        font-family: "EnnVisionsMedium";
        color: ${pureDark};
      }
      .branch {
        color: ${tertiaryGrey7};
        font-size: 16px;
      }
      .location {
        color: ${secondaryDark4};
        font-size: 14px;
      }
    }
    .sold-properties {
      border-radius: 5px;

      .properties {
        background: ${tertiaryGrey8};
        border: 1px solid #f5f5f5;
        border-radius: 5px;

        .quantity {
          font-size: 18px;
          font-family: "EnnVisionsMedium";
        }
      }
      padding: 15px;
      .title {
        color: ${tertiaryGrey7};
        font-size: 15px;
      }
      .chat-btn {
        background: ${lightblue};
        border-radius: 5px;
        font-size: 15px;
        padding: 4px;
        color: ${whiteColor};
        width: 90%;
      }
      .call-btn {
        background: ${primaryColor};
        border-radius: 5px;
        font-size: 15px;
        padding: 4px;
        color: ${whiteColor};
        width: 90%;
      }

      @media screen and (min-width: 576px) {
        margin-top: -10px;
        button {
          width: 100%;
        }
      }
      @media screen and (max-width: 576px) {
        margin-top: 10px;
        button {
          width: 50%;
        }
      }
    }
  }
`;

export const ProfileDetailsStyle = styled.div`
  max-width: 1040px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  margin: 0 auto;
  .inner-section {
    height: calc(100vh - 150px);
    .professional-section {
      background: ${whiteColor};
      border: 1px solid ${tertiaryGrey8};
      padding: 25px;
      border-radius: 7px;
    }
  }
`;

import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { professionalPlanTypes } from "../redux/features/types";

type professionalContext = {
  // professional context
  isProfessional: boolean;
  setIsProfessional: Dispatch<SetStateAction<boolean>>;
  professionType: professionType;
  setProfessionType: Dispatch<SetStateAction<professionType>>;
  professionalPlan: professionalPlanTypes;
  setProfessionalPlan: Dispatch<SetStateAction<professionalPlanTypes>>;
  isShowFollowing: boolean;
  setIsShowFollowing: Dispatch<SetStateAction<boolean>>;
  isShowFollowers: boolean;
  setIsShowFollowers: Dispatch<SetStateAction<boolean>>;
};

export type professionType = {
  id: number;
  name: string;
};

const ProfessionalContext = createContext({} as professionalContext);

const ProfessionalProvider = ({ children }: { children: React.ReactNode }) => {
  const [professionalPlan, setProfessionalPlan] = useState(
    {} as professionalPlanTypes
  );
  const [isProfessional, setIsProfessional] = useState(false);
  const [professionType, setProfessionType] = useState({} as professionType);
  const [isShowFollowing, setIsShowFollowing] = useState(false);
  const [isShowFollowers, setIsShowFollowers] = useState(false);

  return (
    <ProfessionalContext.Provider
      value={{
        isProfessional,
        setIsProfessional,
        professionType,
        setProfessionType,
        professionalPlan,
        setProfessionalPlan,
        isShowFollowing,
        setIsShowFollowing,
        isShowFollowers,
        setIsShowFollowers,
      }}
    >
      {children}
    </ProfessionalContext.Provider>
  );
};

// make sure use
const useProfessionalContext = () => {
  return useContext(ProfessionalContext);
};

export { useProfessionalContext, ProfessionalProvider };

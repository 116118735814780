import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey2,
  primaryColor,
  primaryRed,
  whiteColor,
} from "../GlobalStyle";

const StoriesListStyle = styled.div`
  display: flex;
  column-gap: 10px;
  overflow-x: auto;
  /* .add-story-container {
    .card {
      margin-top: 20px;
      height: 144px;
      .plus-icon {
        position: absolute !important;
        bottom: 30%;
        left: 40%;
        background: wheat;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;
      }
      .add-story-img {
        height: 120px;
        width: 100%;
        object-fit: cover;
      }
      .card-title {
        font-size: 12px;
        margin-top: 10px;
        font-family: "EnnVisionsBold";
      }
    }
  } */

  .story-card {
    border: 0.5px solid ${lightGrey2};
    border-radius: 4px;
    margin-top: 20px;

    .card-img-top,
    .status-text,
    .video-section {
      height: 144px;
      object-fit: cover;
      width: 100px;
      border-radius: 7px;
      cursor: pointer;
    }

    .status-text {
      background: ${primaryColor};
      padding: 4px;
      word-break: break-all;
      padding: 0 10px;

      h6 {
        color: ${whiteColor};
        font-size: 14px;
        font-family: ${fontFamilyMedium};
        width: 98%;
        text-align: center;
      }
    }

    .card-body {
      padding-bottom: 4px;
      width: 100%;

      border-radius: 4px;
      .card-title {
        font-size: 14px;
        color: ${whiteColor};
        margin-bottom: 6px;
        font-family: ${fontFamilyMedium};
      }
    }
  }
`;

export default StoriesListStyle;

import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import UserProfileBox from "../../../components/ProfileBox/ProfileBox";
import store from '../../../redux/store';
import { getUseDetails } from "../../../redux/features/user/userDetailsSlice";

import {
  authorizationToken,
  local_storage_web_key,
  upload_profile_url,
  user_profile_url,
} from "../../../utils/api_urls";
import { userProfileTypes } from "../types";
import UserProfileStyle from "./style";
import HeadingViewMore from "../../../components/HeadingViewMore/HeadingViewMore";
import Posts from "../../professional/ProfessionalProfile/Posts";
import Stories from "../../Story/Stories";
import { useNavigate } from "react-router-dom";
import Property from "../../../components/Property/Property";
import DashboardSection from "../../../components/DashboardSection/DashboardSection";
import PropertiesSkeleton from "../../../components/Skeletons/Properties";
import PostsSkeleton from "../../../components/Skeletons/Posts";
import StoriesSkeleton from "../../../components/Skeletons/Stories";
import Skeleton from "react-loading-skeleton";
import ProfileBoxSkeleton from "../../../components/Skeletons/Profile";
import { loginDataTypes } from "../../../redux/features/types";
import { updateUserHandler } from "../../../redux/features/user/getLoginDataSlice";

function ViewUserProfile() {
  const [loading, setLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState("");
  const [userProfileData, setUserProfileData] = useState(
    {} as userProfileTypes
  );
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const navigate = useNavigate();

  // login data
  const { data: loginData } = useAppSelector((state) => state.loginData);
  // user profile promise
  const userProfileDetailsPromise = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        user_profile_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setUserProfileData(data.results);
      setLoading(false);
    } catch (error: any) {
      console.log({ error });
      setError(error.response.data.responseMessage);
      setLoading(false);
      console.log(error.response.data, "error in user profile promise data");
    }
  };

  // upload file promise

  const uploadFilePromise = async () => {
    if (!profileImage) return;
    const formData = new FormData();
    formData.append("profilePicture", profileImage);

    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            userId: loginData?.userDetails.id,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setProfileLoading(true);
      const { data } = await axios.post(
        upload_profile_url,
        formData,

        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      console.log("view user profile",{ data });

      // login data from local storage
      const localStorageLoginData: loginDataTypes = JSON.parse(
        localStorage.getItem(local_storage_web_key)!
      );
      localStorageLoginData.userDetails = {
        ...localStorageLoginData.userDetails,
        profileImageURL: data?.results?.profilePictureURL,
      };
      localStorage.setItem(
        local_storage_web_key,
        JSON.stringify(localStorageLoginData)
      );
      store.dispatch(
        updateUserHandler({
          userId: userProfileData?.id!,
          firstName: userProfileData?.firstName || "",
          lastName: userProfileData?.lastName || "",
          profileImageUrl: data?.results?.profilePictureURL,
        })
      );

      setProfileLoading(false);
    } catch (error: any) {
      // setError(error.response.data);
      setProfileLoading(false);
      console.log(error.response.data, "error in properties data");
    }
  };

  useEffect(() => {
    if (profileImage) {
      uploadFilePromise();
    } else {
      userProfileDetailsPromise();
    }
  }, [profileImage]);
  useEffect(()=>{
    loginData && store.dispatch(getUseDetails(loginData?.userDetails.id))
     },[])
  return (
    <UserProfileStyle>
      <Container>
        {/* <h1>{JSON.stringify(userProfileData.professionalDetails)}2122323</h1> */}

        {error ? (
          <NoDataAvailable title={error} />
        ) : (
          <div>
            {loading ? (
              <div className="d-flex justify-content-end flex-column align-items-end">
                <>
                  <Skeleton className="mt-2" containerClassName="w-25" />
                  <Skeleton containerClassName="w-50" />
                </>
              </div>
            ) : (
              userProfileData.professionalDetails?.id && (
                <div className="d-flex justify-content-end mt-3">
                  <DashboardSection
                    title="Professional Dashboard"
                    subtitle="Manage your professional accounts"
                    onClick={() =>
                      navigate(`/profile-details/${loginData?.userDetails.id}`)
                    }
                  />
                </div>
              )
            )}
            {loading ? (
              <ProfileBoxSkeleton />
            ) : (
              <UserProfileBox
                userData={userProfileData}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
                profileLoading={profileLoading}
              />
            )}
            <div className="mt-3">
              {loading ? (
                <StoriesSkeleton />
              ) : (
                userProfileData.storiesNewsFeedList && (
                  <>
                    <HeadingViewMore
                      bottomLines={false}
                      title="Stories List"
                      listing="VIEW MORE STORIES"
                      viewMoreTask={() =>
                        navigate(`/user-stories/${loginData?.userDetails.id}`)
                      }
                    />
                    <div className="d-flex stories-list">
                      <Stories
                        stories={
                          userProfileData.storiesNewsFeedList.storiesList
                        }
                      />
                    </div>
                  </>
                )
              )}
            </div>

            <div className="mt-3">
              {loading ? (
                <PropertiesSkeleton />
              ) : (
                userProfileData.propertiesList && (
                  <>
                    <HeadingViewMore
                      bottomLines={false}
                      title="Property List"
                      listing="VIEW MORE PROPERTIES"
                      viewMoreTask={() =>
                        navigate("/properties-by-status/1", {
                          state: { propertyTitle: "Active" },
                        })
                      }
                    />
                    <Row>
                      {userProfileData.propertiesList.propertyList.map(
                        (propertyData: any) => (
                          <Col key={propertyData.propertyId} md={4} lg={3}>
                            <Property {...propertyData} />
                          </Col>
                        )
                      )}
                    </Row>
                  </>
                )
              )}
            </div>
            <div className="mt-3">
              {loading ? (
                <PostsSkeleton />
              ) : (
                userProfileData.postList && (
                  <>
                    <HeadingViewMore
                      bottomLines={false}
                      title="Post Listing"
                      listing="VIEW MORE POST"
                      viewMoreTask={() =>
                        navigate(`/user-posts/${loginData?.userDetails.id}`)
                      }
                    />
                    <Posts posts={userProfileData.postList.postList} />
                  </>
                )
              )}
            </div>
          </div>
        )}
      </Container>
    </UserProfileStyle>
  );
}

export default ViewUserProfile;

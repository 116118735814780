import Card from "react-bootstrap/Card";
import bedroomIcon from "../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../assets/icons/ic_property_area.svg";
import {
  BaseImgContainer,
  ImgContainer,
  MiniHeadingSecondary,
  primaryColor,
  ProfileMiniImg,
  secondaryBlue,
  tertiaryGrey20,
} from "../../components/GlobalStyle";
import { Button } from "react-bootstrap";
import unLikeIcon from "../../assets/icons/ic_newsfeed_like.svg";
import sent from "../../assets/icons/ic_newsfeed_sent.svg";
import comment from "../../assets/icons/ic_newsfeed_comment.svg";
import save from "../../assets/icons/ic_newsfeed_saved.svg";
import { SinglePostStyle } from "./style";
import CustomLink from "../../components/CustomLink/Index";
import moreOptionIcon from "../../assets/icons/ic_newsfeed_more.svg";
import AliceCarousel from "react-alice-carousel";
import { NewsFeedRecord } from "../../utils/types";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  follow_url,
  like_url,
  media_base_url,
} from "../../utils/api_urls";
import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import NewsfeedModal from "../../components/Modals/NewsFeedModal";
import CommentsModal from "../../components/Modals/Comments/CommentsModal";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/Modal/CustomModal";
import LikesModal from "../../components/Modals/LikesModal";
import { toast } from "react-toastify";
import getTimeAgo from "../../components/GetTimeAgo";
import MyPostOptions from "../../components/PostOptions/MyPost";
import { PostList2 } from "./types";
import like from "../../assets/icons/ic_story_like.svg";
import send from "../../assets/icons/ic_story_send.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import placeholder from "../../assets/icons/ic_use_placeholder.svg";
import ShowCommentsBtn from "../../components/Custom/ShowCommentsBtn";
import SlidePrevBtn from "../../components/Custom/SlidePrevBtn";
import SlideNextBtn from "../../components/Custom/SlideNextBtn";
import { useNewsfeedContext } from "../../context/NewsfeedContext";

// carosuel responseive
const responsive = {
  0: { items: 1 },
  //   568: { items: 3.2 },
  //   768: { items: 4.2 },
  //   1024: { items: 5.2 },
  //   1200: { items: 7.2 },
  //   1400: { items: 9.2 },
};

// post props

type singlePostProps = {
  postData: PostList2;
  isShowPost: boolean;
  setIsShowPost: Dispatch<SetStateAction<boolean>>;
};

const SinglePost: React.FC<singlePostProps> = ({
  postData,
  isShowPost,
  setIsShowPost,
}) => {
  const {
    createdDateTime,
    imagesURLS,
    description,
    address,
    videosURLS,
    hideLikesAndViewsCounts,
    contactRequestPermission,
    saveFavourite,
    readComments,
    turnOffCommenting,
    sharingEnabled,
    postId,

    lastLikeAndComment: {
      lastLikedUserId,
      lastCommentUserId,
      lastCommentUserFirstName,
      lastLikedUserLastName,
      lastCommentText,
      lastLikedUserFirstName,
      lastCommentUserProfilePicture,
      lastCommentUserLastName,
      totalLikes,
      totalComments,
      isLiked,
    },
    newsFeedId,
    ownerData: { firstName, lastName, profilePicture },
  } = postData;
  const [error, setError] = useState("");
  const [isShowOptions, setIsShowOptions] = useState(false);
  const userData = useAppSelector((state) => state.loginData.data?.userDetails);
  const [isShowComments, setIsShowComments] = useState(false);
  const { setShowSocialOptions, setShowPostOptions,setId,setpostId, setSelectedPost,selectedPost ,selectedStories,newsfeedData, selectedStory } =
    useNewsfeedContext();
  const [postItems, setPostItems] = useState<any>([]);
  const [postSelectedIndex, setPostSelectedIndex] = useState(0);

  // load post slides items
  const items = postItems;
  // checking whether item is video?
  console.log({ postItems, items });
  useEffect(() => {
    setPostSelectedIndex(0);
    setPostItems([
      ...imagesURLS.map((img, index) => (
        <div
          style={{
            height: "70vh",
            width: "100%",
          }}
        >
          <BaseImgContainer
            style={{
              objectFit: "cover",
              display: "block",
              width: "100%",
              height: "100%",
            }}
            key={index}
            alt={`post ${index}`}
            img_url={img}
          />
        </div>
      )),
    ]);
    if (videosURLS) {
      setPostItems([
        <video width="100%" height="300" controls>
          <source src={media_base_url + videosURLS} type="video/mp4" />
          Your browser does not support the video tag.
        </video>,
        ...postItems,
      ]);
    }
  }, [postData]);

  // getting loginData from redux
  const { data: loginData } = useAppSelector((state) => state.loginData);

  // follow api promsie handler
  const followPromiseHandler = async () => {
    try {
      const data: any = await axios.post(
        follow_url,
        {
          followToUserId: userData?.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      alert(data.responseMessage);
    } catch (err: any) {
      alert(err.response.data.responseMessage);
      setError(err.response.data.responseMessage);
    }
  };

  //add like types
  type addLikeTypes = {
    newsFeedId: number;
    userId: number;
    isLike: boolean;
  };

  return (
    <CustomModal
      isModalVisible={isShowPost}
      setIsModalVisible={setIsShowPost}
      onCancel={() => {}}
      width="100%"
    >
      <CustomModal
        isModalVisible={isShowOptions}
        setIsModalVisible={setIsShowOptions}
      >
        <MyPostOptions />
      </CustomModal>
      <CommentsModal
        isModalVisible={isShowComments}
        newsFeedId={newsFeedId}
        readComments={readComments}
        setIsModalVisible={setIsShowComments}
        title="Comments"
        turnOffCommenting={turnOffCommenting}
      />
      <SinglePostStyle>
        <div className="inner-section">
          <div className="post-owner-section d-flex justify-content-between align-align-items-center">
            <div className="d-flex">
              <ImgContainer
                src={
                  profilePicture ? media_base_url + profilePicture : placeholder
                }
                alt="profile"
                className="rounded-circle"
                height="50px"
                width="50px"
              />
              <div className="ms-3">
                <div className="d-flex">
                  <p className="name mb-0">
                    {firstName} {lastName}
                  </p>
                  <div className="ms-2" onClick={() => followPromiseHandler()}>
                    <CustomLink
                      color={secondaryBlue}
                      link="#"
                      textDecoration="none"
                      title="Follow"
                      fontSize="16px"
                      fontFamily="EnnVisionsMedium"
                    />
                  </div>
                </div>
                <p className="time mb-0">{getTimeAgo(createdDateTime)}</p>
              </div>
            </div>
            <img
              className="view-more-icon cursor-pointer align-self-start"
              onClick={() => {setIsShowOptions(true); setId(Number(newsFeedId));setpostId(postId)}}
              src={moreOptionIcon}
              alt="newFeed"
            />
          </div>
          <Card>
            <div className="position-relative">
              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                disableButtonsControls
                activeIndex={postSelectedIndex}
                disableDotsControls={items.length < 2 ? true : false}
              />
              {postSelectedIndex > 0 && (
                <SlidePrevBtn
                  clicked={() => setPostSelectedIndex((prev) => prev - 1)}
                />
              )}
              {imagesURLS.length > 1 &&
                postSelectedIndex < imagesURLS.length - 1 && (
                  <SlideNextBtn
                    clicked={() => setPostSelectedIndex((prev) => prev + 1)}
                  />
                )}
            </div>

            <Card.Body>
              <MiniHeadingSecondary>{description}</MiniHeadingSecondary>
              <ShowCommentsBtn
                firstName={firstName}
                lastName={lastName}
                onClick={() => setIsShowComments(true)}
                saveFavourite={saveFavourite}
                sharingEnabled={sharingEnabled}
                turnOffCommenting={turnOffCommenting}
              />
            </Card.Body>
          </Card>
        </div>
      </SinglePostStyle>
    </CustomModal>
  );
};

export default SinglePost;

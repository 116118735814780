import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { StoriesRecord, StoryDtolist } from "../screens/ExploreNearby/types";
import { NewsFeedRecord, newsFeedRecordsTypes } from "../utils/types";

type newsfeedContext = {
  isShowComments: boolean;
  setShowStoryComments: Dispatch<SetStateAction<boolean>>;
  showStoryComments: boolean;
  setIsShowComments: Dispatch<SetStateAction<boolean>>;
  isShowLikes: boolean;
  setIsShowLikes: Dispatch<SetStateAction<boolean>>;
  showSocialOptions: boolean;
  setShowSocialOptions: Dispatch<SetStateAction<boolean>>;
  showPostOptions: boolean;
  setShowPostOptions: Dispatch<SetStateAction<boolean>>;
  showStoryOptions: boolean;
  setShowStoryOptions: Dispatch<SetStateAction<boolean>>;
  selectedStories: StoriesRecord;
  setSelectedStories: Dispatch<SetStateAction<StoriesRecord>>;
  selectedStory: StoryDtolist;
  setSelectedStory: Dispatch<SetStateAction<StoryDtolist>>;
  selectedPost: NewsFeedRecord;
  setSelectedPost: Dispatch<SetStateAction<NewsFeedRecord>>;
  showPropertyOptions: boolean;
  setShowPropertyOptions: Dispatch<SetStateAction<boolean>>;
  isShowStories: boolean;
  setIsShowStories: Dispatch<SetStateAction<boolean>>;
  newsfeedData: newsFeedRecordsTypes;
  setNewsfeedData: Dispatch<SetStateAction<newsFeedRecordsTypes>>;
  setId: Dispatch<SetStateAction<number>>;
  id:number;
  setpostId: Dispatch<SetStateAction<number>>;
  postId:number;


};

export type professionType = {
  id: number;
  name: string;
};

const NewsfeedContext = createContext({} as newsfeedContext);
// professional context

const NewsfeedProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShowComments, setIsShowComments] = useState(false);
  const [showStoryComments, setShowStoryComments] = useState(false);
  const [isShowLikes, setIsShowLikes] = useState(false);
  const [showSocialOptions, setShowSocialOptions] = useState(false);
  const [showPostOptions, setShowPostOptions] = useState(false);
  const [selectedStories, setSelectedStories] = useState({} as StoriesRecord);
  const [selectedStory, setSelectedStory] = useState({} as StoryDtolist);
  const [showStoryOptions, setShowStoryOptions] = useState(false);
  const [selectedPost, setSelectedPost] = useState({} as NewsFeedRecord);
  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const [isShowStories, setIsShowStories] = useState(false);
  const [newsfeedData, setNewsfeedData] = useState({} as newsFeedRecordsTypes);
  const [id, setId] = useState<number>(0);
  const [postId, setpostId] = useState<number>(0);

  return (
    <NewsfeedContext.Provider
      value={{
        isShowComments,
        setIsShowComments,
        isShowLikes,
        setIsShowLikes,
        selectedPost,
        setSelectedPost,
        showSocialOptions,
        setShowSocialOptions,
        showPostOptions,
        setShowPostOptions,
        selectedStories,
        setSelectedStories,
        selectedStory,
        setSelectedStory,
        showStoryOptions,
        setShowStoryOptions,
        showPropertyOptions,
        setShowPropertyOptions,
        isShowStories,
        setIsShowStories,
        showStoryComments,
        setShowStoryComments,
        newsfeedData,
        setNewsfeedData,
        setId,
        id,
        postId,setpostId
      }}
    >
      {children}
    </NewsfeedContext.Provider>
  );
};

// make sure use
const useNewsfeedContext = () => {
  return useContext(NewsfeedContext);
};

export { useNewsfeedContext, NewsfeedProvider };

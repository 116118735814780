import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { admin_getAll_users_url } from "../../../utils/api_urls";
import { usersDataType } from "./types";
import axios from "axios";

type initialStateTypes = {
  loading: boolean;
  data: usersDataType;
  error: any;
};
const initialState: initialStateTypes = {
  loading: false,
  data: {} as usersDataType,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const getAllUsers = createAsyncThunk(
  "user/userAdmin/getAllUsers",
  async (page: number, thunkAPI) => {
    console.log({ page });
    const state: any = thunkAPI.getState();
    try {
      const { data } = await axios.post(
        admin_getAll_users_url + page,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              state.loginData && state.loginData.data.jwtDetails.token
            }`,
          },
        }
      );
      return data.results;
    } catch (error) {
      return thunkAPI.rejectWithValue("please url is wrong");
    }
  }
);

const getAllUsersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state, action: PayloadAction<usersDataType>) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.data = {
        users: [],
      };
      state.error = action.payload || "Something went wrong";
    });
  },
});

export default getAllUsersSlice.reducer;

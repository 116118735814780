import logo from "../../assets/icons/ic_logo.svg";
import AddAccountStyle from "./style";
const AddBankAccoun = () => {
  return (
    <AddAccountStyle>
      <img src={logo} alt="logo" />
      <h6 className="my-2">Under Maintenance</h6>
    </AddAccountStyle>
  );
};

export default AddBankAccoun;

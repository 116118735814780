import { useNavigate } from "react-router-dom";
import ErrorImg from "../../assets/images/error.svg";
import { Button } from "../../components/styles/Button";
import Page404Style from "./style";

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Page404Style>
      <img src={ErrorImg} alt="error-404-pic" />
      <Button onClick={() => navigate(-1)} className="btn">
        Go Back
      </Button>
    </Page404Style>
  );
};

export default Page404;

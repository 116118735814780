import styled from "styled-components";
import {
  fontFamilyBold,
  fontFamilyMedium,
  lightGrey2,
  primaryColor,
  primaryRed,
  tertiaryGrewish,
  whiteColor,
} from "../../components/GlobalStyle";

export const UserPostStyle = styled.div`
  margin-top: 20px;
`;
export const UserPropertiesStyle = styled.div`
  margin-top: 20px;
`;
export const UserStoriesStyle = styled.div`
  margin-top: 20px;
  .stories {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(5, 1fr);
  }
`;

type singleStoryProps = {
  height: string;
};
export const SingleStoryStyle = styled.div<singleStoryProps>`
  .story-card {
    border: 0.5px solid ${lightGrey2};
    border-radius: 4px;

    &:hover .overlay {
      /* opacity: 1; */
      cursor: pointer;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: rgba(0, 0, 0, 0.4);
      &-inner-container {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        .likes,
        .comments {
          font-family: ${fontFamilyBold};
          font-size: 16px;
        }
      }
    }

    .card-img-top,
    .status-text,
    .video-section {
      height: ${(props) => props.height};
      object-fit: cover;
      width: 100%;
      border-radius: 7px;
      word-break: break-all;
      padding: 0 10px;
    }

    .status-text {
      background: ${primaryColor};
      padding: 4px;
      word-break: break-all;
      padding: 0 10px;

      h6 {
        color: ${whiteColor};
        font-size: 14px;
        font-family: ${fontFamilyMedium};
        width: 98%;
        text-align: center;
      }
    }

    .card-body {
      padding-bottom: 4px;
      width: 100%;

      border-radius: 4px;
      .card-title {
        font-size: 14px;
        color: ${whiteColor};
        margin-bottom: 6px;
        font-family: ${fontFamilyMedium};
      }
    }
  }
`;

// single post style

export const SinglePostStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .alice-carousel__dots-item {
    width: 6px;
    height: 6px;
    &:not(.__custom):not(:last-child) {
      margin-right: 8px;
    }
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: 4%;
    width: 100%;
    z-index: 10;
  }
  .inner-section {
    background: ${whiteColor};
    border-radius: 10px;

    .post-owner-section {
      padding: 12px;
      .name {
        font-family: ${fontFamilyMedium};
        font-size: 16px;
      }
      .time {
        font-size: 14px;
      }
    }
    .card {
      box-shadow: 0px 0px 20px #00000012;
      border-radius: 3px;
      border: none;

      .property-description {
        border-bottom: 1px solid ${tertiaryGrewish};
        padding-bottom: 5px;
        .btn-primary {
          color: ${primaryColor};
          background-color: rgba(199, 17, 43, 0.12);
          font-size: 14px;
          border: none;
          @media screen and (max-width: 480px) {
            font-family: "EnnVisionsMedium";
          }
          /* &:hover {
              color: ${whiteColor};
              background-color: ${primaryColor};
            } */
        }
        .price {
          font-size: 18px;
          font-family: "EnnVisionsMedium";
          color: ${primaryColor};
        }
      }
      .property-details {
        margin-top: 10px;
        border-bottom: 1px solid ${tertiaryGrewish};
        padding-bottom: 10px;

        .img {
          vertical-align: super;
        }
        .text {
          margin-top: 3px;
          margin-left: 4px;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const StorySliderStyle = styled.section`
  height: 94vh;
  padding: 0 16px;
  .section-center {
    margin: 0 auto;
    width: 100%;
    /* have to have a height */
    height: 100vh;
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  .story-img {
    margin-bottom: 1rem;
    width: 150px;
    height: 100vh;
    object-fit: cover;
    border: 4px solid hsl(210, 31%, 80%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: hsl(21, 62%, 45%);
  }
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    color: black;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .prev:hover,
  .next:hover {
    background: hsl(21, 62%, 45%);
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
  }
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
`;

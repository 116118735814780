import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";

export interface InPersonDataTypes {
  propertyId: number;
  visitorId: number;
  visitType: string;
  description: string;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: string | null;
}
export interface virtualDataTypes {
  propertyId: number;
  visitorId: number;
  visitType: string;
  description: string;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: string;
}

type RequestTour = {
  inPersonData: InPersonDataTypes;
  setInPersonData: Dispatch<SetStateAction<InPersonDataTypes>>;
  virtualData: virtualDataTypes;
  setVirtualData: Dispatch<SetStateAction<virtualDataTypes>>;
};

const RequestTour = createContext({} as RequestTour);

const RequestTourProvider = ({ children }: { children: React.ReactNode }) => {
  const [inPersonData, setInPersonData] = useState({} as InPersonDataTypes);
  const [virtualData, setVirtualData] = useState({} as virtualDataTypes);
  return (
    <RequestTour.Provider
      value={{
        virtualData,
        setVirtualData,
        inPersonData,
        setInPersonData,
      }}
    >
      {children}
    </RequestTour.Provider>
  );
};

// make sure use
const useRequestTour = () => {
  return useContext(RequestTour);
};

export { useRequestTour, RequestTourProvider };

import styled from "styled-components";
import {
  fontFamilyMedium,
  lightDark,
  mediaDeviceMax,
  primaryColor,
  tertiaryGrewish,
  whiteColor,
} from "../GlobalStyle";

const PropertyStyle = styled.div`
  .card {
    border-radius: 10px;
    margin-top: 20px;
    border: none;
    box-shadow: 0px 0px 20px #00000012;
    &-body {
      text-align: left;
      padding: 10px;
    }
    .image-container {
      position: relative;
      width: 100%; /* Adjust width as needed */
      height: 0;
      padding-top: 66.67%; /*(2/3) * 100% = 66.67%*/
    }

    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
    }

    cursor: pointer;
    .card-img-top {
      object-fit: cover;
    }
    .sub-title {
      color: ${lightDark};
      font-size: 14px;
    }
    .property-description {
      border-bottom: 1px solid ${tertiaryGrewish};
      padding-bottom: 5px;
      .btn-primary {
        color: ${primaryColor};
        background-color: rgb(108 177 193 / 20%) !important;
        font-size: 14px;
        border-radius: 3px;
        border: none;
        font-family: ${fontFamilyMedium};
        text-transform: uppercase;
      }

      .price {
        font-size: 18px;
        font-family: "EnnVisionsMedium";
        color: ${primaryColor};
      }
    }
    .property-details {
      margin-top: 10px;
      img {
        @media ${mediaDeviceMax.tabletS} {
          height: 18px;
        }
      }
      .text {
        margin-top: 3px;
        margin-left: 4px;
        margin-bottom: 0;
      }
    }
  }
`;

export default PropertyStyle;

export const PropertyOptionsStyle = styled.div`
  width: 120px;
  background: #fcfbfb;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 0px 3px #00000029;
  p {
    font-family: ${fontFamilyMedium};
    margin-bottom: 0px;
    padding: 4px;
    &:not(:last-child) {
      border-bottom: 1px solid grey;
    }
    &:hover {
      cursor: pointer;
      background: rgba(35, 40, 55, 0.4);
      color: ${whiteColor};
    }
  }
`;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey9,
  primaryColor,
  tertiaryGrey7,
} from "../../components/GlobalStyle";
import Head from "../../components/Head/Head";

type termsAndConditionsProps = {
  terms: boolean;
  setTerms: React.Dispatch<React.SetStateAction<boolean>>;
  showTermsError: boolean;
};
const TermsAndConditions: React.FC<termsAndConditionsProps> = ({
  terms,
  setTerms,
  showTermsError,
}) => {
  return (
    <>
  
    <Wrapper>
      <div className="d-flex alig-items-center mt-3">
        <span className="me-1">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
          />
        </span>

        <label
          htmlFor="terms"
          className="terms text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
        >
          <span>
            I Agreed with
            <Link to="/terms" target="_blank" className="me-1">
              Terms and Conditions
            </Link>
            &
            <Link className="ms-1" to="/privacy" target="_blank">
              Privacy Policy
            </Link>
          </span>
        </label>
      </div>
      <p className="text-danger text-center">
        {showTermsError && "Please Accept terms and conditions"}
      </p>
    </Wrapper>
    </>
  );
};

export default TermsAndConditions;

const Wrapper = styled.div`
  .terms {
    max-width: 390px;
    width: 98%;
    span {
      font-size: 14px;
      &:first-child {
        color: ${lightGrey9};
      }
      &:last-child {
        color: ${tertiaryGrey7};
      }
    }
  }
  a {
    font-family: ${fontFamilyMedium};
    text-decoration: none;
    color: ${primaryColor};
    margin: 0 2px;
  }
`;

import { Col, Container, Row } from "react-bootstrap";
import {
  greenishColor,
  lightGrey,
  primaryColor,
  primaryRed,
  SecondaryHeadingMini,
  tertiaryBlue1,
  tertiaryGrey7,
  TertiraryHeading1,
  TertiraryHeadingMini,
  TetraHeading,
  whiteColor,
} from "../../../components/GlobalStyle";
import PropertyDetailsStyle, { DetailBoxStyle, PhoneModalStyle } from "./style";
import bedroomIcon from "../../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../../assets/icons/ic_property_area.svg";
import qualityIcon from "../../../assets/icons/ic_property_detail_repair.svg";
import statusIcon from "../../../assets/icons/ic_property_detail_features.svg";
import availabeBuildingIcon from "../../../assets/icons/ic_professional_post.svg";
import soldPropertyIcon from "../../../assets/icons/ic_property_detail_sold.svg";
import redTickIcon from "../../../assets/icons/ic_select_red.svg";
import lightTickIcon from "../../../assets/icons/ic_property_detail_status.svg";
import axios from "axios";
import {
  authorizationToken,
  media_base_url,
  property_details_url,
} from "../../../utils/api_urls";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { PropertyDetailTypes } from "../types";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { UpOutlined } from "@ant-design/icons";
import chatIcon from "../../../assets/icons/ic_property_detail_chat.svg";
import { BackTop } from "antd";
import Head from "../../../components/Head/Head";
import ItemInfo from "../../../components/ItemInfo/ItemInfo";
import { RootState } from "../../../redux/store";
import { facingsData } from "../../CreateProperty/CreateProperty";
import place_holder from "../../../assets/icons/ic_profile_placeholder.svg";
import MyMapComponent from "./CustomMarker";
import getTimeAgo, { FormattedDate } from "../../../components/GetTimeAgo";
import RequestTour from "../../../test_components/RequestTour/RequestTour";
import callIcon from "../../../assets/icons/ic_call.svg";
import phoneIcon from "../../../assets/icons/ic_owner_number.svg";
import PropertyDetailsSkeleton from "../../../components/Skeletons/PropertyDetails";
import CustomModal from "../../../components/Modal/CustomModal";
import ImageGallery from "react-image-gallery";
import close from "../../../assets/icons/ic_story_close.svg";
import AliceCarousel from "react-alice-carousel";
import { BgImage } from "../../ExploreNearby/style";
import SlidePrevBtn from "../../../components/Custom/SlidePrevBtn";
import SlideNextBtn from "../../../components/Custom/SlideNextBtn";
import { numberToLocalString } from "../../../utils/utilities";
import { useSelector } from "react-redux";
import in_person from "../../../assets/icons/ic_professional_meet_peron.svg";

const PropertyDetails = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const myRef = useRef<HTMLDivElement>(null);
  const [showRequestTour, setShowRequestTour] = useState(false);
  const { property } = useAppSelector((state) => state.appData.data.statusList);
  const { professions } = useAppSelector((state) => state.appData.data);
  const [showImgsGalleryVisible, setShowImgsGalleryVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { serverTimeZone } = useSelector((state: RootState) => state.appData.data);

  // show request tour section when react to owner details
  useEffect(() => {
    function handleScroll() {
      const ownerDetailElement = myRef.current;

      if (ownerDetailElement) {
        const requestTourElement = ownerDetailElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the element is fully in view
        if (
          requestTourElement.top >= 0 &&
          requestTourElement.bottom <= windowHeight
        ) {
          setShowRequestTour(true);
        } else {
          setShowRequestTour(false);
        }
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      setShowRequestTour(false);
    };
  }, [myRef]);
  console.log({ showRequestTour });

  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(null);
  const { data: loginData } = useAppSelector(
    (state: RootState) => state.loginData
  );
  console.log(">>dataa", loginData);
  const [propertyDetailData, setPropertyDetailData] = useState(
    {} as PropertyDetailTypes
  );
  const { propertyAppData, propertyRoomName } = useAppSelector(
    (state: RootState) => state.appData.data
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const [propertyImgIndex, setPropertyImgIndex] = useState(0);

  // property details
  const getPropertyDetailsPromise = async () => {
    try {
      setIsLoading(true);
      document.body.style.overflow = "hidden";

      const { data } = await axios.post(
        property_details_url,
        {
          propertyId: id!,
        }
        // {
        //   headers: {
        //     ...authorizationToken(loginData!),
        //   },
        // }
      );
      console.log(data, "porpery details");
      setPropertyDetailData(data.results);
      setIsLoading(false);
      document.body.style.overflow = "auto";
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      document.body.style.overflow = "auto";

      setError(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  console.log(propertyDetailData, "propertyTypeId");

  let propertySizeTypes: {
    keyName: string;
    id: number;
    displayName: string;
  }[] = [];

  if (propertyDetailData?.propertyData?.propertyTypeId) {
    propertySizeTypes =
      propertyAppData[propertyDetailData?.propertyData?.propertyTypeId - 1 || 0]
        .propertySizeDTOS;
  }
  console.log({ propertySizeTypes });

  useEffect(() => {
    // property sizes from appdata
    getPropertyDetailsPromise();
  }, [id]);

  if (isLoading)
    return (
      <Container className="my-4">
        <PropertyDetailsSkeleton />
      </Container>
    );

  if (error) return <NoDataAvailable title={error} />;
  const { propertyData } = propertyDetailData;

  const {
    ownerData: {
      // countryCode,
      firstName,
      lastName,
      profilePicture,
      totalActiveProperties,
      totalSoldProperties,
      userName,
      id: ownerId,
      phoneNumber,
    },
    propertyData: {
      title,
      purpose,
      description,
      unparsedAddress = "",
      city,
      price,
      imageURLS,
      newsFeedId,
      propertyStatusId,
      propertyTypeId,

      propertySize: { keyName, size },
      propertyUniqueOptions,
      propertyExtraOptions,
      propertyServices,
      facingAndFlorInfo,
      createdDateTime,
      groundFloorInfo,
      firstFloorInfo,
      secondFloorInfo,
      thirdFloorInfo,
      propertyGeneralInfo: {
        others: {
          fullBathrooms,
          fullBedrooms,
          fullStory,
          halfBathrooms,
          partialStory,
        },
      },
    },
  } = propertyDetailData;

  // address data
  const addressData = [
    {
      heading: "Unparsed Address",
      title: propertyData?.unparsedAddress || "",
    },
    {
      heading: "Street Number",
      title: propertyData?.streetNumber || "",
    },
    {
      heading: "Street Type",
      title: propertyData?.streetType || "",
    },
    {
      heading: "Street Direction",
      title: propertyData?.streetDirection || "",
    },
    {
      heading: "Municipality",
      title: propertyData?.municipality || "",
    },
    // {
    //   heading: "Email",
    //   title: propertyData?.email||"",
    // },
    {
      heading: "Province",
      title: propertyData?.province || "",
    },
    {
      heading: "Lat, Log",
      title: propertyData?.latitude + " " + propertyData?.longitude || "",
    },
  ];

  // property deta
  const propertyOptionsData = [
    {
      heading: "Property Code",
      title: "",
    },
    {
      heading: "Legal Description",
      title: "",
    },
    {
      heading: "Actual Frontage",
      title: "",
    },
    {
      heading: "Actual Frontage",
      title: "",
    },
    {
      heading: "Actual Lot Size",
      title: "",
    },
    {
      heading: "Actual Lot Size Uom",
      title: "",
    },
    {
      heading: "Hydro Service Code",
      title: "",
    },
    {
      heading: "Hydro Service Code",
      title: "",
    },
    {
      heading: "Sanitary Service Code",
      title: "",
    },
    {
      heading: "Water Service Description",
      title: "",
    },
  ];

  // primary residence structure
  const primaryResidentialData = [
    {
      heading: "Primary",
      title: "",
    },
    {
      heading: "Structure Number",
      title: "",
    },
    {
      heading: "Structure Code",
      title: "",
    },
    {
      heading: "Structure Description",
      title: "",
    },
    {
      heading: "Actual Year Built",
      title: propertyData?.primaryResidence?.actualYear || "",
    },
    {
      heading: "Effective Year Built",
      title: propertyData?.primaryResidence?.effectiveYear,
    },
    {
      heading: "Air Conditioning Flag",
      title: "",
    },
    {
      heading: "Primary Floors Area 1",
      title: propertyData?.primaryResidence?.firstFloorArea || "",
    },
    {
      heading: "Primary Floors Area 2",
      title: propertyData?.primaryResidence?.secondFloorArea || "",
    },
    {
      heading: "Primary Floors Area 3",
      title: propertyData?.primaryResidence?.thirdFloorArea || "",
    },
    {
      heading: "Heating System Type Code",
      title: "",
    },
    {
      heading: "Heating System Type Description",
      title: "",
    },
    {
      heading: "Finished Basement Area",
      title: propertyData?.primaryResidence?.basementArea,
    },
    {
      heading: "Structure Condition Code",
      title: "",
    },
    {
      heading: "Total Fire Places",
      title: "",
    },
    {
      heading: "basement Height Area",
      title: "",
    },
    {
      heading: "Part Storeys Code",
      title: "",
    },
    {
      heading: "Total Basement Area",
      title: "",
    },
    {
      heading: "Total Bedrooms Count",
      title: "",
    },
    {
      heading: "Total Bedrooms",
      title: "",
    },
    {
      heading: "Total Full Baths",
      title: "",
    },
    {
      heading: "Total Half Baths Count",
      title: "",
    },
    {
      heading: "Total Full Storey",
      title: "",
    },
    {
      heading: "Total Floor Area",
      title: "",
    },
    {
      heading: "Split Level Type Code",
      title: "",
    },
    {
      heading: "Renovation Year",
      title: "",
    },
    {
      heading: "Split Level Type Code",
      title: "",
    },
    {
      heading: "Renovation Year",
      title: propertyData?.primaryResidence?.renovationYear,
    },
  ];
  // garage structure data
  const garageStructureData = [
    {
      heading: "Structure Number",
      title: "",
    },
    {
      heading: "Structure Code",
      title: "",
    },
    {
      heading: "Structure Description",
      title: propertyData?.garageStructure?.garageDescription,
    },
    {
      heading: "Actual Year Built",
      title: propertyData?.garageStructure?.actualYear,
    },
    {
      heading: "Garage Type Code",
      title: "",
    },
    {
      heading: "Total Floor Area",
      title: "",
    },
    {
      heading: "Garage Type Description",
      title: "",
    },
    {
      heading: "Total Garage Spaces Count",
      title: "",
    },
    {
      heading: "Renovation Year",
      title: propertyData?.garageStructure?.renovationYear,
    },
    {
      heading: "Sanitary Service Description",
      title: "",
    },
    {
      heading: "Water Service Code",
      title: "",
    },
    {
      heading: "Water Service Description",
      title: "",
    },
  ];

  const propertyDetailsData = [
    {
      name: "Bedrooms",
      img: bedroomIcon,
      mini_name: "Bed",
      quantity: fullBedrooms || null,
    },
    {
      name: "Bathrooms",
      mini_name: "Bath",
      img: bathIcon,
      quantity: fullBathrooms || null,
    },
    {
      name: "Square Area",
      mini_name: "Area",
      img: areaIcon,
      quantity:
        size ||
        "" +
        " " +
        propertySizeTypes.find(
          (propertySize) => propertySize.keyName === keyName
        )?.displayName,
    },
    {
      name: "Status",
      mini_name: "Status",
      img: statusIcon,
      quantity:
        property.find(({ id }) => propertyStatusId === id)?.name || null,
    },
  ];

  console.table(primaryResidentialData);
  console.log({
    createdDateTime,
    propertyOptionsData,
    propertyExtraOptions,
    propertyUniqueOptions,
    propertyServices,
    facingAndFlorInfo,
  });

  // on map load

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
    // Replace the following line with your custom latitude and longitude values
    const customLatLng = new google.maps.LatLng(51.5074, 0.1278); // London, UK
    setMarkerPosition(customLatLng);
    map?.setCenter(customLatLng);
    map?.setZoom(10);
  };

  // got selected property type options
  const gotSelectedProperty = propertyAppData[propertyTypeId - 1];

  // formatted extra options
  const formattedExtraOptions = [];
  // formatted service|utilties options
  const formattedServiceOptions = [];

  // formatted service|utilties options
  const formattedUniqueOptions: {
    displayName: string;
    value: number | string;
  }[] = [];

  if (gotSelectedProperty) {
    console.log({ gotSelectedProperty });
    // formatted extra options
    if (
      propertyExtraOptions &&
      Object.keys(propertyExtraOptions).length !== 0
    ) {
      for (const [key, value] of Object.entries(propertyExtraOptions)) {
        const foundedOptions = gotSelectedProperty.propertyExtraOptionDTOS.find(
          ({ keyName }: { keyName: string }) => keyName === key
        );
        if (foundedOptions) {
          formattedExtraOptions.push({
            displayName: foundedOptions.displayName,
            value,
          });
        }
      }
    }
    // formatted service|utilties options

    if (propertyServices && Object.keys(propertyServices).length !== 0) {
      for (const [key, value] of Object.entries(propertyServices)) {
        const foundedOptions = gotSelectedProperty.propertyServicesDTOS.find(
          ({ keyName }: { keyName: string }) => keyName === key
        );
        console.log({ key, value });
        if (foundedOptions) {
          formattedServiceOptions.push({
            displayName: foundedOptions.displayName,
            value,
          });
        }
      }
    }
    // formatted service|utilties options

    if (
      propertyUniqueOptions &&
      Object.keys(propertyUniqueOptions).length !== 0
    ) {
      for (const [key, value] of Object.entries(propertyUniqueOptions)) {
        const foundedOptions =
          gotSelectedProperty.propertyUniqueOptionDTOS.find(
            ({ keyName }: { keyName: string }) => keyName === key
          );
        console.log({ key, value });
        if (foundedOptions) {
          formattedUniqueOptions.push({
            displayName: foundedOptions.displayName,
            value,
          });
        }
      }
    }
  }

  console.log({ formattedUniqueOptions });

  console.log({ facingsData });

  // property slides items
  const items = [
    ...imageURLS.map((img) => (
      <BgImage>
        <div className="post position-relative">
          <div className="post-image">
            <img src={media_base_url + img} alt="post" />
          </div>
        </div>
      </BgImage>
    )),
  ];

  // carosuel responseive
  const responsive = {
    0: { items: 1 },
    //   568: { items: 3.2 },
    //   768: { items: 4.2 },
    //   1024: { items: 5.2 },
    //   1200: { items: 7.2 },
    //   1400: { items: 9.2 },
  };
  // carosuel responseive
  const propertyDetailsResponsive = {
    0: { items: 2 },
    440: { items: 3 },
    //   568: { items: 3.2 },
    //   768: { items: 4.2 },
    //   1024: { items: 5.2 },
    //   1200: { items: 7.2 },
    //   1400: { items: 9.2 },
  };

  const dateTime = FormattedDate(createdDateTime, serverTimeZone)
  return (
    <PropertyDetailsStyle>
      <Head title={`property-details/${id}`} />
      <BackTop>
        <div
          style={{
            backgroundColor: tertiaryBlue1,
            borderRadius: "50%",
            padding: "8px",
          }}
        >
          {/* <img src={scroll_up} alt="scroll-up" /> */}
          <UpOutlined style={{ color: whiteColor, fontSize: "24px" }} />
        </div>
      </BackTop>

      <CustomModal
        isModalVisible={showPhoneNumber}
        setIsModalVisible={setShowPhoneNumber}
        showCloseBtn={false}
        width="100%"
      >
        <PhoneModalStyle>
          <div className="inner-container">
            <img src={phoneIcon} alt="phone" />
            <h6 className="mt-2">
              {loginData?.userDetails?.countryCode} {phoneNumber}
            </h6>
            <p className="mb-0">Please call me at the number provided.</p>
            <div className="dismiss-btn w-75 mt-2" />
            <CustomButton
              bgcolor="transparent"
              color={primaryColor}
              padding="8px"
              width="160px"
              textTransform="capitalize"
              type="button"
              title="Dismiss"
              margin="auto"
              fontSize="18px"
              clicked={() => setShowPhoneNumber(false)}
            />
          </div>
        </PhoneModalStyle>
      </CustomModal>
      <CustomModal
        isModalVisible={showImgsGalleryVisible}
        setIsModalVisible={setShowImgsGalleryVisible}
        showCloseBtn={false}
        width="100%"
      >
        <div
          style={{
            background: lightGrey,
          }}
        >
          <img
            className="close-icon"
            onClick={() => {
              setShowImgsGalleryVisible(false);
            }}
            src={close}
            alt="close"
          />
          <ImageGallery
            items={imageURLS.map((img) => ({
              original: media_base_url + img,
              thumbnail: media_base_url + img,
            }))}
            showPlayButton={false}
            startIndex={currentIndex}
            showIndex
          />
          ;
        </div>
      </CustomModal>

      <Container className="my-4">
        {error ? (
          <NoDataAvailable title={error} />
        ) : (
          <>
            {loginData && showRequestTour && (
              <div className="request-tour-section mt-4">
                <RequestTour newsFeedId={newsFeedId} />
              </div>
            )}
            {/* {state?.isEdit && (
            <div className="d-flex justify-content-end my-2">
              <CustomButton
                bgcolor={primaryColor}
                color={whiteColor}
                padding="8px"
                width="160px"
                textTransform="capitalize"
                type="button"
                title="Edit"
                margin="auto"
                fontSize="16px"
                clicked={() => navigate(`/edit-property/${id}`)}
              />
            </div>
          )} */}

            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
              <SecondaryHeadingMini className="mb-0 mb-sm-auto">
                {title}
              </SecondaryHeadingMini>
              <TertiraryHeading1 className="mt-2 d-sm-none">
                {unparsedAddress}
              </TertiraryHeading1>
              <div className="d-flex gap-2 align-items-center">
             {loginData?.jwtDetails.token ?    <button style={{
                  border: ' 1px solid rgb(0, 176, 233)',
                  background:"rgb(0, 176, 233)",
                  color:'white',
                  padding:"4px 12px",
                    margin:"auto",
                    fontSize:"14px",

                }}
                 className="d-flex justify-content-center align-items-center gap-1 tour-btn1 btn w-100"
                 onClick={() =>
                  navigate("/in-person", {
                    state: {
                      newsFeedId,
                      visitType: "IN_PERSON",
                    },
                  })
                }>

              
                   
                  
             
                    CONTACT REQUEST
              </button>: null}
                <div className="d-none d-sm-block">
                  <CustomButton
                    bgcolor="rgba(108, 177, 193, 0.2)"
                    color={primaryColor}
                    padding="4px"
                    width="100px"
                    type="button"
                    title={`For ${purpose}`}
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    fontSize="14px"
                  />
                </div>

              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-sm-none">
                <CustomButton
                  bgcolor="rgba(108, 177, 193, 0.2)"
                  color={primaryColor}
                  padding="4px"
                  width="100px"
                  type="button"
                  title={`For ${purpose}`}
                  margin="auto"
                  fontFamily="EnnVisionsMedium"
                  fontSize="14px"
                />
              </div>
              <div className="d-none d-sm-block">
                <TertiraryHeading1
                  className="mt-2"
                  style={{ fontSize: "16px" }}
                >
                  {unparsedAddress}
                </TertiraryHeading1>
              </div>
              <h4 className="property-price  mb-0">
                ${price && numberToLocalString(+price)}
              </h4>
            </div>
            {imageURLS.length !== 0 && (
              <div
                className="imgs-gallery"
                style={{
                  zIndex: "12",
                  position: "relative",
                }}
              >
                <div className="position-relative d-sm-none">
                  <AliceCarousel
                    mouseTracking
                    items={items}
                    activeIndex={propertyImgIndex}
                    responsive={responsive}
                    controlsStrategy="alternate"
                    disableButtonsControls
                    disableDotsControls={items.length < 2}
                  />
                  {propertyImgIndex > 0 && (
                    <SlidePrevBtn
                      clicked={() => setPropertyImgIndex((prev) => prev - 1)}
                    />
                  )}
                  {imageURLS.length > 1 &&
                    propertyImgIndex < imageURLS.length - 1 && (
                      <SlideNextBtn
                        clicked={() => setPropertyImgIndex((prev) => prev + 1)}
                      />
                    )}
                </div>
                <Row className="m-0 d-none d-sm-flex">
                  <Col sm={6} lg={8} className="p-0 pe-sm-2">
                    <img
                      src={media_base_url + imageURLS[0]}
                      alt="property-pic-1"
                      className="img-large w-100 cursor-pointer"
                      onClick={() => {
                        setCurrentIndex(0);
                        setShowImgsGalleryVisible(true);
                      }}
                    />
                  </Col>
                  <Col sm={6} lg={4} className="p-0 position-relative">
                    {imageURLS.slice(1, 3).map((img, index) => (
                      <div className="position-relative" key={index}>
                        <img
                          src={media_base_url + img}
                          alt={"property2" + index}
                          className={`w-100 cursor-pointer ${index}`}
                          onClick={() => {
                            setCurrentIndex(index + 1);
                            setShowImgsGalleryVisible(true);
                          }}
                        />
                        {index > 0 && imageURLS.length > 3 && (
                          <div id="overlay" className="mt-2" />
                        )}
                      </div>
                    ))}

                    {imageURLS.length > 3 && (
                      <h6
                        onClick={() => setShowImgsGalleryVisible(true)}
                        className="see-all position-absolute"
                      >
                        See all photos
                      </h6>
                    )}
                  </Col>
                </Row>
              </div>
            )}
            {/* property details */}
            <div className="d-sm-none carousel-property">
              <AliceCarousel
                mouseTracking
                items={propertyDetailsData.map(
                  ({ name, img, quantity, mini_name }, index) => (
                    <DetailBoxStyle>
                      <div key={index} className="property-box">
                        <div className="property-box-inner">
                          <p className="name mb-0 text-center d-none d-sm-block text-sm-start">
                            {name}
                          </p>
                          <div className="d-flex flex-column flex-sm-row mt-1 align-items-center">
                            <img src={img} alt="bedroom" />
                            <div className="d-flex align-items-center gap-1 mt-2">
                              {name !== "Square Area" && (
                                <p className="quantity mb-0 ms-0 ms-sm-2 text-center text-sm-start">
                                  {quantity}
                                </p>
                              )}
                              <p className="d-sm-none mb-0 name"> {name}</p>
                            </div>
                            {name === "Square Area" && (
                              <p className="quantity mb-0 ms-0 mt-2 ms-sm-2 text-center text-sm-start">
                                <span className="quantity me-1">
                                  {quantity}
                                </span>
                                {
                                  propertySizeTypes.find(
                                    (propertySize) =>
                                      propertySize.keyName === keyName
                                  )?.displayName
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </DetailBoxStyle>
                  )
                )}
                responsive={propertyDetailsResponsive}
                controlsStrategy="alternate"
                disableButtonsControls
                disableDotsControls
              />
            </div>
            <div className="d-none d-sm-block">
              <div className="property">
                {propertyDetailsData.map(
                  ({ name, img, quantity, mini_name }, index) => (
                    <div key={index} className="property-box">
                      <div className="property-box-inner">
                        <p className="name mb-0 text-center d-none d-sm-block text-sm-start">
                          {name}
                        </p>
                        <div className="d-flex flex-column flex-sm-row mt-1 align-items-center">
                          <img src={img} alt="bedroom" />
                          <p className="quantity mb-0 ms-0 mt-2 mt-sm-0 ms-sm-2 text-center text-sm-start">
                            {quantity}
                            <span className="d-sm-none"> {mini_name}</span>
                          </p>
                          {name === "Square Area" && (
                            <p className="quantity mb-0 ms-0 mt-2 mt-sm-0 ms-sm-2 text-center text-sm-start">
                              {
                                propertySizeTypes.find(
                                  (propertySize) =>
                                    propertySize.keyName === keyName
                                )?.displayName
                              }
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="owner-details">
              <p className="listed-by">Listed by Property Owner</p>
              <Row className="inner-section">
                <Col md={6} className="mt-3">
                  <div className="d-flex">
                    {profilePicture ? (
                      <img
                        src={media_base_url + profilePicture}
                        alt={firstName}
                        className="profile-img"
                      />
                    ) : (
                      <img
                        src={place_holder}
                        alt="placeholder"
                        className="profile-img"
                      />
                    )}

                    <div className="bio ms-3 mt-1">
                      <h6>
                        {firstName} {lastName}
                      </h6>
                      <p className="branch my-1">{userName}</p>
                      <p
                        className="location"
                        onClick={() => setShowPhoneNumber(true)}
                        style={{ cursor: "pointer" }}
                      >
                        {loginData?.userDetails?.countryCode}{" "}
                        {loginData?.userDetails?.phoneNumber}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mt-3 mt-md-0">
                  <div className="sold-properties">
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <div className="properties">
                          <p className="title text-center text-md-start">
                            Sold Properties
                          </p>
                          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                            <span style={{ marginLeft: "10px" }}>
                              <img
                                className="property-icon"
                                src={soldPropertyIcon}
                                alt="buildings"
                              />
                            </span>
                            <p className="quantity ms-2 mb-0">
                              {totalSoldProperties}
                            </p>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-center mt-3">
                          <button
                            className="chat d-flex justify-content-center"
                            onClick={() => navigate("/in-person")}
                          >
                            <img
                              className="me-2 mb-1"
                              src={chatIcon}
                              alt="chat-icon"
                            />
                            Start Chat
                          </button>
                        </div> */}
                      </Col>
                      <Col xs={6} className="">
                        <div className="properties">
                          <p className="title text-center text-md-start">
                            Available Properties
                          </p>
                          <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                            <span style={{ marginLeft: "10px" }}>
                              <img
                                className="property-icon"
                                src={availabeBuildingIcon}
                                alt="building"
                              />
                            </span>
                            <p className="quantity ms-2 mb-0">
                              {totalActiveProperties}
                            </p>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-center mt-3">
                          <button
                            className="call-btn d-flex justify-content-center align-items-center"
                            onClick={() => setShowPhoneNumber(true)}
                          >
                            <img
                              className="me-2 mb-1"
                              src={callIcon}
                              alt="call-icon"
                            />
                            Call
                          </button>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <h6 className="about-property-title">About This Property</h6>
            <div ref={myRef} className="about-property">
              <div className="about-property-main-details" ref={myRef}>
                <div className="posted-at d-flex">
                  <p className="posted-at-text">Posted on</p>
                  <p className="posted-at-date ms-2">
                    {dateTime}
                  </p>
                </div>
                <p className="posted-at-area mb-1">
                  {/* {size && size + " " + keyName && keyName} */}
                  House for {purpose} in {city}
                </p>
                <p className="posted-at-detail-text">{description}</p>
              </div>
              {/* <div>
              <h6 className="detail-heading">Address</h6>
              <Row>
                {addressData.map(
                  ({ heading, title }) =>
                    title && (
                      <Col sm={6}>
                        <ItemInfo heading={heading} title={title} />
                      </Col>
                    )
                )}
                <Col>
                  <ItemInfo heading="Complete Address" title="a" />
                </Col>
              </Row>
             </div>
              <div className="mt-3">
              <h6 className="detail-heading">Property</h6>
              <Row>
                {propertyOptionsData.map(
                  ({ heading, title }) =>
                    title && (
                      <Col sm={6}>
                        <ItemInfo heading={heading} title={title} />
                      </Col>
                    )
                )}
              </Row>
              </div> */}
              <div className="primary-residentail mt-3">
                <Row>
                  {primaryResidentialData.filter(({ title }) => title)
                    .length !== 0 ? (
                    <>
                      <h6 className="detail-heading">
                        Primary Residential Structures
                      </h6>
                      {primaryResidentialData.map(
                        ({ heading, title }) =>
                          title && (
                            <Col sm={6}>
                              <ItemInfo heading={heading} title={title} />
                            </Col>
                          )
                      )}
                    </>
                  ) : null}
                </Row>
              </div>
              <div className="garage-structure mt-3">
                {primaryResidentialData.filter(({ title }) => title).length !==
                  0 ? (
                  <>
                    <h6 className="detail-heading">Garage Structures</h6>
                    <Row>
                      {garageStructureData.map(
                        ({ heading, title }) =>
                          title && (
                            <Col sm={6}>
                              <ItemInfo heading={heading} title={title} />
                            </Col>
                          )
                      )}
                    </Row>
                  </>
                ) : null}
              </div>
              {formattedExtraOptions.length !== 0 && (
                <div className="key-features mt-3">
                  <p className="key-features-title">Key features:</p>
                  <div className="details">
                    <Row>
                      {formattedExtraOptions.map(
                        (
                          {
                            displayName,
                            value,
                          }: { displayName: string; value: string | number },
                          index
                        ) => (
                          <Col xs={6}>
                            <div key={index} className="d-flex feature mt-3">
                              <img src={redTickIcon} alt="check-icon" />
                              <p className="text ms-2 mb-0">
                                {`${value}  ${displayName}`}
                              </p>
                            </div>
                          </Col>
                        )
                      )}
                    </Row>
                  </div>
                </div>
              )}
              {formattedUniqueOptions.length !== 0 && (
                <div className="features-container mt-3">
                  <p className="detail-heading mb-0">Unique Options</p>
                  <Row className="mb-2">
                    {formattedUniqueOptions.map(
                      (
                        {
                          displayName,
                        }: {
                          displayName: string;
                        },
                        index
                      ) => (
                        <Col key={index} xs={6} md={4}>
                          <div className="feature d-flex align-items-center gap-2">
                            <span>
                              <img src={lightTickIcon} alt="check-icon" />
                            </span>
                            <p className="feature-text mb-0 ms-2">
                              {displayName}
                            </p>
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              )}
              {formattedUniqueOptions.length !== 0 && (
                <div className="features-container mt-3">
                  <h6 className="detail-heading mb-0">Utilities</h6>
                  <Row className="mb-2">
                    {formattedServiceOptions.map(
                      (
                        {
                          displayName,
                          value,
                        }: { displayName: string; value: string | number },
                        index
                      ) => (
                        <Col key={index} xs={6} md={4}>
                          <div className="feature d-flex align-items-center gap-2">
                            <span>
                              <img src={lightTickIcon} alt="check-icon" />
                            </span>
                            <p className="feature-text mb-0 ms-2">
                              {displayName}
                            </p>
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              )}

              {facingsData.filter(
                ({ abbre }) => abbre === facingAndFlorInfo.direction
              ).length !== 0 && (
                  <div className="features-container mt-3">
                    <h6 className="detail-heading mb-0">Facing</h6>
                    <Row className="mb-2">
                      {facingsData.map(
                        ({ title, abbre }, index) =>
                          abbre === facingAndFlorInfo.direction && (
                            <Col key={index} sm={6} md={4}>
                              <div className="feature d-flex align-items-center gap-2">
                                <span>
                                  <img src={lightTickIcon} alt="check-icon" />
                                </span>
                                <p className="feature-text mb-0 ms-2">{title}</p>
                              </div>
                            </Col>
                          )
                      )}
                    </Row>
                  </div>
                )}
              {(groundFloorInfo?.length > 0 ||
                firstFloorInfo?.length > 0 ||
                secondFloorInfo?.length > 0) && (
                  <div className="rooms-container mt-3">
                    <h6 className="rooms-container-heading mb-0">Room Details</h6>
                    <Row>
                      {groundFloorInfo?.length > 1 && (
                        <Col sm={6} className="mt-3">
                          <h6 className="rooms-container-subheading">
                            Ground Floor
                          </h6>
                          {groundFloorInfo.map(
                            ({ name, length, width }, index) =>
                              propertyRoomName.find(
                                ({ keyName }) => keyName === name
                              ) && (
                                <div key={index}>
                                  <div className="feature d-flex align-items-center gap-2">
                                    <span>
                                      <TetraHeading className="mb-0 feature-heading">
                                        {
                                          propertyRoomName.find(
                                            ({ keyName }) => keyName === name
                                          )?.displayName
                                        }
                                        :
                                      </TetraHeading>
                                    </span>
                                    {length?.feet ||
                                      length?.inch ||
                                      width?.feet ||
                                      width?.inch ? (
                                      <p
                                        className="feature-text mb-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {`${length?.feet || 0} ' ${length?.inch || 0
                                          }'' x ${width?.feet || 0} ' ${width?.inch || 0
                                          }''`}
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        Measurement not Available
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </Col>
                      )}
                      {firstFloorInfo?.length > 1 && (
                        <Col sm={6} className="mt-3">
                          <h6 className="rooms-container-subheading">
                            First Floor
                          </h6>
                          {firstFloorInfo.map(
                            ({ name, length, width }, index) =>
                              propertyRoomName.find(
                                ({ keyName }) => keyName === name
                              ) && (
                                <div key={index}>
                                  <div className="feature d-flex align-items-center gap-2">
                                    <span>
                                      <TetraHeading className="mb-0 feature-heading">
                                        {
                                          propertyRoomName.find(
                                            ({ keyName }) => keyName === name
                                          )?.displayName
                                        }
                                        :
                                      </TetraHeading>
                                    </span>
                                    {length?.feet ||
                                      length?.inch ||
                                      width?.feet ||
                                      width?.inch ? (
                                      <p
                                        className="feature-text mb-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {`${length?.feet || 0} ' ${length?.inch || 0
                                          }'' x ${width?.feet || 0} ' ${width?.inch || 0
                                          }''`}
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        Measurement not Available
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </Col>
                      )}
                      {secondFloorInfo?.length > 1 && (
                        <Col sm={6} className="mt-3">
                          <h6 className="rooms-container-subheading">
                            Second Floor
                          </h6>
                          {secondFloorInfo.map(
                            ({ name, length, width }, index) =>
                              propertyRoomName.find(
                                ({ keyName }) => keyName === name
                              ) && (
                                <div key={index}>
                                  <div className="feature d-flex align-items-center gap-2">
                                    <span>
                                      <TetraHeading className="mb-0 feature-heading">
                                        {
                                          propertyRoomName.find(
                                            ({ keyName }) => keyName === name
                                          )?.displayName
                                        }
                                        :
                                      </TetraHeading>
                                    </span>
                                    {length?.feet ||
                                      length?.inch ||
                                      width?.feet ||
                                      width?.inch ? (
                                      <p
                                        className="feature-text mb-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {`${length?.feet || 0} ' ${length?.inch || 0
                                          }'' x ${width?.feet || 0} ' ${width?.inch || 0
                                          }''`}
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        Measurement not Available
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </Col>
                      )}
                      {thirdFloorInfo && thirdFloorInfo.length > 1 && (
                        <Col sm={6} className="mt-3">
                          <h6 className="rooms-container-subheading">
                            Third Floor
                          </h6>
                          {thirdFloorInfo.map(
                            ({ name, length, width }, index) =>
                              propertyRoomName.find(
                                ({ keyName }) => keyName === name
                              ) && (
                                <div key={index}>
                                  <div className="feature d-flex align-items-center gap-2">
                                    <span>
                                      <TetraHeading className="mb-0 feature-heading">
                                        {
                                          propertyRoomName.find(
                                            ({ keyName }) => keyName === name
                                          )?.displayName
                                        }
                                        :
                                      </TetraHeading>
                                    </span>
                                    {length?.feet ||
                                      length?.inch ||
                                      width?.feet ||
                                      width?.inch ? (
                                      <p
                                        className="feature-text mb-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      >
                                        {`${length?.feet || 0} ' ${length?.inch || 0
                                          }'' x ${width?.feet || 0} ' ${width?.inch || 0
                                          }''`}
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        Measurement not Available
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
            </div>
            {/* <div className="static-request-tour my-3">
              <RequestTour newsFeedId={newsFeedId} />
            </div> */}
            {propertyData?.latitude && propertyData?.longitude && (
              <div className="mt-3">
                <MyMapComponent
                  initialLatitude={propertyData.latitude}
                  initialLongitude={propertyData.longitude}
                />
              </div>
            )}
          </>
        )}
      </Container>
    </PropertyDetailsStyle>
  );
};

export default PropertyDetails;

import Skeleton from "react-loading-skeleton";

const ProfileBoxSkeleton = () => {
  return (
    <div>
      <div className="d-flex align-items-center booking-profile">
        <Skeleton circle height={66} width={66} className="me-3" />
        <Skeleton count={3} containerClassName="w-25" />
      </div>
      <div className="d-flex justify-content-betwwen gap-3">
        <Skeleton containerClassName="w-75" />
        <Skeleton containerClassName="w-75" />
      </div>
      <div className="d-flex justify-content-betwwen gap-3">
        <Skeleton containerClassName="w-75" />
        <Skeleton containerClassName="w-75" />
      </div>
    </div>
  );
};

export default ProfileBoxSkeleton;

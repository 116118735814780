import styled from "styled-components";
import { lightGrey } from "../../components/GlobalStyle";

const Page404Style = styled.section`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .btn {
    font-size: 1rem;
    margin-top: 30px;
    border: 1px solid ${lightGrey};
  }
`;

export default Page404Style;

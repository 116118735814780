import SecondaryHeading from "../../components/SecondaryHeading/Index";
import { FeaturePropertiesStyled } from "./style";

const FeatureProperties = () => {
  return (
    <FeaturePropertiesStyled>
      <SecondaryHeading
        heading="Featured Properties"
        sub_heading=""
        // sub_heading="Browse The Best Of Our Latest Listing's"
      />
    </FeaturePropertiesStyled>
  );
};

export default FeatureProperties;

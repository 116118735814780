import { Col, Container, Row } from "react-bootstrap";
import SendRequestStyle from "./style";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import viewmore from "../../assets/icons/ic_newsfeed_more.svg";
import message from "../../assets/icons/ic_property_detail_chat.svg";
import call from "../../assets/icons/ic_property_detail_call.svg";
import CustomButton from "../CustomButton/CustomButton";
import {
  lightGrey,
  primaryColor,
  pureDark,
  secondaryDark,
  whiteColor,
} from "../GlobalStyle";
import { useNavigate } from "react-router-dom";
import {
  authorizationToken,
  change_booking_status_url,
  media_base_url,
  upcoming_requests_url,
} from "../../utils/api_urls";
import { useAppSelector } from "../../app/hooks";
import NoDataAvailable from "../NoData/NoDataAvailable";
import { useBookingContext } from "../../context/BookingContext";
import usePostEvent from "../../hooks/usePostEvent";
import LoadingOverlay from "../Modal/LoadingOverlay";
import { truncateString } from "../../utils/utilities";
import video from "../../assets/icons/ic_video.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import ApproveOptions from "./ApproveOptions";
import AcceptOptions from "./AcceptOptions";
import BookingsSkeleton from "../Skeletons/Bookings";
import RescheduleOptions from "./RescheduleOptions";
import ConfirmationOptions from "./ConfirmationOptions";
import PendingOptions from "./PendingOptions";

const UpcomingRequests = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [upcomingData, setUpcomingData] = useState({} as upcomingDataTypes);
  const [counter, setCounter] = useState(0);
  const [rescheduleModal, setrescheduleModal] = useState(false);
  const [showAcceptedModal, setShowAcceptedModal] = useState(false);
  const {
    loading: confirmLoading,
    error: confirmError,
    data,
    apiDataPromise,
  } = usePostEvent();

  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [showBookingOptions, setShowBookingOptions] = useState(false);
  const [booking, setbooking] = useState(0);

  const { setBookingId, setSelectedBooking, selectedBooking } =
    useBookingContext();

  const { visitRequestStatus } = useAppSelector(
    (state) => state.appData.data.statusList
  );

  const extractCityFromAddress = (address: string) => {
    const addressParts = address.split(', ');
    // Assuming there are at least two parts in the address
    return addressParts.length > 1 ? addressParts[addressParts.length - 2] : '';
  };
  console.log(loginData, "login");

  // receive id's
  const receiveIds = [1, 2, 4, 8, 9];
  const [visitRequestData, setVisitRequestData] = useState(
    visitRequestStatus
      .map((data) => {
        if (data.id === 1) {
          return { ...data, isSelect: true };
        }
        return { ...data, isSelect: false };
      })
      .filter(({ id }) => receiveIds.includes(id))
  );

  //   receive request promise
  const receiveRequestPromise = async (requestStatusId: string) => {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await axios.post(
        upcoming_requests_url,
        {
          userId: loginData?.userDetails.id,
          requestStatusId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setUpcomingData(data.results);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  const selectedStatus = visitRequestData.find(
    ({ isSelect }) => isSelect === true
  );
  useEffect(() => {
    if (selectedStatus) {
      receiveRequestPromise(selectedStatus.id.toString());
    }
  }, [counter]);
  const opennew = (propId: number, profId: number) => {
    if (propId) { navigate(`/property-details/${propId}`) }
    if (profId)
    //correct the url
    { navigate(`/property-details/${profId}`) }

  }

  // toggle visit request
  const toggleReceiveRequests = (index: number) => {
    const oldData = [...visitRequestData];
    const newData = oldData.map((data) => ({ ...data, isSelect: false }));
    newData[index].isSelect = true;
    setVisitRequestData(newData);
  };

  const doTask = () => {
    setShowAcceptedModal(false);
  };
  console.log();

  return (
    <Container className="py-4">
      <CustomModal
        isModalVisible={showBookingOptions}
        setIsModalVisible={setShowBookingOptions}
        showCloseBtn={false}
      >
        {/* {selectedStatus && selectedStatus.id === 2 ? (
          <AcceptOptions setShowBookingModal={setShowBookingOptions} prop={booking} />            


        ) : (
          <ApproveOptions setShowBookingModal={setShowBookingOptions} props={booking} />
        )} */}
         {/* {selectedStatus && selectedStatus.id === 2 ? (
            <PendingOptions setShowBookingModal={setShowBookingOptions} />
          ) : selectedStatus && selectedStatus.id === 8 ? (
            <AcceptOptions setShowBookingModal={setShowBookingOptions} prop={booking} />          ) : (
            <ConfirmationOptions setShowBookingModal={setShowBookingOptions} />
          )} */}

{selectedStatus && selectedStatus.id === 1?(
          <ApproveOptions setShowBookingModal={setShowBookingOptions} props={booking} />
)          : selectedStatus && selectedStatus.id === 8 ? (
  <PendingOptions setShowBookingModal={setShowBookingOptions} />
  ) : ((
            <ConfirmationOptions setShowBookingModal={setShowBookingOptions} />
          ))} 
      </CustomModal>
      <CustomModal
        isModalVisible={showAcceptedModal}
        setIsModalVisible={setShowAcceptedModal}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Booking Accepted"
          description="Booking Accepted Successfully."
          doTask={doTask}
        />
      </CustomModal>

      <Row>
        {visitRequestData.map(({ name, id, isSelect }, index) => (
          <Col md={2} className="mt-2">
            <CustomButton
              key={id}
              bgcolor={isSelect ? primaryColor : "transparent"}
              color={isSelect ? whiteColor : secondaryDark}
              padding="8px 8px"
              width="100%"
              border={`1px solid ${isSelect ? primaryColor : lightGrey}`}
              type="submit"
              title={name}
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              textTransform="Capitalize"
              clicked={() => {
                toggleReceiveRequests(index);
                receiveRequestPromise(id.toString());
              }}
            />
          </Col>
        ))}
      </Row>
      {confirmLoading && <LoadingOverlay message="Booking..." />}
      {isLoading ? (
        <BookingsSkeleton />
      ) : error ? (
        <NoDataAvailable title={error} />
      ) : (
        <Row>
          {upcomingData.upComingRequests.map((bookingData) => {
            const {
              bookingId,
              host,
              visitor,
              description,
              visitType,
              address,
              visitDate,
              visitStartTime,
              visitEndTime,
              requestStatusId,
              newsFeedId,
              propertyId,
              professionalId,
            } = bookingData;
            const { firstName, lastName, profilePicture, phoneNumber } = visitor;
            // const { phoneNumber: hostPhoneNumber } = host;

            return (
              <Col sm={6} md={4} key={bookingId}>
                <SendRequestStyle>
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex gap-3">
                      <figure className="m-0 profile">
                        <img
                           onClick={() => navigate(`/professional/${visitor.id}`)}
                          src={
                            visitor.hasOwnProperty("profilePicture")
                              ? media_base_url + profilePicture
                              : placeholder
                          }
                          className="rounded cursor-pointer"
                          alt="image"
                        />
                      </figure>
                      <div>
                        <h4
                          onClick={() => opennew(propertyId, professionalId)}
                          className="m-0 d-flex gap-2 heading cursor-pointer"
                        >
                          {firstName} {lastName}
                        </h4>
                        <p className="m-0 address">
                          {phoneNumber}                       
                           </p>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end gap-2">
                      {(requestStatusId === 2 || requestStatusId === 9) && (
                        <i>
                          <img
                            src={viewmore}
                            alt="view more"
                            className="cursor-pointer"
                            onClick={() => {
                              setShowBookingOptions(true);
                              setbooking(bookingId)
                              setSelectedBooking({
                                bookingId,
                                requestStatusId,
                                newsFeedId,
                                visitType,
                                description,
                                visitDate,
                                visitEndTime,
                                visitStartTime,
                              });
                            }}
                          />
                        </i>
                      )}
                      <div className="d-flex gap-2 icon-container" >
                        {requestStatusId !== 1 && (
                          <button
                            disabled={
                              requestStatusId === 6 || requestStatusId === 7
                                ? true
                                : false
                            }
                          >
                            <img src={message} alt="message" />
                          </button>
                        )}
                        {requestStatusId !== 1 && (
                          <div className="reason-section d-flex my-2" />
                        )}
                        {visitType !== "IN_PERSON" && (
                          <button
                            disabled={
                              requestStatusId === 6 || requestStatusId === 7
                                ? true
                                : false
                            }
                          >
                            <img src={video} alt="video" />
                          </button>
                        )}
                        
                      </div>
                    </div>
                  </div>
                  <div className="reason-section d-flex my-2" />

                  <div className="d-flex align-items-center">
                    <p className="highlighted mt-1">
                      {visitType === "IN_PERSON" ? "in person" : "virtual"}
                    </p>
                    <p className="ms-3">{visitDate}</p>
                    <p className="ms-3">
                      {visitStartTime}
                      {visitEndTime && ` to ${visitEndTime}`}
                    </p>
                  </div>

                
                
                  <div className="reason-section d-flex my-2" />
                  <p className="my-2">{truncateString(description, 116)}</p>
                  {requestStatusId === 8 && (
                    <div className="reason-section d-flex my-2">
                      <p className="highlighted">Reschedule</p>
                      <p className="ms-3">{visitDate}</p>
                      <p className="ms-3">
                        {visitStartTime}
                        {visitEndTime && ` to ${visitEndTime}`}
                      </p>
                    </div>
                  )}
                    {/* {requestStatusId === 2 && (
              <> 
              <div className="reason-section d-flex my-2"/>

          <div className="my-2">
            <CustomButton
              bgcolor={primaryColor}
              color={whiteColor}
              padding="8px"
              width="100%"
              type="button"
              border={`1px solid ${primaryColor}`}
              title="Reschedule Contact Us Request"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              clicked={() => {
                console.log(bookingId,bookingData,"Nafda");
                console.log("Reached Location");
                setShowBookingOptions(true);
                // apiDataPromise("property/visit/changeStatus", {
                //   bookingId:bookingId,
                //   requestStatusId: 8,
                // }).then(() => setCounter((prev) => prev + 1));
              }}
            />
          </div>
          </>
        )} */}
                  {requestStatusId === 8 && (
                    <div className="d-flex gap-2">
                      <CustomButton
                        bgcolor="transparent"
                        color={pureDark}
                        padding="8px"
                        width="100%"
                        type="submit"
                        border={`1px solid ${pureDark}`}
                        title="Decline"
                        margin="auto"
                        fontFamily="EnnVisionsMedium"
                        fontSize="16px"
                        clicked={() => {
                          setSelectedBooking({
                            ...selectedBooking,
                            bookingId: bookingId,
                            requestStatusId,
                          });
                          navigate(`/booking-reports/${bookingId}`);
                        }}
                      />
                      <CustomButton
                        bgcolor={primaryColor}
                        color="white"
                        padding="8px"
                        width="100%"
                        type="submit"
                        title="Approved"
                        margin="auto"
                        fontFamily="EnnVisionsMedium"
                        fontSize="16px"
                        clicked={() =>
                          apiDataPromise(change_booking_status_url, {
                            bookingId,
                            requestStatusId: 2,
                          }).then(() => {
                            setCounter((prev) => prev + 1);
                          })
                        }
                      />
                    </div>
                  )}
                  {requestStatusId === 1 && (
                    <div className="d-flex gap-2">
                      <CustomButton
                        bgcolor="transparent"
                        color={pureDark}
                        padding="8px"
                        width="100%"
                        type="submit"
                        border={`1px solid ${pureDark}`}
                        title="REJECTED"
                        margin="auto"
                        fontFamily="EnnVisionsMedium"
                        fontSize="16px"
                        clicked={() => {
                          setBookingId(bookingId);
                          navigate(`/booking-reports/${bookingId}`);
                        }}
                      />
                      <CustomButton
                        bgcolor={primaryColor}
                        color="white"
                        padding="8px"
                        width="100%"
                        type="submit"
                        title="ACCEPT"
                        margin="auto"
                        fontFamily="EnnVisionsMedium"
                        fontSize="16px"
                        clicked={() => {
                          apiDataPromise(change_booking_status_url, {
                            bookingId,
                            requestStatusId: 2,
                          }).then(() => setCounter((prev) => prev + 1));
                        }}
                      />
                    </div>
                  )}
                </SendRequestStyle>
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  );
};

export default UpcomingRequests;

export interface upcomingDataTypes {
  totalItems: number;
  upComingRequests: UpComingRequest[];
  totalPages: number;
  currentPage: number;
}

export interface UpComingRequest {
  bookingId: number;
  createdDateTime: string;
  visitType: string;
  description: string;
  visitDate: string;
  visitStartTime: string;
  visitEndTime?: string;
  newsFeedId: number;
  requestStatusId: any;
  rejectReasonId: any;
  address: string;
  postType: string;
  visitor: Visitor;
  host: Host;
  propertyId: number
  professionalId: number

}

export interface Visitor {
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  id: number;
  profilePicture?: string;
}
export interface Host {
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  id: number;
  profilePicture?: string;
}

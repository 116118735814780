import styled from "styled-components";

type customTextAreaProps = {
  labelFamily?: string;
  labelFontSize?: string;
  height: string;
};
export const CustomTextAreaStyle = styled.div<customTextAreaProps>`
  margin-bottom: 10px;

  label {
    font-size: ${(props) => props.labelFontSize};
    font-family: ${(props) => props.labelFamily};
    text-transform: capitalize;
    display: block;
    margin-bottom: 10px;
  }
  textarea {
    height: ${(props) => props.height};
  }
  .customInput.ant-input {
    background: white;
    border: 1px solid #c6c6c8;
    border-radius: 5px;
    padding: 10px;
    height: ${(props) => props.height};
  }
`;

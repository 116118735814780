import { Nav } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { primaryColor } from "../GlobalStyle";
import FiltersStyle from "./style";
import { useState } from "react";
import {
  ProfessionNavItemEventKey,
  useSearchContext,
} from "../../context/SearchContext";

const ProfessionsFilter = () => {
  const [active, setActive] = useState(false);
  const {
    data: { professions },
  } = useAppSelector((state) => state.appData);
  const {
    selectedProfessions,
    setSelectedProfessions,
    setShowProfessions,
    professionalAddress,
    setProfessionalFilterCriteria,
    setProfessionalAddress,
    setProfessionalSearchCriteria,
  } = useSearchContext();

  const handleSelect = (eventKey: string | null) => {
    if (!eventKey) return;
    const item: ProfessionNavItemEventKey = JSON.parse(eventKey);
    if (item.name === "all") {
      setActive(true);
      setSelectedProfessions(professions);
    } else if (selectedProfessions.some((i) => i.name === item.name)) {
      setSelectedProfessions(
        selectedProfessions.filter((i) => i.name !== item.name)
      );
    } else {
      setSelectedProfessions([...selectedProfessions, item]);
    }
  };

  console.log({ selectedProfessions });

  return (
    <FiltersStyle>
      <div className="types mt-2">
        <Nav
          variant="pills"
          defaultActiveKey={professions[0].name}
          onSelect={handleSelect}
        >
          <Nav.Item>
            <Nav.Link
              eventKey={JSON.stringify({
                name: "all",
              })}
              active={active}
            >
              All
            </Nav.Link>
          </Nav.Item>
          {professions.map(({ id, name }) => (
            <Nav.Item key={id}>
              <Nav.Link
                eventKey={JSON.stringify({
                  id,
                  name,
                })}
                active={selectedProfessions.some((item) => item.name === name)}
              >
                {name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="d-flex justify-content-end w-100">
        <div className="me-3 mb-3">
          <CustomButton
            bgcolor="#EFEFF4"
            color="#717272"
            padding="6px 8px"
            width="100px"
            type="button"
            title="Reset"
            fontSize="16px"
            clicked={() => {
              setProfessionalAddress("");
              setSelectedProfessions([]);
              setActive(false);
              setShowProfessions(false);
              setProfessionalSearchCriteria([]);
            }}
            border="none"
          />
        </div>
        <div className="me-2 mb-3">
          <CustomButton
            bgcolor={primaryColor}
            color="#FCFBFB"
            padding="6px 8px"
            width="100px"
            type="button"
            fontSize="16px"
            title="Apply"
            border="none"
            clicked={() => {
              if (professionalAddress) {
                // setting address
                setProfessionalFilterCriteria("address", professionalAddress);
              }
              // add selected profession id's
              const selectedIds = selectedProfessions
                .map(({ id }) => id)
                .join(",");
              console.log({ selectedIds });
              // add all id's if no profession is selected
              const zeroSelectedIds = professions.map(({ id }) => id).join(",");
              // setting subtypes search filter
              setProfessionalFilterCriteria(
                "profession",
                selectedProfessions.length < 1 ? zeroSelectedIds : selectedIds
              );
              setShowProfessions(false);
            }}
          />
        </div>
      </div>
    </FiltersStyle>
  );
};

export default ProfessionsFilter;

import { Drawer } from "antd";
import { useGlobalContext } from "../../context/context";
import { SideMenuStyle } from "./style";
import { useAppSelector } from "../../app/hooks";

type sideMenuProps = {
  children: React.ReactNode;
};
const SideMenu = ({ children }: sideMenuProps) => {
  const loginData = useAppSelector((state) => state.loginData.data);
  const { showSidebar, setShowSidebar, showAuthSidebar, setShowAuthSidebar } =
    useGlobalContext();
  const onClose = () => {
    if (loginData) {
      setShowAuthSidebar(false);
    } else {
      setShowSidebar(false);
    }
  };
  return (
    <SideMenuStyle>
      {loginData ? (
        <Drawer
          style={{
            marginTop: "0px",
          }}
          width={250}
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={showAuthSidebar}
          key={"left"}
        >
          {children}
        </Drawer>
      ) : (
        <Drawer
          style={{
            marginTop: "0px",
          }}
          width={250}
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={showSidebar}
          key={"left"}
        >
          {children}
        </Drawer>
      )}
    </SideMenuStyle>
  );
};

export default SideMenu;

import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  lightGrey2,
  tertiaryDark4,
} from "../../components/GlobalStyle";

const SettingAndPrivacyStyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 450px;
  background-color:  white;
  padding: 16px;

  .title {
    font-size: 24px;
    
  }
  .settings {
    &-option {
      margin-top: 20px;
      border-bottom: 1px solid ${lightGrey2};
      padding-bottom: 8px;
      &-title {
        color: ${tertiaryDark4};
        font-size: 14px;
      }
    }
  }
  .terms,
  .privacy {
    font-family: ${fontFamilyRegular};
    font-size: 14px;
    margin: 16px 0px;
  }
  .termsPrivacy{
    background-color: white;
    padding: 0px 6px;
    border-radius: 5px;

  }
`;

export default SettingAndPrivacyStyle;

import { Slider } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { lightGrey, primaryColor } from "../../components/GlobalStyle";
import { PriceRangeStyle } from "./style";
import { useSearchContext } from "../../context/SearchContext";

const PriceRange = () => {
  const { setShowPriceRange } = useSearchContext();
  const {
    setSearchCriteria,
    searchCriteria,
    setFilterCriteria,
    priceMinRange,
    priceMaxRange,
    setPriceMinRange,
    setPriceMaxRange,
  } = useSearchContext();
  const onChange = (value: [number, number]) => {
    setPriceMinRange(value[0]);
    setPriceMaxRange(value[1]);
  };

  const onAfterChange = (value: number | [number, number]) => {
    console.log("onAfterChange: ", value);
  };

  const totalPriceRange = 10000000;
  return (
    <PriceRangeStyle>
      <div className="d-flex justify-content-between">
        <h6>Price Range</h6>
        <h6>Pkr</h6>
      </div>
      <div className="d-flex align-items-center">
        <input
          type="number"
          placeholder="0"
          value={priceMinRange}
          onChange={(e) => setPriceMinRange(e.target.value)}
        />
        <p className="mx-2 mb-0 to-text">To</p>
        <input
          type="number"
          placeholder={totalPriceRange.toString()}
          value={priceMaxRange}
          onChange={(e) => setPriceMaxRange(e.target.value)}
        />
      </div>
      <Slider
        range
        defaultValue={[20, totalPriceRange]}
        max={totalPriceRange}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className="d-flex gap-2">
        <CustomButton
          bgcolor={lightGrey}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Cancel"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            const oldSearchCreteria = searchCriteria.filter(
              ({ filterKey }) =>
                filterKey !== "propertyPriceStartRange" &&
                filterKey !== "propertyPriceEndRange"
            );
            setSearchCriteria(oldSearchCreteria);
            setPriceMinRange(0);
            setPriceMaxRange(0);
            setShowPriceRange(false);
          }}
        />

        <CustomButton
          bgcolor={primaryColor}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Apply"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            if (+priceMinRange < 1 && priceMinRange > priceMaxRange) {
              return;
            }
            console.log(parseFloat(`${priceMinRange}.0`), "float value");
            setFilterCriteria("propertyPriceStartRange", +priceMinRange);
            setFilterCriteria("propertyPriceEndRange", +priceMaxRange);
            setShowPriceRange(false);
          }}
        />
      </div>
    </PriceRangeStyle>
  );
};

export default PriceRange;

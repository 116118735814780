import { useState } from "react";
import styled from "styled-components";
import CustomDate from "../Date/Date";
import CustomTime from "../Time/Time";
import { fontFamilyMedium, primaryColor } from "../../components/GlobalStyle";
import { Input } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { authorizationToken, edit_booking_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import axios from "axios";
import { useBookingContext } from "../../context/BookingContext";
import CustomModal from "../../components/Modal/CustomModal";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { Col, Container, Row } from "react-bootstrap";
import ItemInfo from "../../components/ItemInfo/ItemInfo";

const { TextArea } = Input;

const EditReschedule = () => {
  // const { state: locationData }: { state: bookingStateTypes } = useLocation();
  const { selectedBooking } = useBookingContext();
  // if (!locationData) {
  //   return <Navigate to="/property-listing" />;
  // }
  const [date, setDate] = useState<string | null>(
    selectedBooking.visitDate || ""
  );
  const [description, setDescription] = useState("");
  // Create a dayjs instance representing the current time as the initial value
  const [selectedTime, setSelectedTime] = useState<string>(
    selectedBooking.visitStartTime || ""
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    bookingId,
    newsFeedId = "",
    visitType = "",
    requestStatusId = "",
  } = selectedBooking;
  //   request tour promise
  const editTourPromise = async () => {
    if (!date || !selectedTime) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        edit_booking_url,
        {
          bookingId,
          newsFeedId,
          visitType,
          requestStatusId: 8,
          description: selectedBooking.description,
          visitDate: selectedBooking.visitDate,
          visitStartTime: selectedBooking.visitStartTime,
          visitEndTime: null,
          rescheduleVisitDate: date,
          rescheduleVisitStartTime: selectedTime,
          rescheduleVisitEndTime: null,
          shortNote: description,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  console.log({ date }, "date in on change");

  console.log({ date, selectedTime });

  return (
    <>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        {isLoading && <LoadingOverlay message="Reschedule.." />}
        <EnnvisionModal
          title="Visit Reschedule"
          description="Visited Rescheduled Successfully"
          doTask={() => navigate(-1)}
        />
      </CustomModal>
      <Container className="py-2">
        <Row>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <ItemInfo
                  heading="Description"
                  title={selectedBooking.description || ""}
                />
              </Col>
              <Col sm={6}>
                <ItemInfo
                  heading="Visit Date"
                  title={selectedBooking.visitDate || ""}
                />
              </Col>
              <Col md={6}>
                <ItemInfo
                  heading="Visit Time"
                  title={selectedBooking.visitStartTime || ""}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Wrapper>
              <article>
                <label htmlFor="date">Reschedule Visit Date</label>
                <div className="mt-2">
                  <CustomDate date={date} setDate={setDate} />
                  {!date && <p className="error">Date is Required</p>}
                </div>
                {/* {date && <p>Selected date: {date.format("DD MMM YYYY")}</p>} */}
                <div className="mt-2">
                  <label htmlFor="time">Reschedule Visit Start Time</label>
                  <div className="mt-2">
                    <CustomTime time={selectedTime} setTime={setSelectedTime} />
                  </div>
                  {!selectedTime && <p className="error">Time is Required</p>}
                </div>
                <div className="mt-2">
                  <label htmlFor="description">Short Note (Optional)</label>
                  <div className="mt-2">
                    <TextArea
                      maxLength={100}
                      style={{ height: 240, marginBottom: 16 }}
                      onChange={(event) => setDescription(event.target.value)}
                      value={description}
                      placeholder="Describe the reason of schedule"
                    />
                  </div>
                </div>
                <CustomButton
                  bgcolor={primaryColor}
                  color="white"
                  padding="8px"
                  width="100%"
                  type="submit"
                  title="Submit"
                  margin="auto"
                  loading={isLoading}
                  clicked={editTourPromise}
                />
              </article>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </>
  );
};
const Wrapper = styled.section`
  background: #ffffff;
  border: 1px solid #efeff4;
  border-radius: 3px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 10px;
  margin-top: 20px;
  .error {
    margin-top: 10px;
    color: ${primaryColor};
    font-family: ${fontFamilyMedium};
  }
  label {
    font-family: ${fontFamilyMedium};
    font-size: 14px;
  }
  input {
    padding: 4px;
  }
  .selected_Date {
    text-align: left;
    font-size: 14px;
    font-family: "EnnVisions";
    letter-spacing: 0px;
    color: #000000;
  }
`;
export default EditReschedule;

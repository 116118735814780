import StoryStyle from "./style";
import { Navigate } from "react-router-dom";
import StorySlider from "../ExploreNearby/StorySlider";
import { useGlobalContext } from "../../context/context";
import { Dispatch, SetStateAction } from "react";

type storyProps = {
  setIsShowStories: Dispatch<SetStateAction<boolean>>;
};
const Story: React.FC<storyProps> = ({ setIsShowStories }) => {
  // add user stories in state
  const { userStoriesData, setUserStoriesData, setSlideIndex } =
    useGlobalContext();
  if (Object.keys(userStoriesData).length === 0)
    return <Navigate to="/explore-nearby" />;

  console.table(userStoriesData.ownerData);

  return (
    <StoryStyle>
      <div className="inner-container">
        <StorySlider
          userStoriesData={userStoriesData}
          setIsShowStories={setIsShowStories}
          setUserStoriesData={setUserStoriesData}
        />
      </div>
    </StoryStyle>
  );
};

export default Story;

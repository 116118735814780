import {
  BlackDot,
  lightGrey11,
  lightGrey2,
  primaryColor,
  TertiraryHeading,
  UnderlineRed,
} from "../../../components/GlobalStyle";
import { ProInputsStyle } from "./style";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormControl";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SelectOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/context";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getProfessionalDropdown } from "../../../redux/features/professional/dropdownSlice";
import { FILE_SIZE, FILE_SUPPORTED_FORMATS } from "../../../utils/formats";
import Errormsg from "../../../components/ErrorMessage";
import {
  authorizationToken,
  become_professional_url,
  local_storage_web_key,
} from "../../../utils/api_urls";
import axios, { AxiosError } from "axios";
import dateIcon from "../../assets/icons/ic_calendar.svg";
import { RootState } from "../../../redux/store";
import {
  becomeProHandler,
  removeLoginData,
} from "../../../redux/features/user/getLoginDataSlice";
import { loginDataTypes } from "../../../redux/features/user/types";
import AutoCompleteAddress from "../../../maps/AutoCompleteAddress";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionModal from "../../../components/CustomModals/EnnvisionModal";
import useLogout from "../../../hooks/useLogout";

// inital values types
type initialValuesTyes = {
  address: string;
  professionTypeId: number | string;
  businessName: string;
  businessStartedDate: string;
  businessRegisterNumber: string;
  businessRegisterDoc: any;
  idProfDoc: any;
};
const Inputs = () => {
  const navigate = useNavigate();
  const { setIsProfessional } = useGlobalContext();
  const dispatch = useAppDispatch();
  const [becomeProLoading, setBecomeProLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const logout = useLogout();

  const { loading, data, error } = useAppSelector(
    (state) => state.professionalDropdown
  );

  // get Validations from redux appData
  const { validations } = useAppSelector(
    (state: RootState) => state.appData.data
  );
  const { data: loginData } = useAppSelector((state) => state.loginData);
  // user validations
  const addressValidation = validations.find(({ key }) => key === "ADDRESS")!;
  const bussinessNameValidation = validations.find(
    ({ key }) => key === "BUSINESS_NAME"
  )!;
  const business_register_no_validation = validations.find(
    ({ key }) => key === "BUSINESS_REGISTRATION_NUMBER"
  )!;
  // become professional regExpressions
  const addressRegex = new RegExp(addressValidation.pattern);
  const bussinessNameRegex = new RegExp(bussinessNameValidation.pattern);
  const business_registration_no_regex = new RegExp(
    business_register_no_validation.pattern
  );

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current >= dayjs().startOf("day");
  };

  useEffect(() => {
    dispatch(getProfessionalDropdown());
  }, []);

  const initialValues: initialValuesTyes = {
    address: "",
    professionTypeId: "",
    businessName: "",
    businessStartedDate: "",
    businessRegisterNumber: "",
    businessRegisterDoc: null,
    idProfDoc: null,
  };
  const noWhiteSpaceRegex = /^\S*$/;
  const validationSchema = Yup.object({
    address: Yup.string().required("Address is required!"),
    // .required(addressValidation.notBlankMsg)
    // .matches(addressRegex, addressValidation.patternMsg),
    professionTypeId: Yup.string().required("profession is required!"),
    businessName: Yup.string()
      .required(bussinessNameValidation.notBlankMsg)
      .matches(bussinessNameRegex, bussinessNameValidation.patternMsg),
    businessStartedDate: Yup.string().required("field is required!"),
    businessRegisterNumber: Yup.string()
      .required(business_register_no_validation.notBlankMsg)
      .matches(
        business_registration_no_regex,
        business_register_no_validation.patternMsg
        
      )  .matches(noWhiteSpaceRegex, "No white spaces allowed in the business start date!"),

    idProfDoc: Yup.mixed().required("A file is required"),
    // .test(
    //   "fileSize",
    //   "File too large",become
    //   (value) => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
    // ),
    businessRegisterDoc: Yup.mixed().required("A file is required"),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   (value) => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
    // ),
  });

  const handleSubmit = async (values: initialValuesTyes) => {
    const formdata = new FormData();
    formdata.append(
      "businessRegisterDoc",
      values.businessRegisterDoc,
      values.businessRegisterDoc.name
    );
    formdata.append("idProfDoc", values.idProfDoc, values.idProfDoc.name);
    // object of become prfoessional value to delete docs before submit
    const allValues = {
      ...values,
    };
    delete allValues.businessRegisterDoc;
    delete allValues.idProfDoc;
    formdata.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...allValues,
            userId: loginData?.userDetails.id,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setBecomeProLoading(true);
      const { data } = await axios.post(become_professional_url, formdata, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      localStorage.removeItem(local_storage_web_key);
      dispatch(removeLoginData());
      setIsProfessional(true);
      setBecomeProLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        logout();
      }, 2000);
      console.log({ data });
    } catch (error: any) {
      alert(error.response.data.responseMessage);
      if (error.response.data.responseCode === "500") {
        alert(error.response.data.responseMessage);
        navigate("/find-home");
        console.log(error.response.data.responseMessage);
        setBecomeProLoading(false);
      } else {
        alert(error.response.data.responseMessage);
        setBecomeProLoading(false);
      }
    }
  };

  // navigate to professional plans
  const doTask = () => {
    logout();
  };
  return (
    <ProInputsStyle className="mt-3">
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Become Professional"
          description="Congratulations on upgrading your account as a professional. Now you are our premium user. you can promote you business feel free"
          doTask={doTask}
        />
      </CustomModal>
      <TertiraryHeading className="ms-3 mt-4">
        Become a Professional
      </TertiraryHeading>

      <div className="ms-3">
        <UnderlineRed />
        <BlackDot />
      </div>
      <div className="inputs mt-3 mx-2">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            console.log(formik.values, formik.errors, "formik values");
            return (
              <Form
                typeof="application/json"
                name="basic"
                // onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <Row className="login-input-fields">
                  <Col sm={6} md={4} className="mt-1 mt-sm-0">
                    <AutoCompleteAddress
                      label="Business Address"
                      placeholder="Search Address..."
                      name="address"
                      onChange={({ address, longitude, latitude }) => {
                        console.log({ longitude, latitude });
                        formik.setFieldValue("address", address);
                        formik.setFieldValue("longitude", longitude);
                        formik.setFieldValue("latitude", latitude);
                      }}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <ErrorMessage name="address" component={Errormsg} />
                      // <p className="text-danger mt-2 mb-0">
                      //   address is required
                      // </p>
                    )}
                  </Col>
                  <Col sm={6} md={4} className="mt-1 mt-sm-0">
                    <FormControl
                      control="input"
                      type="text"
                      name="businessName"
                      border={`1px solid ${lightGrey2}`}
                      label="Business Name"
                      placeholder="Business Name"
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.businessName &&
                        formik.touched.businessName
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                  </Col>
                  <Col sm={6} md={4} className="mt-1 mt-sm-0">
                    <FormControl
                      control="select"
                      type="text"
                      labelFont="14px"
                      name="professionTypeId"
                      labelMarginBottom="6px"
                      padding="3px"
                      loading={loading}
                      border={`1px solid ${lightGrey2}`}
                      label="Select a Profession"
                      placeholder="Select Business Type"
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.professionTypeId &&
                        formik.touched.professionTypeId
                          ? "is-invalid"
                          : "customInput"
                      }
                      options={
                        error
                          ? []
                          : data.map(({ id, name }) => ({
                              value: id,
                              label: name,
                            }))
                      }
                    />
                  </Col>
                  <Col
                    sm={6}
                    md={4}
                    className="mt-1 mt-sm-0 d-flex justify-content-center align-items-center"
                  >
                    <FormControl
                      control="file"
                      type="file"
                      name="businessRegisterDoc"
                      label="Business Registeration"
                      padding="7px"
                      placeholder="Document"
                      border={`1px solid ${lightGrey11}`}
                      suffix={<SelectOutlined />}
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.businessRegisterDoc &&
                        formik.touched.businessRegisterDoc
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                  </Col>
                  <Col sm={6} md={4} className="mt-2">
                    <FormControl
                      control="date"
                      type="text"
                      padding="6.52222px"
                      disabledDate={disabledDate}
                      name="businessStartedDate"
                      border={`1px solid ${lightGrey11}`}
                      label="In Business Since"
                      placeholder="In Business Since"
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.businessStartedDate &&
                        formik.touched.businessStartedDate
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                  </Col>
                  <Col sm={6} md={4} className="mt-2">
                    <FormControl
                      control="input"
                      type="text"
                      name="businessRegisterNumber"
                      border={`1px solid ${lightGrey2}`}
                      label="Business Reg no."
                      placeholder="Business Register Number"
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.businessRegisterNumber &&
                        formik.touched.businessRegisterNumber
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                  </Col>
                  <Col
                    sm={6}
                    md={4}
                    className="mt-1 mt-sm-0 d-flex justify-content-center align-items-center"
                  >
                    <FormControl
                      control="file"
                      type="file"
                      name="idProfDoc"
                      label="Id Prof Doc"
                      padding="7px"
                      placeholder="Document"
                      border={`1px solid ${lightGrey2}`}
                      suffix={<SelectOutlined />}
                      labelFamily="EnnVisionsMedium"
                      className={
                        formik.errors.idProfDoc && formik.touched.idProfDoc
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                  </Col>
                  <div className="mt-2 d-flex justify-content-end">
                    <CustomButton
                      bgcolor={`${primaryColor}`}
                      color="white"
                      padding="7px 8px"
                      width="auto"
                      type="submit"
                      loading={becomeProLoading}
                      title="Register Your Business"
                      fontSize="16px"
                    />
                  </div>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ProInputsStyle>
  );
};

export default Inputs;

import styled from "styled-components";
import { fontFamilyBold, fontFamilyMedium } from "../GlobalStyle";

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  transition: all 0.4s;
  &:focus {
    width: 100%;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    text-decoration: none;
    .title {
      font-family: ${fontFamilyBold};
    }
    li {
      padding: 7px 7px;
      color: rgba(0, 0, 0, 0.7);
      box-shadow: 0px 0.2px 0.2px rgba(0, 0, 0, 0.3);
      font-weight: 500;
      cursor: pointer;
      transition: all 0.1s;
      font-size: 18px;
      font-family: ${fontFamilyMedium};

      /* &:hover {
        box-shadow: 0.2px 0.1px 0.1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
      } */
      &:focus {
        filter: brightness(50%);
        background-color: rgba(0, 0, 0, 0.9);
        color: rgba(255, 255, 255, 0.3);
        transform: scale(1);
      }
      /* &:active {
        background-color: rgba(0, 0, 0, 0.7);
        color: rgba(255, 255, 255, 0.7);
      } */
    }
  }

  /* scale up amazing animation if want to remove then remove it */
  /* 
li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  transform: translateZ(0);
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: ease-out;
}

li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  transform: translateX(-50%);
  opacity: 0;
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

li:hover {
  transform: translateY(-10px) translateZ(0);
}

li:hover:before {
  opacity: 1;
} */
`;

export default Wrapper;




import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton/CustomButton";
import { whiteColor } from "../../components/GlobalStyle";
import { useStoryContext } from "../../context/StoryContext";
import { truncateString } from "../../utils/utilities";
import { CreateStoryPreviewStyle } from "./style";
import createIcon from "../../assets/icons/ic_upload_camera_story.svg";
import { useAppSelector } from "../../app/hooks";
import { authorizationToken, create_story_url } from "../../utils/api_urls";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";

type createPostTextInitTypes = {
  storyText: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
};

const CreateStoryPreview = () => {
  const { storyText } = useStoryContext();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [isCreatingPost, setIsCreatingPost] = useState(false);

  useEffect(() => {
    if (!storyText) {
      navigate("/create-story-text");
    }
  }, []);

  //  story submit handler

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            storyText,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            contactRequestPermission: true,
            boostPermission: true,
            saveFavourite: true,
            sharingEnabled: true,
            turnOffCommenting: false,
            readComments: true,
            hideLikesAndViewsCounts: false,
            ...locationData,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(create_story_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);
    } catch (error: any) {
      console.log({ error });
      setIsCreatingPost(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  // do task
  const doTask = () => {
    navigate("/explore-nearby");
  };

  return (
    <CreateStoryPreviewStyle>
      {isCreatingPost && <LoadingOverlay message="story creating..." />}
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Story Created"
          description="Story Created Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <div className="status">
        <div className="edit-btn">
          <CustomButton
            bgcolor={whiteColor}
            color="black"
            padding="6px"
            width="100px"
            type="button"
            textTransform="Captilize"
            title="Edit"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => navigate(-1)}
          />
        </div>
        <h6 className="status-text text-center">
          {truncateString(storyText, 500)}
        </h6>
        <button
          type="submit"
          className="create-story-btn"
          onClick={handleSubmit}
        >
          <img src={createIcon} alt="creat_icon" />
        </button>
      </div>
    </CreateStoryPreviewStyle>
  );
};

export default CreateStoryPreview;

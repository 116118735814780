import { HeroSectionStyle } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { useSearchContext } from "../../context/SearchContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useNavbarContext } from "../../context/NavbarContext";
import AuthNavbar from "../../components/Navbar/AuthNavbar";
import houseupVideo from '../../assets/houseUpVideo.mp4';
import { useEffect, useRef } from "react";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import VideoInView from "../../components/VideoPlayers/AutoVideoPlay";
// initial values
type initialValuesTypes = {
  keyWord: string;
};

// proeprty type item
export interface NavItemEventKey {
  id: number;
  keyName: string;
}

const HeroSection = () => {
  const { loading } = useNavbarContext();
  const {
    showPropertyTypes,
    setShowPropertyTypes,
    propertyTypeNumber,
    selectedSubTypes,
    setFilterCriteria,
    propertyType,
    purpose,
    setPurpose,
    address,
    setAddress,
  } = useSearchContext();
  const navigate = useNavigate();
  const propertyData = useAppSelector(
    (state) => state.appData.data.propertyAppData[propertyTypeNumber]
  );
  const { pathname } = useLocation();
  const propertySubTypes = propertyData.propertySubTypesDTOS;
  const purposeHandler = () => {
    setPurpose(purpose === "Rent" ? "Buy" : "Rent");
  };

  const initialValues: initialValuesTypes = {
    keyWord: "",
  };
 
  const end=()=>{
    console.log("video end")
  }
   
  return (
    <>
      <Container> 
        <AuthNavbar />
      </Container>
      <HeroSectionStyle>
        {/* <video  autoPlay src={houseupVideo} loop /> */}
        {/* <VideoInView isMuted={false} videoUrl={houseupVideo} onVolumeChange={()=>(false)}/> */}
          <VideoPlayer isMuted={false} videoUrl={houseupVideo} onVideoEnd={end} isPlaying={true} />
        {/* <Formik initialValues={initialValues} onSubmit={() => {}}>
          {(formik) => {
            console.log(formik.values, "formik values");
            return (
              <Form
                name="basic"
                autoComplete="off"
                onFinish={formik.handleSubmit}
              >
                <div className="inner-container d-flex align-items-center flex-column justify-content-center">
                  {loading ? (
                    <Skeleton height={20} count={2} width="300px" />
                  ) : (
                    <h4 className="inner-container-main-heading">
                      <span> Own the way you sell your home</span>
                      <span className="d-block text-center">
                        Only with <span className="nestify-up">Nestify </span>
                      </span>
                    </h4>
                  )}

                  {/* <div className="purchase-btns d-flex justify-content-between gap-3  w-100">
                    <button
                      className={`purchase-btns-rent w-50 ${
                        purpose === "Rent" ? "active" : ""
                      }`}
                      type="button"
                      onClick={purposeHandler}
                    >
                      RENT
                    </button>
                    <button
                      className={`purchase-btns-buy w-50 ${
                        purpose === "Rent" ? "" : "active"
                      }`}
                      type="button"
                      onClick={purposeHandler}
                    >
                      BUY
                    </button>
                  </div>
                  <div className="position-relative w-100 mx-2 mt-3">
                    <Row className="location-select-section w-100 mx-auto p-3 align-items-center">
                      <Col
                        sm={6}
                        className="location text-center text-sm-start"
                      >
                        <h5 className="title">Location</h5>
                        <AutoCompleteCity
                          placeholder="City Area etc"
                          name="location"
                          onCityChange={(city) => setAddress(city)}
                        />
                      </Col>
                      <Col
                        sm={6}
                        className="property-type d-flex justify-content-sm-between justify-content-center align-items-center mt-2 mt-sm-0"
                      >
                        <div className="left-section ps-md-4 w-100">
                          <h5 className="title">Property Type</h5>
                          <Dropdown
                            open={showPropertyTypes}
                            onOpenChange={(open) => setShowPropertyTypes(open)}
                            dropdownRender={()=> <PropertyTypeFilters />;}
                            trigger={["click"]}
                          >
                            <a onClick={(e) => e.preventDefault()}>
                              <FormikController
                                control="inputByFocus"
                                type="text"
                                name="professionalTypeId"
                                border="1px solid #EFEFF4"
                                placeholder="Profession"
                                value={propertyType}
                                className="profession"
                                showErrorMessage={false}
                              />
                            </a>
                          </Dropdown>
                        </div>
                        <button
                          type="submit"
                          className="search-box ms-2 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            // setting purpose
                            setFilterCriteria("purpose", purpose);
                            // setting address
                            setFilterCriteria("address", address);
                            // setting property type
                            const selectedIds = selectedSubTypes
                              .map(({ id }) => id)
                              .join(",");
                            // when no subtype is selected by user
                            const zeroSelectedIds = propertySubTypes
                              .map(({ id }) => id)
                              .join(",");
                            setFilterCriteria(
                              "propertyType",
                              propertyTypeNumber + 1
                            );
                            // setting subtypes search filter
                            setFilterCriteria(
                              "propertySubType",
                              selectedSubTypes.length < 1
                                ? zeroSelectedIds
                                : selectedIds
                            );
                            setShowPropertyTypes(false);
                            if (pathname === "/") {
                              navigate("/find-home");
                            }
                          }}
                        >
                          <img src={search_icon} alt="search" />
                        </button>
                      </Col>
                    </Row>
                  </div> */}
        {/* </div> */}
        {/* </Form> */}
        {/* );
          }} */}
        {/* </Formik> */}
      </HeroSectionStyle>
    </>
  );
};

export default HeroSection;

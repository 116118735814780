import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { StorySliderStyle } from "./style";
import like from "../../../assets/icons/ic_story_like.svg";
import send from "../../../assets/icons/ic_story_send.svg";
import volume from "../../../assets/icons/ic_story_speaker.svg";
import more from "../../../assets/icons/ic_story_more_info.svg";
import pause from "../../../assets/icons/ic_story_pause.svg";
import {
  BackgroundImage,
  ProfileMiniImg,
} from "../../../components/GlobalStyle";
import { BigStoryStyle } from "../../Story/style";
import { useGlobalContext } from "../../../context/context";
import { StoriesList } from "../types";
import { media_base_url } from "../../../utils/api_urls";
import getTimeAgo from "../../../components/GetTimeAgo";
import PrevBtn from "../../../components/Custom/PrevBtn";
import NextBtn from "../../../components/Custom/NextBtn";
import CommentsModal from "../../../components/Modals/Comments/CommentsModal";
import CustomModal from "../../../components/Modal/CustomModal";
import ShowCommentsBtn from "../../../components/Custom/ShowCommentsBtn";
import ShowCommentsLightBtn from "../../../components/Custom/ShowCommentLight";
import profile_placeholder from "../../../assets/icons/ic_use_placeholder.svg";
import VideoPlayer from "../../../components/VideoPlayers/VideoPlayer";

type StorySliderTypes = {
  userStoriesData: StoriesList[];
  setIsShowStories: Dispatch<SetStateAction<boolean>>;
  isShowStories: boolean;
};

let duration = 6000;

function StorySlider({
  userStoriesData,
  setIsShowStories,
  isShowStories,
}: StorySliderTypes) {
  const { slideIndex, setSlideIndex } = useGlobalContext();
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);

  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [newsfeedId, setNewsfeedId] = useState<number | string>("");
  const currentStoryRef = useRef<HTMLDivElement>(null);
  let lastSlideIndex = userStoriesData.length;
  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressWidth < 100) {
        setProgressWidth(progressWidth + 1);
      } else {
        clearInterval(intervalId);
      }
    }, duration / 100); // Adjust the interval frequency based on the desired duration

    return () => {
      clearInterval(intervalId);
    };
  }, [duration, progressWidth, slideIndex]);

  useEffect(() => {
    if (slideIndex < 0) {
      setSlideIndex(lastSlideIndex);
    }
    // checking if last slide Index is available or not
    if (lastSlideIndex > 0) {
      if (slideIndex > lastSlideIndex || slideIndex == lastSlideIndex) {
        setIsShowStories(false);
        setSlideIndex(0);
      }
    }
  }, [slideIndex, userStoriesData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (progressWidth < 100) {
        setProgressWidth(progressWidth + 1);
      } else {
        clearInterval(intervalId);
      }
    }, duration / 100); // Adjust the interval frequency based on the desired duration

    return () => {
      clearInterval(intervalId);
    };
  }, [duration, progressWidth]);

  // useEffect(() => {
  //   const slidesInterval = setInterval(() => {
  //     setSlideIndex(slideIndex + 1);
  //   }, 2000);

  //   return () => {
  //     clearInterval(slidesInterval);
  //   };
  // }, [slideIndex]);

  // owner data
  // const { firstName, lastName, profilePicture } = ownerData;

  // comment Moda Handler
  const commentModalHandler = (newsfeedId: number) => {
    setNewsfeedId(newsfeedId);
    setIsCommentsVisible(true);
  };

  // on video ended
  const onVideoEnd = () => {
    setSlideIndex((prev) => prev + 1);
  };
  // // move to next slide after 15 sec
  // useEffect(() => {
  //   let timerId: NodeJS.Timeout;

  //   if (slideIndex < lastSlideIndex) {
  //     timerId = setTimeout(() => {
  //       setSlideIndex((prevSlideIndex) => prevSlideIndex + 1);
  //     }, duration);
  //   }

  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, [slideIndex, lastSlideIndex]);

  console.log({ isCommentsVisible });
  return (
    <>
      {isCommentsVisible && (
        <CommentsModal
          readComments={true}
          turnOffCommenting={true}
          setIsModalVisible={setIsCommentsVisible}
          isModalVisible={isCommentsVisible}
          newsFeedId={+newsfeedId}
          title="Comments"
        />
      )}
      <div>
        <CustomModal
          isModalVisible={isShowStories}
          setIsModalVisible={setIsShowStories}
          width="100%"
        >
          <StorySliderStyle className="mx-3">
            <div className="section-center">
              {userStoriesData.map(
                (
                  {
                    imagesURLS,
                    videosURLS,
                    storyText,
                    saveFavourite,
                    sharingEnabled,
                    turnOffCommenting,
                    createdDateTime,
                    newsFeedId,
                    ownerData: { firstName, lastName, profilePicture },
                  },
                  personslideIndex: number
                ) => {
                  {
                    console.log(
                      { storyText, videosURLS, imagesURLS },
                      personslideIndex
                    );
                  }
                  let position = "nextSlide";
                  if (personslideIndex === slideIndex) {
                    position = "activeSlide";
                  }
                  console.log({ position });
                  // if (
                  //   personslideIndex === slideIndex - 1 ||
                  //   (slideIndex === 0 && personslideIndex === slides!.length - 1)
                  // ) {
                  //   position = "lastSlide";
                  // }
                  return (
                    <article key={personslideIndex} className={position}>
                      <BigStoryStyle>
                        <div className="big-story bigImg">
                          <div
                            className="indicators"
                            style={{
                              display: "grid",
                              gridTemplateColumns: `repeat(${userStoriesData.length}, 1fr)`,
                            }}
                          >
                            {userStoriesData.map((_, itemslideIndex) =>
                              itemslideIndex > slideIndex ? (
                                <div
                                  className="indicator indicator-null mb-1"
                                  key={itemslideIndex}
                                />
                              ) : (
                                <div
                                  className="indicator indicator-fill mb-1"
                                  key={itemslideIndex}
                                />
                              )
                            )}
                          </div>

                          <div className="profile-details d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                              <ProfileMiniImg
                                className="mini-img"
                                src={
                                  profilePicture
                                    ? media_base_url + profilePicture
                                    : profile_placeholder
                                }
                                alt={firstName}
                                borderRadius="50%"
                                height="42px"
                                width="42px"
                              />
                              <div className="d-flex flex-column">
                                <p className="title ms-2 mb-0 text-start">
                                  {firstName} {lastName}
                                </p>
                                <p className="text-start ms-2">
                                  <span className="title">
                                    {getTimeAgo(createdDateTime)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {videosURLS && (
                              <div className="icons">
                                <img
                                  src={pause}
                                  alt="pause"
                                  onClick={handleTogglePlay}
                                />
                                <img
                                  src={volume}
                                  alt="volume"
                                  onClick={() => setIsMuted((prev) => !prev)}
                                />
                                <img
                                  src={more}
                                  className="align-self-start"
                                  alt="more"
                                />
                              </div>
                            )}
                          </div>
                          {storyText && storyText.trim().length > 0 ? (
                            // story type
                            <div className="status-text h-100">
                              <h2 className="fs-1 h-100 d-flex align-items-center justify-content-center">
                                {storyText}
                              </h2>
                            </div>
                          ) : // images type
                          imagesURLS.length !== 0 ? (
                            <BackgroundImage
                              className="big-img"
                              postion="top"
                              repeat="no-repeat"
                              width="auto"
                              size="cover"
                              url={media_base_url + imagesURLS}
                            />
                          ) : (
                            // video type
                            <div className="video-section h-100">
                              <VideoPlayer
                                videoUrl={media_base_url + videosURLS}
                                onVideoEnd={onVideoEnd}
                                isMuted={isMuted}
                                isPlaying={isPlaying}
                                controls={false}
                              />
                              <video
                                className="h-100"
                                width="100%"
                                height="300"
                                controls
                                muted
                              >
                                <source
                                  src={media_base_url + videosURLS}
                                  type="video/mp4"
                                />
                                Your browser does not support play the video
                                tag.
                              </video>
                            </div>
                          )}
                          <div className="profile-details position-absolute bottom-0 w-100 d-flex justify-content-between align-items-center">
                            <ShowCommentsLightBtn
                              firstName={firstName}
                              lastName={lastName}
                              onClick={() => commentModalHandler(newsFeedId)}
                              saveFavourite={saveFavourite}
                              sharingEnabled={sharingEnabled}
                              turnOffCommenting={turnOffCommenting}
                            />
                            <div className="icons">
                              {saveFavourite && (
                                <img src={like} className="mt-1" alt="like" />
                              )}
                              {sharingEnabled && (
                                <img src={send} className="mt-1" alt="send" />
                              )}
                            </div>
                          </div>
                        </div>
                      </BigStoryStyle>
                    </article>
                  );
                }
              )}
              <PrevBtn clicked={() => setSlideIndex(slideIndex - 1)} />
              <NextBtn clicked={() => setSlideIndex(slideIndex + 1)} />
            </div>
          </StorySliderStyle>
        </CustomModal>
      </div>
    </>
  );
}

export default StorySlider;

import AuthNavbar from "../../components/Navbar/AuthNavbar";
import HomepageStyle from "./style";

const Homepage = ({ children }: any) => {
  return (
    <>
      <AuthNavbar />
      <HomepageStyle>
        <div className="inner-container">{children}</div>
      </HomepageStyle>
    </>
  );
};

export default Homepage;

import Skeleton from "react-loading-skeleton";

const PropertySkeleton = () => {
  return (
    <div
      className="inner-container bg-white rounded"
      style={{
        width: 440,
        height: 420,
      }}
    >
      <Skeleton height={250} />
      <div className="mt-2">
        <Skeleton width="86%" className="mt-2" />
        <Skeleton width="60%" />
      </div>

      <div className="d-flex justify-content-between px-2 mt-2">
        <Skeleton width={60} className="rounded" />
        <Skeleton width={60} className="rounded" />
      </div>
      <div className="d-flex justify-content-between px-2 mt-2">
        <Skeleton width={60} className="rounded" />
        <Skeleton width={60} className="rounded" />
        <Skeleton width={60} className="rounded" />
      </div>
    </div>
  );
};

export default PropertySkeleton;

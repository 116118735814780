import { PrivacyReportStyle } from "./style";
import { useEffect, useState } from "react";
import { TertiraryHeadingMini } from "../GlobalStyle";
import { authorizationToken, report_post_url } from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import LoadingOverlay from "../Modal/LoadingOverlay";

const PostReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const reportingData = useAppSelector(
    (state) => state.appData.data.reportingReasonsList
  );
  const { selectedPost } = useNewsfeedContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportReasonId, setReportReasonId] = useState<number | string>("");
  const navigate = useNavigate();

  // update privacy policy
  const reportReasonPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        report_post_url,
        {
          reportType: "POST",
          id: selectedPost.postId,
          reportReasonId: reportReasonId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (reportReasonId) {
      reportReasonPromise();
    }
  }, [reportReasonId]);

  return (
    <>
      {isLoading && <LoadingOverlay message="Post Reporting..." />}
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Report Post"
          description="Post Reported successfully"
          doTask={() => navigate("/explore-nearby")}
        />
      </CustomModal>
      <PrivacyReportStyle className="mt-3 mt-md-0">
        <TertiraryHeadingMini className="my-3">
          Why are you reporting this post?
        </TertiraryHeadingMini>
        <div className="inner-section">
          <div className="advanced-settings">
            {reportingData.map(({ title, description, id }, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => setReportReasonId(id)}
              >
                <div className="d-flex justify-content-between">
                  <p className=" mini-title mb-0">{title}</p>
                </div>
                <p className="sub-title">{description}</p>
              </div>
            ))}
            <div>
              <div className="d-flex justify-content-between">
                <p className=" mini-title mb-0">Allow Permissions</p>
              </div>
              <p className="sub-title">
                You can change this later by going to the menu at the top of
                your post.
              </p>
            </div>
          </div>
        </div>
      </PrivacyReportStyle>
    </>
  );
};

export default PostReport;

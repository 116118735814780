import { useState } from "react";
import { SidebarStyle } from "./style";
import { NavLink, useNavigate } from "react-router-dom";
import CustomDrawer from "../SideMenu/SideMenu";
import { useGlobalContext } from "../../context/context";
import CustomButton from "../CustomButton/CustomButton";
import { primaryColor } from "../GlobalStyle";

const Sidebar = () => {
  const navigate = useNavigate();
  const { showSidebar, setShowSidebar } = useGlobalContext();

  const [sidebarData, setSidebarData] = useState([
    {
      name: "Find Home",
      link: "/",
    },
    {
      name: "Find Property",
      link: "/find-home",
    },
    {
      name: "Find Professional",
      link: "/find-professional",
    },
    // {
    //   name: "Resources",
    //   link: "/resources",
    // },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
  ]);

  return (
    <CustomDrawer>
      <SidebarStyle className={`${showSidebar ? "d-block" : "d-none"}`}>
        <div className="inner-container d-flex flex-column justify-content-between h-100">
          <div>
            {sidebarData.map(({ name, link }, index) => (
              <div key={index} className="inner-container">
                <NavLink
                  onClick={() => setShowSidebar(false)}
                  to={link}
                  className="text-decoration-none "
                >
                  <p>{name}</p>
                </NavLink>
              </div>
            ))}
          </div>

          <div className="login-btn">
            <CustomButton
              bgcolor={primaryColor}
              color="white"
              padding="6px"
              width="80%"
              type="button"
              title="Login"
              margin="auto"
              fontSize="16px"
              clicked={() => navigate("/login")}
            />
          </div>
        </div>
      </SidebarStyle>
    </CustomDrawer>
  );
};

export default Sidebar;

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { useGlobalContext } from "../context/context";
import { removeLoginData } from "../redux/features/user/getLoginDataSlice";
import { local_storage_web_key } from "../utils/api_urls";

const useLogout = () => {
  const dispatch = useAppDispatch();
  const { setShowSidebar, setSelectedTourGuide } = useGlobalContext();
  const navigate = useNavigate();

  // logout handler
  const logoutHandler = () => {
    setSelectedTourGuide(0);
    localStorage.removeItem(local_storage_web_key);
    dispatch(removeLoginData());
    setShowSidebar(false);
    navigate("/");
  };

  return logoutHandler;
};

export default useLogout;

import styled from "styled-components";
import { fontFamilyMedium, whiteColor } from "../GlobalStyle";

const CustomModalStyle = styled.div`
  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2000;
    height: 46px;
    background-color: #1A1B1E;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s linear;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .modalWrap{
    background-color:rgba(0, 0, 0, 0.880);
    width: "100%"; 
  }
`;

export default CustomModalStyle;

export const LoadingOverlayStyle = styled.div`
  .message {
    color: ${whiteColor};
    font-family: ${fontFamilyMedium};
    text-align: center;
    font-size: 22px;
    margin-bottom: 0;
  }
`;

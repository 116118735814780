import { useRef, useState } from "react";
import { CreatePost4Style } from "./style";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { primaryColor } from "../../../components/GlobalStyle";
import { Col, Row } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../../components/Head/Head";
import { Form, Switch } from "antd";
import galleryIcon from "../../../assets/icons/ic_services_4.svg";
import CustomizedFileInput from "../../../components/CustomFileInput/CustomizedFileInput";
import placeholder from "../../../assets/icons/ic_res_placeholder.svg";
import { authorizationToken, create_post_url } from "../../../utils/api_urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import AliceCarousel from "react-alice-carousel";
import { toast } from "react-toastify";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionModal from "../../../components/CustomModals/EnnvisionModal";
import { usePostContext } from "../../../context/PostContext";

type createPostInitialTypes = {
  // tagPeoples: string;
  // media_item: any;
  description: string;
  address: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
};

const CreatePost4 = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const carousel = useRef<AliceCarousel>(null);
  const navigate = useNavigate();
  const { createPostFiles, setCreatePostFiles } = usePostContext();

  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );

  let initialValues: createPostInitialTypes = {
    // tagPeoples: "",
    description: "",
    address: locationData?.address || "",
    contactRequestPermission: true,
    boostPermission: true,
    saveFavourite: true,
    sharingEnabled: true,
    turnOffCommenting: false,
    readComments: true,
    hideLikesAndViewsCounts: false,
  };

  const FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/quicktime",
    "video/x-ms-wmv",
  ];

  const validationSchema = Yup.object({
    description: Yup.string().required("description is required"),
    address: Yup.string().required("address is required"),
    contactRequestPermission: Yup.boolean(),
    boostPermission: Yup.boolean(),
    saveFavourite: Yup.boolean(),
    sharingEnabled: Yup.boolean(),
    turnOffCommenting: Yup.boolean(),
    readComments: Yup.boolean(),
    hideLikesAndViewsCounts: Yup.boolean(),
  });

  const handleSubmit = async (values: createPostInitialTypes) => {
    const formData = new FormData();
    const images: any = [];
    const videos: any = [];

    if (createPostFiles.length === 0) {
      return;
    }

    for (let i = 0; i < createPostFiles.length; i++) {
      if (createPostFiles[i].type.startsWith("image/")) {
        images.push(createPostFiles[i]);
      }
      if (createPostFiles[i].type.startsWith("video/")) {
        videos.push(createPostFiles[i]);
      }
    }
    images.forEach((file: any) => {
      formData.append("images", file);
    });

    videos.forEach((file: any) => {
      formData.append("video", file);
    });
    const userLocation = {
      city: locationData?.city,
      state: locationData?.state,
      country: locationData?.country,
      latitude: locationData?.latitude,
      longitude: locationData?.longitude,
    };
    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...values,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            ...userLocation,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(create_post_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);

      console.log({ data });
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setIsCreatingPost(false);
    }
  };

  const responsive = {
    0: { items: 1 },
  };

  const items = createPostFiles
    ? createPostFiles.map((item: File, index: number) => {
        if (item.type.startsWith("image/")) {
          return (
            <img
              className="media-post-item"
              src={URL.createObjectURL(item)}
              alt={"image-" + index}
              key={index}
              onClick={() => navigate("/create-post-grid")}
            />
          );
        } else {
          return (
            <video
              src={URL.createObjectURL(item)}
              className="media-post-item rounded"
              controls
            />
          );
        }
      })
    : [];

  console.log({ items });

  // do task
  const doTask = () => {
    navigate("/post-list");
  };

  return (
    <CreatePost4Style>
      <Head title="create-post" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <EnnvisionModal
          title="Post Created"
          description="Post Created Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          console.log(formik.values, "formik values", formik.errors);
          return (
            <Form
              name="basic"
              onFinish={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="inner-section d-flex justify-content-center align-items-center">
                <Row>
                  <Col sm={6}>
                    <div className="position-relative">
                      {createPostFiles.length === 0 ? (
                        <div>
                          <img
                            src={placeholder}
                            alt="placeholder"
                            className="media-post-item rounded"
                          />
                          <p className="text-danger mt-2">File is Required</p>
                        </div>
                      ) : (
                        <div>
                          <AliceCarousel
                            mouseTracking
                            items={items}
                            disableButtonsControls
                            ref={carousel}
                            responsive={responsive}
                          />
                          <nav key="nav" className="b-refs-navs">
                            {items.map((item, i) => {
                              return (
                                <span
                                  key={i}
                                  onClick={() => carousel?.current?.slideTo(i)}
                                />
                              );
                            })}
                          </nav>
                          <div key="btns" className="b-refs-buttons">
                            <button
                              className="prev-btn"
                              type="button"
                              onClick={(e) => carousel?.current?.slidePrev(e)}
                            >
                              <span>
                                <LeftCircleOutlined />
                              </span>
                            </button>
                            <button
                              className="next-btn"
                              type="button"
                              onClick={(e) => carousel?.current?.slideNext(e)}
                            >
                              <span>
                                <RightCircleOutlined />
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="position-absolute cursor-pointer media-post">
                        <CustomizedFileInput>
                          <img
                            src={galleryIcon}
                            alt="galleryIcon"
                            className="gallery-icon upload-wrapper-item"
                          />
                          <input
                            type="file"
                            multiple
                            accept="image/*, video/*"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              let images: Array<string> = [];
                              let files = event.target.files;
                              if (files) {
                                for (let i = 0; i < files.length; i++) {
                                  images.push(URL.createObjectURL(files[i]));
                                }
                                setCreatePostFiles(Array.from(files));
                              }
                            }}
                          />
                        </CustomizedFileInput>
                      </div>
                    </div>
                    <div className="mt-2">
                      <CustomButton
                        bgcolor={primaryColor}
                        color="white"
                        padding="8px 8px"
                        width="100%"
                        type="submit"
                        title="Post Now"
                        loading={isCreatingPost}
                        margin="auto"
                        fontSize="18px"
                        fontFamily="EnnVisionsMedium"
                      />
                    </div>
                  </Col>
                  <Col
                    sm={6}
                    className="mt-3 mt-md-0 right-section"
                    style={{
                      height: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <FormControl
                      control="textarea"
                      height="100px"
                      type="text"
                      name="description"
                      border="1px solid #EFEFF4"
                      placeholder="Write a Caption"
                      className={
                        formik.errors.description && formik.touched.description
                          ? "is-invalid"
                          : "customInput"
                      }
                    />
                    <div className="tag-section">
                      <p className="mini-title pb-2 btm-border">Tag People</p>
                      <div className="add-location mt-2">
                        <p className="mini-title mb-1 border-0">Add Location</p>
                        <FormControl
                          control="input"
                          type="text"
                          name="address"
                          placeholder="Enter Address"
                          className={
                            formik.errors.address && formik.touched.address
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                        {/* <PlacesAutoCompleteInput
                          handleChange={(location) =>
                            formik.setFieldValue("address", location)
                          }
                        /> */}
                        {/* <div className="locations d-flex justify-content-between mt-2 flex-wrap">
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Jauharabad
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Islamabad
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            OshaToronto, Ontorio
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Osha
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="request-section mt-2 pb-2">
                      <div className="d-flex justify-content-between btm-border">
                        <p className="text mini-title mb-2">Contact Request</p>
                        <Switch
                          checked={formik.values.contactRequestPermission}
                          onChange={(value) => {
                            console.log({ value });
                            formik.setFieldValue(
                              "contactRequestPermission",
                              !formik.values.contactRequestPermission
                            );
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-between post-request mt-2">
                        <p className="text mini-title mb-0">Post Boost</p>
                        <Switch
                          checked={formik.values.boostPermission}
                          onChange={(value) => {
                            console.log({ value });
                            formik.setFieldValue(
                              "boostPermission",
                              !formik.values.boostPermission
                            );
                          }}
                        />
                      </div>
                    </div>
                    <p className="advanced-title mt-2 mb-1">
                      Advanced settings
                    </p>
                    <div className="advanced-settings ">
                      <div className="d-flex justify-content-between">
                        <p className=" mini-title mb-0">
                          Hide like and view counts in this post
                        </p>
                        <Switch
                          checked={formik.values.hideLikesAndViewsCounts}
                          onChange={(value) => {
                            console.log({ value });
                            formik.setFieldValue(
                              "hideLikesAndViewsCounts",
                              !formik.values.hideLikesAndViewsCounts
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <p className=" mini-title mb-0 fw-bold">
                        Turn Off Commenting
                      </p>
                      <Switch
                        checked={formik.values.turnOffCommenting}
                        onChange={(value) => {
                          console.log({ value });
                          formik.setFieldValue(
                            "turnOffCommenting",
                            !formik.values.turnOffCommenting
                          );
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <p className=" mini-title mb-0 fw-bold">Enable Sharing</p>
                      <Switch
                        checked={formik.values.sharingEnabled}
                        onChange={(value) => {
                          console.log({ value });
                          formik.setFieldValue(
                            "sharingEnabled",
                            !formik.values.sharingEnabled
                          );
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <p className=" mini-title mb-0 fw-bold">Read Comments</p>
                      <Switch
                        checked={formik.values.readComments}
                        onChange={(value) => {
                          console.log({ value });
                          formik.setFieldValue(
                            "readComments",
                            !formik.values.readComments
                          );
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <p className=" mini-title mb-0 fw-bold">Save Favourite</p>
                      <Switch
                        checked={formik.values.saveFavourite}
                        onChange={(value) => {
                          console.log({ value });
                          formik.setFieldValue(
                            "saveFavourite",
                            !formik.values.saveFavourite
                          );
                        }}
                      />
                    </div>
                    <p>
                      You can change this later by going to the menu of top of
                      your post
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className=" mini-title mb-0 fw-bold">
                        Allow Permissions
                      </p>
                    </div>
                    <p>
                      You can change this later by going to the menu of top of
                      your post
                    </p>
                  </Col>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CreatePost4Style>
  );
};

export default CreatePost4;

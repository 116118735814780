import React, { useState, useRef, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

interface MyMapComponentProps {
  initialLatitude: number;
  initialLongitude: number;
}

const MyMapComponent: React.FC<MyMapComponentProps> = ({
  initialLatitude,
  initialLongitude,
}) => {
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const onMapLoad = (map: google.maps.Map) => {
    const latLng = new google.maps.LatLng(initialLatitude, initialLongitude);
    map?.setCenter(latLng);
    map?.setZoom(16);
    setMap(map);
  };

  useEffect(() => {
    setMarkerPosition({ lat: initialLatitude, lng: initialLongitude });
    if (map) {
      map.setCenter({ lat: initialLatitude, lng: initialLongitude });
      map.setZoom(16);
    }
  }, [initialLatitude, initialLongitude, map]);

  return (
    <GoogleMap
      mapContainerStyle={{ height: "300px", width: "100%" }}
      center={markerPosition || undefined}
      zoom={15}
      onLoad={onMapLoad}
    >
      {markerPosition && (
        <Marker
          position={markerPosition}
          onLoad={(marker) => {
            // Store the marker instance for later use
            // if needed (e.g., to update its position)
            markerRef.current = marker;
          }}
        />
      )}
    </GoogleMap>
  );
};

export default MyMapComponent;
